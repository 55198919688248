import mock from './mock'

import './jwt'
import './prodi/visi'
import './prodi/pengajar-list'
import './prodi/semesterList'
import './prodi/semester-options'
import './prodi/prodi-options'
import './kurikulum/matakuliah-list'
import './select'
import './pages/faq'
import './apps/chat'
import './apps/todo'
import './apps/email'
import './apps/kanban'
import './apps/invoice'
import './apps/calendar'
import './apps/userList'
import './pages/profile'
import './apps/eCommerce'
import './pages/blog-data'
import './tables/datatables'
import './pages/pricing-data'
import './navbar/navbarSearch'
import './pages/knowledge-base'
import './apps/permissionsList'
import './cards/card-analytics'
import './cards/card-statistics'
import './pages/account-settings'
import './autoComplete/autoComplete'

mock.onAny().passThrough()
