import mock from '../mock'
const data = {
    visi:
        {
            title: "Visi",
            content: "Menjadi Program Studi yang Inovatif dan Terkemuka dalam Bidang Ilmu Teknik Mesin yang Menjunjung Tinggi Moral dan Etika serta Mampu Berperan pada tingkat Regional Asia Tenggara pada Tahun 2026."
        }
}
mock
    .onGet('/api/prodi/visi')
    .reply(() => [200, data.visi])
