import mock from '../mock'

const data = {
  prodis: [
    {
      value: "0410101",
      label: "Teknik Sipil"
    },
    {
      value: "0410201",
      label: "Teknik Mesin"
    },
    {
      value: "0410301",
      label: "Teknik Kimia"
    },
    {
      value: "0410401",
      label: "Arsitektur"
    },
    {
      value: "0410501",
      label: "Teknik Elektro"
    },
    {
      value: "0410601",
      label: "Teknik Industri"
    },
    {
      value: "0410701",
      label: "Teknik Geofisika"
    },
    {
      value: "0410801",
      label: "Teknik Pertambangan"
    },
    {
      value: "0410901",
      label: "Teknik Geologi"
    },
    {
      value: "0411001",
      label: "Perencanaan Wilayah dan Kota"
    },
    {
      value: "0411101",
      label: "Teknik Komputer"
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/prodi/prodi-options').reply(200, data.prodis)