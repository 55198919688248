import mock from '../mock'

const data = {
  semesters: [
    {
      value: "",
      label: "Pilih Semester"
    },
    {
      value: "20213",
      label: "Genap 2021/2022"
    },
    {
      value: "20211",
      label: "Ganjil 2021/2022"
    },
    {
      value: "20203",
      label: "Genap 2020/2021"
    },
    {
      value: "20201",
      label: "Ganjil 2020/2021"
    },
    {
      value: "20193",
      label: "Genap 2019/2020"
    },
    {
      value: "20191",
      label: "Ganjil 2019/2020"
    },
    {
      value: "20183",
      label: "Genap 2018/2019"
    },
    {
      value: "20181",
      label: "Ganjil 2018/2019"
    },
    {
      value: "20173",
      label: "Genap 2017/2018"
    },
    {
      value: "20171",
      label: "Ganjil 2017/2018"
    },
    {
      value: "20163",
      label: "Genap 2016/2017"
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/prodi/semester-options').reply(200, data.semesters)