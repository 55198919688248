import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const getProducts = createAsyncThunk("products/getProducts", async () => {
    const response = await axios.get('http://localhost:5000/products')
    return response.data
})

const productEntity = createEntityAdapter({
    selectId: (product) => product.id
})

const productSlice = createSlice({
    name: "product",
    initialState: productEntity.getInitialState(),
    extraReducers: {
        [getProducts.fulfilled]: (state, action) => {
            productEntity.setAll(state, action.payload)
        }
    }
})

export const produkSeleksi = productEntity.getSelectors(state => state.product) //state.product harus sama nama dengan yg di rootReducer.js
export default productSlice.reducer