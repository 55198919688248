import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  listMatakuliah: [
    {
      id: 1,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "FTEP01",
      nama_mk: "MBKM MAGANG/KERJASAMA RISET MANDIRI/WIRAUSAHA",
      kelas: "11",
      nama_dosen: "Dr. Melinda, ST., M.Sc",
      nip: "197906102002122001",
      ruang: "RKTE 3",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "11"
    },
    {
      id: 2,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "FTEP02",
      nama_mk: "MBKM PROYEK KEMANUSIAAN/INDEPENDEN/DESA/KAMPUS MENGAJAR",
      kelas: "11",
      nama_dosen: "Dr. Melinda, ST., M.Sc",
      nip: "197906102002122001",
      ruang: "RKTE 3",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "1"
    },
    {
      id: 3,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "FTT302",
      nama_mk: "TECHNOPRENEURSHIP",
      kelas: "11",
      nama_dosen: "Ir. Muhammad Jamil, M.T",
      nip: "196501301991021001",
      ruang: "A12-302",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "26"
    },
    {
      id: 4,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "FTT302",
      nama_mk: "TECHNOPRENEURSHIP",
      kelas: "11",
      nama_dosen: "Dr. Ir. Rizal Munadi, M.M., MT",
      nip: "196708151993031005",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "30"
    },
    {
      id: 5,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "FTT302",
      nama_mk: "TECHNOPRENEURSHIP",
      kelas: "11",
      nama_dosen: "Rizki Agam Syahputra, S.T., M.Si., M.AppIn&E",
      nip: "199307212021021101",
      ruang: "PRODI TI",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "36"
    },
    {
      id: 6,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "FTT302",
      nama_mk: "TECHNOPRENEURSHIP",
      kelas: "11",
      nama_dosen: "Dr. Ir. Marwan, S.Si., M.T., IPM., ASEAN Eng",
      nip: "197112311998021003",
      ruang: "Jurusan",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "53"
    },
    {
      id: 7,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "FTT302",
      nama_mk: "TECHNOPRENEURSHIP",
      kelas: "11",
      nama_dosen: "Nurul Kamal, ST., M.Sc",
      nip: "196903232014011101",
      ruang: "prodi",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "21"
    },
    {
      id: 8,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "FTT302",
      nama_mk: "TECHNOPRENEURSHIP",
      kelas: "11",
      nama_dosen: "Akmal Muhni, S.T., M.T.",
      nip: "199301162019031016",
      ruang: "Prodi",
      hari: "Senin",
      waktu: "16:35-18:15",
      peserta: "16"
    },
    {
      id: 9,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "FTT302",
      nama_mk: "TECHNOPRENEURSHIP",
      kelas: "11",
      nama_dosen: "Putra Rizkiya, S.T., M.Sc.",
      nip: "198601212019031009",
      ruang: "A34-201",
      hari: "Jumat",
      waktu: "10:40-12:19",
      peserta: "30"
    },
    {
      id: 10,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "FTT302",
      nama_mk: "TECHNOPRENEURSHIP",
      kelas: "12",
      nama_dosen: "Alfa Taras Bulba, ST, M.Sc",
      nip: "198208092008121002",
      ruang: "A25-203",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "27"
    },
    {
      id: 11,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "FTT302",
      nama_mk: "TECHNOPRENEURSHIP",
      kelas: "12",
      nama_dosen: "Ahmadiar, S.T., M.Sc.",
      nip: "198006252008121001",
      ruang: "RKTE1",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "34"
    },
    {
      id: 12,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "FTT302",
      nama_mk: "TECHNOPRENEURSHIP",
      kelas: "12",
      nama_dosen: "Friesca Erwan, S.T., MProjMgt",
      nip: "198405172019011101",
      ruang: "PRODI TI",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "27"
    },
    {
      id: 13,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "FTT302",
      nama_mk: "TECHNOPRENEURSHIP",
      kelas: "12",
      nama_dosen: "Freddy Sapta Wirandha, S.T., M.Eng",
      nip: "199308072020011101",
      ruang: "Jurusan",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "3"
    },
    {
      id: 14,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "FTT302",
      nama_mk: "TECHNOPRENEURSHIP",
      kelas: "12",
      nama_dosen: "Moehammad Ediyan Raza Karmel, B.Sc(Huns), M.Si.",
      nip: "198902262019031006",
      ruang: "prodi",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "21"
    },
    {
      id: 15,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "FTT302",
      nama_mk: "TECHNOPRENEURSHIP",
      kelas: "12",
      nama_dosen: "Myna Agustina Yusuf, S.T., MURP",
      nip: "198908082019032026",
      ruang: "A34-202",
      hari: "Jumat",
      waktu: "10:40-12:19",
      peserta: "24"
    },
    {
      id: 16,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "FTT302",
      nama_mk: "TECHNOPRENEURSHIP",
      kelas: "13",
      nama_dosen: "Masduki Khamdan Muchamad, S.Kom., M.Sc.",
      nip: "199404082019031009",
      ruang: "daring",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "6"
    },
    {
      id: 17,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "FTT302",
      nama_mk: "TECHNOPRENEURSHIP",
      kelas: "13",
      nama_dosen: "Sarika Zuhri, S.T., M.T.",
      nip: "198908142019032019",
      ruang: "PRODI TI",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "32"
    },
    {
      id: 18,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "FTT302",
      nama_mk: "TECHNOPRENEURSHIP",
      kelas: "14",
      nama_dosen: "Dr. Ir. Rizal Munadi, M.M., MT",
      nip: "196708151993031005",
      ruang: "RKTE 1",
      hari: "Kamis",
      waktu: "10:45-11:10",
      peserta: "14"
    },
    {
      id: 19,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "MKSP02",
      nama_mk: "KULIAH KERJA NYATA",
      kelas: "11",
      nama_dosen: "Dr. Renni Angraini, ST., M.Eng",
      nip: "197109231997022001",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "14:00-15:40",
      peserta: "26"
    },
    {
      id: 20,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "MKSP02",
      nama_mk: "KULIAH KERJA NYATA",
      kelas: "11",
      nama_dosen: "Dr. Muhammad Rizal, S.T, M.Sc",
      nip: "197910192006041003",
      ruang: "LAPANGAN",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "54"
    },
    {
      id: 21,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "MKSP02",
      nama_mk: "KULIAH KERJA NYATA",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Sri Aprilia, M.T",
      nip: "196704121993032001",
      ruang: "Jurusan T.Kimia",
      hari: "Senin",
      waktu: "8:00-16:30",
      peserta: "30"
    },
    {
      id: 22,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "MKSP02",
      nama_mk: "KULIAH KERJA NYATA",
      kelas: "11",
      nama_dosen: "Muhammad Haiqal, ST, M.Sc",
      nip: "197908122006041004",
      ruang: " Jurusan arsitektur",
      hari: "Minggu",
      waktu: "8:00-12:00",
      peserta: "47"
    },
    {
      id: 23,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "MKSP02",
      nama_mk: "KULIAH KERJA NYATA",
      kelas: "11",
      nama_dosen: "Ir. Ilyas, M.T.",
      nip: "196302061991021001",
      ruang: "PRODI TI",
      hari: "Sabtu",
      waktu: "10:45-12:25",
      peserta: "66"
    },
    {
      id: 24,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "MKSP02",
      nama_mk: "KULIAH KERJA NYATA",
      kelas: "11",
      nama_dosen: "Dr. Muhammad Isa, S.Si., M.Si",
      nip: "197404202006041002",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "7"
    },
    {
      id: 25,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "MKSP02",
      nama_mk: "KULIAH KERJA NYATA",
      kelas: "11",
      nama_dosen: "",
      nip: "mku",
      ruang: "prodi",
      hari: "Jumat",
      waktu: "16:30-18:00",
      peserta: "17"
    },
    {
      id: 26,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "MKSP02",
      nama_mk: "KULIAH KERJA NYATA",
      kelas: "11",
      nama_dosen: "Akmal Muhni, S.T., M.T.",
      nip: "199301162019031016",
      ruang: "Prodi",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "17"
    },
    {
      id: 27,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "MKSP02",
      nama_mk: "KULIAH KERJA NYATA",
      kelas: "11",
      nama_dosen: "Zainuddin, S.T.,M.Sc",
      nip: "197306042008011013",
      ruang: "Universitas",
      hari: "Minggu",
      waktu: "8:00-12:19",
      peserta: "-14"
    },
    {
      id: 28,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "MKSP02",
      nama_mk: "KULIAH KERJA NYATA",
      kelas: "11",
      nama_dosen: "Dr. Kahlil, S.T., M.Eng.",
      nip: "198512022019031006",
      ruang: "RKTE",
      hari: "Senin",
      waktu: "11:45-13:45",
      peserta: "20"
    },
    {
      id: 29,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "MKSPO2",
      nama_mk: "KULIAH KERJA NYATA",
      kelas: "11",
      nama_dosen: "Dr. Ramzi Adriman, S.T, M. Sc",
      nip: "197901302005011001",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "41"
    },
    {
      id: 30,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS102",
      nama_mk: "MATEMATIKA II",
      kelas: "11",
      nama_dosen: "Ir. Maimun Rizalihadi, M.Sc.Eng",
      nip: "196405301990021001",
      ruang: "A12-302",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "25"
    },
    {
      id: 31,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS102",
      nama_mk: "MATEMATIKA II",
      kelas: "12",
      nama_dosen: "Prof. Dr. Azmeri, S.T, M.T.",
      nip: "197308201998032001",
      ruang: "A12-301A",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "25"
    },
    {
      id: 32,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS102",
      nama_mk: "MATEMATIKA II",
      kelas: "13",
      nama_dosen: "Febriyanti Maulina, S.T., MT.",
      nip: "197802242008122002",
      ruang: "A23-203",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "21"
    },
    {
      id: 33,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS102",
      nama_mk: "MATEMATIKA II",
      kelas: "14",
      nama_dosen: "Nina Shaskia, S.T., M. Sc.",
      nip: "198903152015042002",
      ruang: "RK. MTS- IV",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "20"
    },
    {
      id: 34,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS102",
      nama_mk: "MATEMATIKA II",
      kelas: "15",
      nama_dosen: "Dr. Muhammad Fauzi, S.T.,M.T.",
      nip: "197006271999031005",
      ruang: "A25-203",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "20"
    },
    {
      id: 35,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS102",
      nama_mk: "MATEMATIKA II",
      kelas: "16",
      nama_dosen: "Yus Yudhyantoro, S.T.M.T",
      nip: "197202032000121001",
      ruang: "A23-205",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "20"
    },
    {
      id: 36,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS102",
      nama_mk: "MATEMATIKA II",
      kelas: "17",
      nama_dosen: "Nora Abdullah, S.T., M.Eng.Sc",
      nip: "197307101998022003",
      ruang: "D3-Z",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "20"
    },
    {
      id: 37,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS102",
      nama_mk: "MATEMATIKA II",
      kelas: "18",
      nama_dosen: "Dr. Yusria Darma, ST, M.Eng.Sc",
      nip: "197301301999031002",
      ruang: "A21-203",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "15"
    },
    {
      id: 38,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS106",
      nama_mk: "PRAKTIKUM BAHAN BANGUNAN",
      kelas: "11",
      nama_dosen: "Ir. Rudiansyah Putra, ST, M. Si., IPM",
      nip: "197509232002121004",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "8:00-9:40",
      peserta: "157"
    },
    {
      id: 39,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS108",
      nama_mk: "MEKANIKA TANAH I",
      kelas: "11",
      nama_dosen: "Ir. Banta Chairullah M, M.Ing., IPU",
      nip: "196008151986031004",
      ruang: "D3-Z",
      hari: "Rabu",
      waktu: "14:00-15:40",
      peserta: "40"
    },
    {
      id: 40,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS108",
      nama_mk: "MEKANIKA TANAH I",
      kelas: "12",
      nama_dosen: "Dr. Halida Yunita, S.T., M.T.",
      nip: "197806132002122002",
      ruang: "A12-301A",
      hari: "Rabu",
      waktu: "14:00-15:40",
      peserta: "40"
    },
    {
      id: 41,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS108",
      nama_mk: "MEKANIKA TANAH I",
      kelas: "13",
      nama_dosen: "Devi Sundary, S.T., M.T.",
      nip: "197302271999032002",
      ruang: "A23-203",
      hari: "Rabu",
      waktu: "14:00-15:40",
      peserta: "40"
    },
    {
      id: 42,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS108",
      nama_mk: "MEKANIKA TANAH I",
      kelas: "14",
      nama_dosen: "Yus Yudhyantoro, S.T.M.T",
      nip: "197202032000121001",
      ruang: "A25-203",
      hari: "Rabu",
      waktu: "14:00-15:40",
      peserta: "40"
    },
    {
      id: 43,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS108",
      nama_mk: "MEKANIKA TANAH I",
      kelas: "15",
      nama_dosen: "Hendra Gunawan, S.T.,M.T",
      nip: "197712112005011005",
      ruang: "RK. LAB. MEKTAN",
      hari: "Rabu",
      waktu: "14:00-15:40",
      peserta: "5"
    },
    {
      id: 44,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS110",
      nama_mk: "MEKANIKA BAHAN",
      kelas: "11",
      nama_dosen: "Ir. Rudiansyah Putra, ST, M. Si., IPM",
      nip: "197509232002121004",
      ruang: "Daring 21",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "29"
    },
    {
      id: 45,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS110",
      nama_mk: "MEKANIKA BAHAN",
      kelas: "12",
      nama_dosen: "Nora Abdullah, S.T., M.Eng.Sc",
      nip: "197307101998022003",
      ruang: "RK. MTS - II",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "29"
    },
    {
      id: 46,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS110",
      nama_mk: "MEKANIKA BAHAN",
      kelas: "13",
      nama_dosen: "Surya Bermansyah, S.T., M.T",
      nip: "197210022000031002",
      ruang: "A12-302",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "27"
    },
    {
      id: 47,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS110",
      nama_mk: "MEKANIKA BAHAN",
      kelas: "14",
      nama_dosen: "Ir. Purwandy Hasibuan, S.T., M.Eng., IPM",
      nip: "198210182006041001",
      ruang: "A25-203",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "27"
    },
    {
      id: 48,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS110",
      nama_mk: "MEKANIKA BAHAN",
      kelas: "15",
      nama_dosen: "Dr. Yunita Idris, ST. M.Eng.Structure",
      nip: "198006082009122002",
      ruang: "A23-203",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "26"
    },
    {
      id: 49,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS110",
      nama_mk: "MEKANIKA BAHAN",
      kelas: "16",
      nama_dosen: "Zahra Amalia, S.T., M.Eng.",
      nip: "199203122019032023",
      ruang: "A21-203",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 50,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS112",
      nama_mk: "ANALISIS STRUKTUR II",
      kelas: "11",
      nama_dosen: "Dr. Ir. Muttaqin, M.T.",
      nip: "196606151990091001",
      ruang: "A21-203",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "25"
    },
    {
      id: 51,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS112",
      nama_mk: "ANALISIS STRUKTUR II",
      kelas: "12",
      nama_dosen: "Dr. Ir. Abdullah, M.Sc",
      nip: "196403211989031002",
      ruang: "A25-203",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "24"
    },
    {
      id: 52,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS112",
      nama_mk: "ANALISIS STRUKTUR II",
      kelas: "13",
      nama_dosen: "Mahlil, S.T., M.T.",
      nip: "198711062018011101",
      ruang: "A23-203",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "25"
    },
    {
      id: 53,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS112",
      nama_mk: "ANALISIS STRUKTUR II",
      kelas: "14",
      nama_dosen: "Ir. Rudiansyah Putra, ST, M. Si., IPM",
      nip: "197509232002121004",
      ruang: "A12-301A",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "24"
    },
    {
      id: 54,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS112",
      nama_mk: "ANALISIS STRUKTUR II",
      kelas: "15",
      nama_dosen: "Ir. Huzaim, M.T.",
      nip: "196603201992031003",
      ruang: "D3-V",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "20"
    },
    {
      id: 55,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS112",
      nama_mk: "ANALISIS STRUKTUR II",
      kelas: "16",
      nama_dosen: "Surya Bermansyah, S.T., M.T",
      nip: "197210022000031002",
      ruang: "A12-302",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "20"
    },
    {
      id: 56,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS112",
      nama_mk: "ANALISIS STRUKTUR II",
      kelas: "17",
      nama_dosen: "Ir. Purwandy Hasibuan, S.T., M.Eng., IPM",
      nip: "198210182006041001",
      ruang: "A12-303",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "23"
    },
    {
      id: 57,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS114",
      nama_mk: "REKAYASA LALU LINTAS",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Sofyan, M.Sc.Eng., IPU",
      nip: "195905121987021001",
      ruang: "A25-203",
      hari: "Rabu",
      waktu: "9:50-11:30",
      peserta: "26"
    },
    {
      id: 58,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS114",
      nama_mk: "REKAYASA LALU LINTAS",
      kelas: "12",
      nama_dosen: "Dr. Renni Angraini, ST., M.Eng",
      nip: "197109231997022001",
      ruang: "RK MTS IV",
      hari: "Rabu",
      waktu: "9:50-11:30",
      peserta: "21"
    },
    {
      id: 59,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS114",
      nama_mk: "REKAYASA LALU LINTAS",
      kelas: "13",
      nama_dosen: "Ir. Lulusi, S.T., M.Sc",
      nip: "197301291999032001",
      ruang: "A25-201",
      hari: "Rabu",
      waktu: "9:50-11:30",
      peserta: "25"
    },
    {
      id: 60,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS114",
      nama_mk: "REKAYASA LALU LINTAS",
      kelas: "14",
      nama_dosen: "Dr. Noer Fadhly, ST., MT",
      nip: "197507312002121002",
      ruang: "A21-203",
      hari: "Rabu",
      waktu: "9:50-11:30",
      peserta: "25"
    },
    {
      id: 61,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS114",
      nama_mk: "REKAYASA LALU LINTAS",
      kelas: "15",
      nama_dosen: "Fitrika Mita Suryani, S.T., M.T.",
      nip: "196812211998022001",
      ruang: "A12-302",
      hari: "Rabu",
      waktu: "9:50-11:30",
      peserta: "21"
    },
    {
      id: 62,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS114",
      nama_mk: "REKAYASA LALU LINTAS",
      kelas: "16",
      nama_dosen: "Dr. Eng. Ir. Sugiarto, S.T., M. Eng., IPM",
      nip: "198104102006041003",
      ruang: "A12-301A",
      hari: "Rabu",
      waktu: "9:50-11:30",
      peserta: "25"
    },
    {
      id: 63,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS114",
      nama_mk: "REKAYASA LALU LINTAS",
      kelas: "17",
      nama_dosen: "Dr. Yusria Darma, ST, M.Eng.Sc",
      nip: "197301301999031002",
      ruang: "A23-203",
      hari: "Rabu",
      waktu: "9:50-11:30",
      peserta: "23"
    },
    {
      id: 64,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS116",
      nama_mk: "TEKNOLOGI BAHAN",
      kelas: "11",
      nama_dosen: "Dr. Ir. Abdullah, M.Sc",
      nip: "196403211989031002",
      ruang: "D3-W",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "27"
    },
    {
      id: 65,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS116",
      nama_mk: "TEKNOLOGI BAHAN",
      kelas: "12",
      nama_dosen: "Dr. Ir. Muttaqin, M.T.",
      nip: "196606151990091001",
      ruang: "D3-V",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "35"
    },
    {
      id: 66,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS116",
      nama_mk: "TEKNOLOGI BAHAN",
      kelas: "13",
      nama_dosen: "Mahlil, S.T., M.T.",
      nip: "198711062018011101",
      ruang: "A14-302",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "35"
    },
    {
      id: 67,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS116",
      nama_mk: "TEKNOLOGI BAHAN",
      kelas: "15",
      nama_dosen: "Dr. Yunita Idris, ST. M.Eng.Structure",
      nip: "198006082009122002",
      ruang: "D3-X",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "35"
    },
    {
      id: 68,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS116",
      nama_mk: "TEKNOLOGI BAHAN",
      kelas: "16",
      nama_dosen: "Surya Bermansyah, S.T., M.T",
      nip: "197210022000031002",
      ruang: "D3-Z",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "32"
    },
    {
      id: 69,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS202",
      nama_mk: "HIDRAULIKA",
      kelas: "11",
      nama_dosen: "Dr. Syamsidik, S.T., M.Sc.",
      nip: "197502251999031001",
      ruang: "Ruang Kuliah TDMRC",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "10"
    },
    {
      id: 70,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS202",
      nama_mk: "HIDRAULIKA",
      kelas: "12",
      nama_dosen: "Ir. Maimun Rizalihadi, M.Sc.Eng",
      nip: "196405301990021001",
      ruang: "A23-203",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "25"
    },
    {
      id: 71,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS202",
      nama_mk: "HIDRAULIKA",
      kelas: "13",
      nama_dosen: "Prof. Dr. Azmeri, S.T, M.T.",
      nip: "197308201998032001",
      ruang: "A21-203",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "4"
    },
    {
      id: 72,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS202",
      nama_mk: "HIDRAULIKA",
      kelas: "14",
      nama_dosen: "Dr. Ir. Eldina Fatimah, M.Sc.Eng",
      nip: "196408281989032002",
      ruang: "A12-301A",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "25"
    },
    {
      id: 73,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS202",
      nama_mk: "HIDRAULIKA",
      kelas: "15",
      nama_dosen: "Dr. Cut Dwi Refika, S.T.,M. Eng",
      nip: "197907022006042001",
      ruang: "A12-302",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "25"
    },
    {
      id: 74,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS202",
      nama_mk: "HIDRAULIKA",
      kelas: "16",
      nama_dosen: "Amir Fauzi, ST.M.Sc",
      nip: "197808282003121001",
      ruang: "A12-303",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "21"
    },
    {
      id: 75,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS202",
      nama_mk: "HIDRAULIKA",
      kelas: "17",
      nama_dosen: "Nina Shaskia, S.T., M. Sc.",
      nip: "198903152015042002",
      ruang: "EKTENSI 4",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "22"
    },
    {
      id: 76,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS202",
      nama_mk: "HIDRAULIKA",
      kelas: "18",
      nama_dosen: "Ziana, S.T., M.T",
      nip: "197503182000122001",
      ruang: "A25-201",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "24"
    },
    {
      id: 77,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS204",
      nama_mk: "PRAKTIKUM MEKANIKA FLUIDA",
      kelas: "11",
      nama_dosen: "Amir Fauzi, ST.M.Sc",
      nip: "197808282003121001",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "8:00-9:40",
      peserta: "148"
    },
    {
      id: 78,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS206",
      nama_mk: "HIDROLOGI",
      kelas: "11",
      nama_dosen: "Prof. Dr. Azmeri, S.T, M.T.",
      nip: "197308201998032001",
      ruang: "A21-203",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "4"
    },
    {
      id: 79,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS206",
      nama_mk: "HIDROLOGI",
      kelas: "12",
      nama_dosen: "Prof. Dr. Ir. Alfiansyah Yulianur BC",
      nip: "196307251991021001",
      ruang: "A25-203",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "25"
    },
    {
      id: 80,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS206",
      nama_mk: "HIDROLOGI",
      kelas: "13",
      nama_dosen: "Ir. Maimun Rizalihadi, M.Sc.Eng",
      nip: "196405301990021001",
      ruang: "A23-206",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "25"
    },
    {
      id: 81,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS206",
      nama_mk: "HIDROLOGI",
      kelas: "14",
      nama_dosen: "Dr. Muhammad Fauzi, S.T.,M.T.",
      nip: "197006271999031005",
      ruang: "A23-203",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "26"
    },
    {
      id: 82,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS206",
      nama_mk: "HIDROLOGI",
      kelas: "15",
      nama_dosen: "Amir Fauzi, ST.M.Sc",
      nip: "197808282003121001",
      ruang: "A12-301A",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "19"
    },
    {
      id: 83,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS206",
      nama_mk: "HIDROLOGI",
      kelas: "16",
      nama_dosen: "Ziana, S.T., M.T",
      nip: "197503182000122001",
      ruang: "A12-302",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "18"
    },
    {
      id: 84,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS206",
      nama_mk: "HIDROLOGI",
      kelas: "17",
      nama_dosen: "Nina Shaskia, S.T., M. Sc.",
      nip: "198903152015042002",
      ruang: "A25-202",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "20"
    },
    {
      id: 85,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS206",
      nama_mk: "HIDROLOGI",
      kelas: "18",
      nama_dosen: "Dr. Ella Meilianda, ST.MT",
      nip: "197505302000122001",
      ruang: "Ruang Kuliah TDMRC",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "20"
    },
    {
      id: 86,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS208",
      nama_mk: "STRUKTUR BETON II",
      kelas: "11",
      nama_dosen: "Dr. Teuku Budi Aulia, S.T.Dipl,Ing",
      nip: "196705291994031001",
      ruang: "A23-203",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "31"
    },
    {
      id: 87,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS208",
      nama_mk: "STRUKTUR BETON II",
      kelas: "12",
      nama_dosen: "Ir. Huzaim, M.T.",
      nip: "196603201992031003",
      ruang: "A25-203",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "31"
    },
    {
      id: 88,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS208",
      nama_mk: "STRUKTUR BETON II",
      kelas: "13",
      nama_dosen: "Dr. Ir. Abdullah, M.Sc",
      nip: "196403211989031002",
      ruang: "A21-203",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "28"
    },
    {
      id: 89,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS208",
      nama_mk: "STRUKTUR BETON II",
      kelas: "14",
      nama_dosen: "Dr. Ir. Taufiq S., M.Eng., IPU",
      nip: "196309221990021001",
      ruang: "A12-302",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "28"
    },
    {
      id: 90,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS208",
      nama_mk: "STRUKTUR BETON II",
      kelas: "15",
      nama_dosen: "Dr. Yulia Hayati, ST.M.Eng",
      nip: "197107091997022002",
      ruang: "A12-301A",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "32"
    },
    {
      id: 91,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS210",
      nama_mk: "ANALISIS STRUKTUR IV",
      kelas: "11",
      nama_dosen: "Dr. Teuku Budi Aulia, S.T.Dipl,Ing",
      nip: "196705291994031001",
      ruang: "A12-302",
      hari: "Senin",
      waktu: "16:35-18:15",
      peserta: "29"
    },
    {
      id: 92,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS210",
      nama_mk: "ANALISIS STRUKTUR IV",
      kelas: "12",
      nama_dosen: "Dr. Ir. Mochammad Afifuddin, M.Eng",
      nip: "196409071989031003",
      ruang: "D3-V",
      hari: "Senin",
      waktu: "16:35-18:15",
      peserta: "29"
    },
    {
      id: 93,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS210",
      nama_mk: "ANALISIS STRUKTUR IV",
      kelas: "13",
      nama_dosen: "Dr. Yunita Idris, ST. M.Eng.Structure",
      nip: "198006082009122002",
      ruang: "A12-301A",
      hari: "Senin",
      waktu: "16:35-18:15",
      peserta: "29"
    },
    {
      id: 94,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS210",
      nama_mk: "ANALISIS STRUKTUR IV",
      kelas: "14",
      nama_dosen: "Ir. Huzaim, M.T.",
      nip: "196603201992031003",
      ruang: "A23-203",
      hari: "Senin",
      waktu: "16:35-18:15",
      peserta: "28"
    },
    {
      id: 95,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS210",
      nama_mk: "ANALISIS STRUKTUR IV",
      kelas: "15",
      nama_dosen: "Ir. Purwandy Hasibuan, S.T., M.Eng., IPM",
      nip: "198210182006041001",
      ruang: "A25-203",
      hari: "Senin",
      waktu: "16:35-18:15",
      peserta: "32"
    },
    {
      id: 96,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS212",
      nama_mk: "STRUKTUR BAJA II",
      kelas: "11",
      nama_dosen: "Ir. Rudiansyah Putra, ST, M. Si., IPM",
      nip: "197509232002121004",
      ruang: "A12-203",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "30"
    },
    {
      id: 97,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS212",
      nama_mk: "STRUKTUR BAJA II",
      kelas: "12",
      nama_dosen: "Dr. Ir. Mochammad Afifuddin, M.Eng",
      nip: "196409071989031003",
      ruang: "D3-Y",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "30"
    },
    {
      id: 98,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS212",
      nama_mk: "STRUKTUR BAJA II",
      kelas: "13",
      nama_dosen: "Ir. Purwandy Hasibuan, S.T., M.Eng., IPM",
      nip: "198210182006041001",
      ruang: "A23-203",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "28"
    },
    {
      id: 99,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS212",
      nama_mk: "STRUKTUR BAJA II",
      kelas: "14",
      nama_dosen: "Ir. M. Zardan, M.T.",
      nip: "195911161987031003",
      ruang: "A12-301A",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "28"
    },
    {
      id: 100,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS212",
      nama_mk: "STRUKTUR BAJA II",
      kelas: "15",
      nama_dosen: "Dr. Ir. Muttaqin, M.T.",
      nip: "196606151990091001",
      ruang: "A12-302",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "31"
    },
    {
      id: 101,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS216",
      nama_mk: "DESAIN PONDASI I",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Munirwansyah, M.Sc",
      nip: "195905251985031003",
      ruang: "A25-202",
      hari: "Rabu",
      waktu: "10:45-12:25",
      peserta: "19"
    },
    {
      id: 102,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS216",
      nama_mk: "DESAIN PONDASI I",
      kelas: "12",
      nama_dosen: "Ir. Banta Chairullah M, M.Ing., IPU",
      nip: "196008151986031004",
      ruang: "D3-Z",
      hari: "Rabu",
      waktu: "10:45-12:25",
      peserta: "41"
    },
    {
      id: 103,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS216",
      nama_mk: "DESAIN PONDASI I",
      kelas: "13",
      nama_dosen: "Devi Sundary, S.T., M.T.",
      nip: "197302271999032002",
      ruang: "A34-201",
      hari: "Rabu",
      waktu: "10:45-12:25",
      peserta: "41"
    },
    {
      id: 104,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS216",
      nama_mk: "DESAIN PONDASI I",
      kelas: "14",
      nama_dosen: "Yus Yudhyantoro, S.T.M.T",
      nip: "197202032000121001",
      ruang: "A14-301A",
      hari: "Rabu",
      waktu: "10:45-12:25",
      peserta: "26"
    },
    {
      id: 105,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS216",
      nama_mk: "DESAIN PONDASI I",
      kelas: "15",
      nama_dosen: "Dr. Halida Yunita, S.T., M.T.",
      nip: "197806132002122002",
      ruang: "JTS",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "25"
    },
    {
      id: 106,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS218",
      nama_mk: "MANAJEMEN PROYEK",
      kelas: "11",
      nama_dosen: "Nurisra, S.T., M.T",
      nip: "197010011997022001",
      ruang: "A12-302",
      hari: "Senin",
      waktu: "10:00-12:30",
      peserta: "25"
    },
    {
      id: 107,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS218",
      nama_mk: "MANAJEMEN PROYEK",
      kelas: "12",
      nama_dosen: "Ir. Buraida, M.M., MT",
      nip: "196809211999032002",
      ruang: "RK. MTS- IV",
      hari: "Senin",
      waktu: "10:00-12:30",
      peserta: "23"
    },
    {
      id: 108,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS218",
      nama_mk: "MANAJEMEN PROYEK",
      kelas: "13",
      nama_dosen: "Dr. Ir. Saiful Husin, M.T.",
      nip: "195501011987021001",
      ruang: "RK. MTS - I",
      hari: "Senin",
      waktu: "10:00-12:30",
      peserta: "17"
    },
    {
      id: 109,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS218",
      nama_mk: "MANAJEMEN PROYEK",
      kelas: "14",
      nama_dosen: "Dr. Cut Zukhrina Oktaviani, S.T., MT.",
      nip: "197310102008122001",
      ruang: "A21-203",
      hari: "Senin",
      waktu: "10:00-12:30",
      peserta: "20"
    },
    {
      id: 110,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS218",
      nama_mk: "MANAJEMEN PROYEK",
      kelas: "15",
      nama_dosen: "Mahmuddin, S.T., M.T.",
      nip: "196912051997021001",
      ruang: "A12-301A",
      hari: "Senin",
      waktu: "10:00-12:30",
      peserta: "23"
    },
    {
      id: 111,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS218",
      nama_mk: "MANAJEMEN PROYEK",
      kelas: "16",
      nama_dosen: "Ir. Ibnu Abbas, M.Sc",
      nip: "196203281989031002",
      ruang: "A23-203",
      hari: "Senin",
      waktu: "10:00-12:30",
      peserta: "23"
    },
    {
      id: 112,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS218",
      nama_mk: "MANAJEMEN PROYEK",
      kelas: "18",
      nama_dosen: "Dr. Fachrurrazi, S.T., MT",
      nip: "197005062000121001",
      ruang: "A25-203",
      hari: "Senin",
      waktu: "10:00-12:30",
      peserta: "20"
    },
    {
      id: 113,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS220",
      nama_mk: "METODE PELAKSANAAN DAN PEMBONGKARAN KONSTRUKSI",
      kelas: "11",
      nama_dosen: "Ir. Muhammad Jamil, M.T",
      nip: "196501301991021001",
      ruang: "A12-301A",
      hari: "Selasa",
      waktu: "16:20-18:00",
      peserta: "37"
    },
    {
      id: 114,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS220",
      nama_mk: "METODE PELAKSANAAN DAN PEMBONGKARAN KONSTRUKSI",
      kelas: "12",
      nama_dosen: "Febriyanti Maulina, S.T., MT.",
      nip: "197802242008122002",
      ruang: "A12-302",
      hari: "Selasa",
      waktu: "16:20-18:00",
      peserta: "42"
    },
    {
      id: 115,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS220",
      nama_mk: "METODE PELAKSANAAN DAN PEMBONGKARAN KONSTRUKSI",
      kelas: "13",
      nama_dosen: "Mahmuddin, S.T., M.T.",
      nip: "196912051997021001",
      ruang: "D3-W",
      hari: "Selasa",
      waktu: "16:20-18:00",
      peserta: "39"
    },
    {
      id: 116,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS220",
      nama_mk: "METODE PELAKSANAAN DAN PEMBONGKARAN KONSTRUKSI",
      kelas: "14",
      nama_dosen: "Alfa Taras Bulba, ST, M.Sc",
      nip: "198208092008121002",
      ruang: "D3-Z",
      hari: "Selasa",
      waktu: "16:20-18:00",
      peserta: "40"
    },
    {
      id: 117,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS220",
      nama_mk: "METODE PELAKSANAAN DAN PEMBONGKARAN KONSTRUKSI",
      kelas: "15",
      nama_dosen: "Dr. Mubarak, S.T., M.T.",
      nip: "197505062000121001",
      ruang: "A14-303",
      hari: "Selasa",
      waktu: "16:20-18:00",
      peserta: "37"
    },
    {
      id: 118,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS220",
      nama_mk: "METODE PELAKSANAAN DAN PEMBONGKARAN KONSTRUKSI",
      kelas: "16",
      nama_dosen: "Nurul Malahayati, ST., M.Sc",
      nip: "196911301998022001",
      ruang: "RK. MTS III",
      hari: "Selasa",
      waktu: "16:20-18:00",
      peserta: "30"
    },
    {
      id: 119,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS220",
      nama_mk: "METODE PELAKSANAAN DAN PEMBONGKARAN KONSTRUKSI",
      kelas: "17",
      nama_dosen: "Nurisra, S.T., M.T",
      nip: "197010011997022001",
      ruang: "RK. MTS - IV",
      hari: "Selasa",
      waktu: "16:20-18:00",
      peserta: "40"
    },
    {
      id: 120,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS224",
      nama_mk: "DINAMIKA STRUKTUR DAN TEKNIK GEMPA",
      kelas: "11",
      nama_dosen: "Dr. Ir. Mochammad Afifuddin, M.Eng",
      nip: "196409071989031003",
      ruang: "RK. MTS-I",
      hari: "Sabtu",
      waktu: "8:00-9:40",
      peserta: "32"
    },
    {
      id: 121,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS224",
      nama_mk: "DINAMIKA STRUKTUR DAN TEKNIK GEMPA",
      kelas: "12",
      nama_dosen: "Dr. Ir. Abdullah, M.Sc",
      nip: "196403211989031002",
      ruang: "A25-203",
      hari: "Sabtu",
      waktu: "8:00-9:40",
      peserta: "42"
    },
    {
      id: 122,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS224",
      nama_mk: "DINAMIKA STRUKTUR DAN TEKNIK GEMPA",
      kelas: "13",
      nama_dosen: "Dr. Ir. Muttaqin, M.T.",
      nip: "196606151990091001",
      ruang: "A23-203",
      hari: "Sabtu",
      waktu: "8:00-9:40",
      peserta: "37"
    },
    {
      id: 123,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS224",
      nama_mk: "DINAMIKA STRUKTUR DAN TEKNIK GEMPA",
      kelas: "14",
      nama_dosen: "Dr. Yunita Idris, ST. M.Eng.Structure",
      nip: "198006082009122002",
      ruang: "A12-31A",
      hari: "Sabtu",
      waktu: "8:00-9:40",
      peserta: "40"
    },
    {
      id: 124,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS224",
      nama_mk: "DINAMIKA STRUKTUR DAN TEKNIK GEMPA",
      kelas: "15",
      nama_dosen: "Dr. Teuku Budi Aulia, S.T.Dipl,Ing",
      nip: "196705291994031001",
      ruang: "A12-302",
      hari: "Sabtu",
      waktu: "8:00-9:40",
      peserta: "41"
    },
    {
      id: 125,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS224",
      nama_mk: "DINAMIKA STRUKTUR DAN TEKNIK GEMPA",
      kelas: "16",
      nama_dosen: "Dr. Yusria Darma, ST, M.Eng.Sc",
      nip: "197301301999031002",
      ruang: "A25-201",
      hari: "Sabtu",
      waktu: "8:00-9:40",
      peserta: "52"
    },
    {
      id: 126,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS224",
      nama_mk: "DINAMIKA STRUKTUR DAN TEKNIK GEMPA",
      kelas: "17",
      nama_dosen: "Dr. Eng. M. Arief Rahman Panjaitan, S.T., M.T.",
      nip: "198511112012121003",
      ruang: "A34-201",
      hari: "Sabtu",
      waktu: "8:00-9:40",
      peserta: "36"
    },
    {
      id: 127,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS224",
      nama_mk: "DINAMIKA STRUKTUR DAN TEKNIK GEMPA",
      kelas: "18",
      nama_dosen: "Mahlil, S.T., M.T.",
      nip: "198711062018011101",
      ruang: "A21-201",
      hari: "Sabtu",
      waktu: "8:00-9:40",
      peserta: "32"
    },
    {
      id: 128,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS302",
      nama_mk: "PERANCANGAN BIAYA DAN JADWAL PROYEK KONSTRUKSI",
      kelas: "11",
      nama_dosen: "Febriyanti Maulina, S.T., MT.",
      nip: "197802242008122002",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "10:00-11:40",
      peserta: "145"
    },
    {
      id: 129,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS304",
      nama_mk: "PRAKTIKUM PERENC. & PENG. PROYEK KONSTRUKSI",
      kelas: "11",
      nama_dosen: "Dr. Mubarak, S.T., M.T.",
      nip: "197505062000121001",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "10:00-11:40",
      peserta: "141"
    },
    {
      id: 130,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS306",
      nama_mk: "REKAYASA JALAN REL",
      kelas: "11",
      nama_dosen: "Dr. Noer Fadhly, ST., MT",
      nip: "197507312002121002",
      ruang: "A25-203",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "20"
    },
    {
      id: 131,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS306",
      nama_mk: "REKAYASA JALAN REL",
      kelas: "12",
      nama_dosen: "Prof. Dr. Ir. Sofyan, M.Sc.Eng., IPU",
      nip: "195905121987021001",
      ruang: "RK. MTS - III",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "19"
    },
    {
      id: 132,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS306",
      nama_mk: "REKAYASA JALAN REL",
      kelas: "13",
      nama_dosen: "Dr. Yusria Darma, ST, M.Eng.Sc",
      nip: "197301301999031002",
      ruang: "A21-203",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "19"
    },
    {
      id: 133,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS306",
      nama_mk: "REKAYASA JALAN REL",
      kelas: "14",
      nama_dosen: "Dr. Renni Angraini, ST., M.Eng",
      nip: "197109231997022001",
      ruang: "JTS",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "14"
    },
    {
      id: 134,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS306",
      nama_mk: "REKAYASA JALAN REL",
      kelas: "15",
      nama_dosen: "Ir. Lulusi, S.T., M.Sc",
      nip: "197301291999032001",
      ruang: "A23-203",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "16"
    },
    {
      id: 135,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS307",
      nama_mk: "PERANCANGAN GEOMETRIK JALAN RAYA",
      kelas: "11",
      nama_dosen: "Dr. Yusria Darma, ST, M.Eng.Sc",
      nip: "197301301999031002",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "10:00-11:40",
      peserta: "48"
    },
    {
      id: 136,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS308",
      nama_mk: "REKAYASA PANTAI DAN PELABUHAN",
      kelas: "11",
      nama_dosen: "Dr. Ir. Eldina Fatimah, M.Sc.Eng",
      nip: "196408281989032002",
      ruang: "A23-204",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "27"
    },
    {
      id: 137,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS308",
      nama_mk: "REKAYASA PANTAI DAN PELABUHAN",
      kelas: "12",
      nama_dosen: "Dr. Ella Meilianda, ST.MT",
      nip: "197505302000122001",
      ruang: "D3-Y",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "30"
    },
    {
      id: 138,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS308",
      nama_mk: "REKAYASA PANTAI DAN PELABUHAN",
      kelas: "13",
      nama_dosen: "Dr. Syamsidik, S.T., M.Sc.",
      nip: "197502251999031001",
      ruang: "RK. TDMRC",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "20"
    },
    {
      id: 139,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS308",
      nama_mk: "REKAYASA PANTAI DAN PELABUHAN",
      kelas: "14",
      nama_dosen: "Prof. Dr. Ir. Alfiansyah Yulianur BC",
      nip: "196307251991021001",
      ruang: "A23-203",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "18"
    },
    {
      id: 140,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS308",
      nama_mk: "REKAYASA PANTAI DAN PELABUHAN",
      kelas: "15",
      nama_dosen: "Ir. Maimun Rizalihadi, M.Sc.Eng",
      nip: "196405301990021001",
      ruang: "A25-203",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "21"
    },
    {
      id: 141,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS308",
      nama_mk: "REKAYASA PANTAI DAN PELABUHAN",
      kelas: "16",
      nama_dosen: "Dr. Muhammad Fauzi, S.T.,M.T.",
      nip: "197006271999031005",
      ruang: "A21-203",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "20"
    },
    {
      id: 142,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS310",
      nama_mk: "PERANCANGAN IRIGASI DAN BANGUNAN AIR",
      kelas: "11",
      nama_dosen: "Ir. Maimun Rizalihadi, M.Sc.Eng",
      nip: "196405301990021001",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "10:00-11:40",
      peserta: "141"
    },
    {
      id: 143,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS312",
      nama_mk: "DESAIN PONDASI II",
      kelas: "11",
      nama_dosen: "Ir. Banta Chairullah M, M.Ing., IPU",
      nip: "196008151986031004",
      ruang: "D3- Y",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "36"
    },
    {
      id: 144,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS312",
      nama_mk: "DESAIN PONDASI II",
      kelas: "12",
      nama_dosen: "Devi Sundary, S.T., M.T.",
      nip: "197302271999032002",
      ruang: "D3- Z",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "33"
    },
    {
      id: 145,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS312",
      nama_mk: "DESAIN PONDASI II",
      kelas: "13",
      nama_dosen: "Yus Yudhyantoro, S.T.M.T",
      nip: "197202032000121001",
      ruang: "D3- W",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "30"
    },
    {
      id: 146,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS312",
      nama_mk: "DESAIN PONDASI II",
      kelas: "14",
      nama_dosen: "Dr. Munira Sungkar, S.T., M.T.",
      nip: "197606062000122001",
      ruang: "D3- X",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "35"
    },
    {
      id: 147,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS313",
      nama_mk: "PERANCANGAN KONSTRUKSI GEOTEKNIK I",
      kelas: "11",
      nama_dosen: "Ir. Banta Chairullah M, M.Ing., IPU",
      nip: "196008151986031004",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "10:00-11:40",
      peserta: "4"
    },
    {
      id: 148,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS313",
      nama_mk: "PERANCANGAN KONSTRUKSI GEOTEKNIK I",
      kelas: "12",
      nama_dosen: "Devi Sundary, S.T., M.T.",
      nip: "197302271999032002",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "10:00-11:40",
      peserta: "6"
    },
    {
      id: 149,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS313",
      nama_mk: "PERANCANGAN KONSTRUKSI GEOTEKNIK I",
      kelas: "13",
      nama_dosen: "Hendra Gunawan, S.T.,M.T",
      nip: "197712112005011005",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "10:00-11:40",
      peserta: "3"
    },
    {
      id: 150,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS313",
      nama_mk: "PERANCANGAN KONSTRUKSI GEOTEKNIK I",
      kelas: "14",
      nama_dosen: "Yus Yudhyantoro, S.T.M.T",
      nip: "197202032000121001",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "10:00-11:40",
      peserta: "8"
    },
    {
      id: 151,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS313",
      nama_mk: "PERANCANGAN KONSTRUKSI GEOTEKNIK I",
      kelas: "15",
      nama_dosen: "Dr. Halida Yunita, S.T., M.T.",
      nip: "197806132002122002",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "10:00-11:40",
      peserta: "3"
    },
    {
      id: 152,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS313",
      nama_mk: "PERANCANGAN KONSTRUKSI GEOTEKNIK I",
      kelas: "16",
      nama_dosen: "Nafisah Al-Huda, S.T., MT.",
      nip: "197901102008122001",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "10:00-11:40",
      peserta: "4"
    },
    {
      id: 153,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS313",
      nama_mk: "PERANCANGAN KONSTRUKSI GEOTEKNIK I",
      kelas: "17",
      nama_dosen: "Dr. Munira Sungkar, S.T., M.T.",
      nip: "197606062000122001",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "10:00-11:40",
      peserta: "6"
    },
    {
      id: 154,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS313",
      nama_mk: "PERANCANGAN KONSTRUKSI GEOTEKNIK I",
      kelas: "18",
      nama_dosen: "Khaizal, ST, M.Sc.",
      nip: "197406052002121001",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "10:00-11:40",
      peserta: "10"
    },
    {
      id: 155,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS316",
      nama_mk: "STRUKTUR BETON PRATEGANG",
      kelas: "11",
      nama_dosen: "Dr. Teuku Budi Aulia, S.T.Dipl,Ing",
      nip: "196705291994031001",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "10:00-11:40",
      peserta: "37"
    },
    {
      id: 156,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS318",
      nama_mk: "REKAYASA JEMBATAN BAJA",
      kelas: "11",
      nama_dosen: "Dr. Ir. Mochammad Afifuddin, M.Eng",
      nip: "196409071989031003",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "14:00-15:40",
      peserta: "31"
    },
    {
      id: 157,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS320",
      nama_mk: "PENYELIDIKAN TANAH LAPANGAN",
      kelas: "11",
      nama_dosen: "Ir. Banta Chairullah M, M.Ing., IPU",
      nip: "196008151986031004",
      ruang: "D3- Z",
      hari: "Senin",
      waktu: "9:40-11:30",
      peserta: "1"
    },
    {
      id: 158,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS322",
      nama_mk: "AIR TANAH DAN REMBESAN",
      kelas: "11",
      nama_dosen: "Devi Sundary, S.T., M.T.",
      nip: "197302271999032002",
      ruang: "A21- 203",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "1"
    },
    {
      id: 159,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS324",
      nama_mk: "PERANCANGAN KONSTRUKSI GEOTEKNIK II",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Munirwansyah, M.Sc",
      nip: "195905251985031003",
      ruang: "JTS",
      hari: "Rabu",
      waktu: "16:35-18:15",
      peserta: "4"
    },
    {
      id: 160,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS326",
      nama_mk: "BANGUNAN TENAGA AIR",
      kelas: "11",
      nama_dosen: "Prof. Dr. Azmeri, S.T, M.T.",
      nip: "197308201998032001",
      ruang: "RK. MTS - I",
      hari: "Selasa",
      waktu: "9:50-11:30",
      peserta: "13"
    },
    {
      id: 161,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS328",
      nama_mk: "HIDRAULIKA KOMPUTASI",
      kelas: "11",
      nama_dosen: "Dr. Syamsidik, S.T., M.Sc.",
      nip: "197502251999031001",
      ruang: "RK. TDMRC",
      hari: "Senin",
      waktu: "10:45-11:35",
      peserta: "-3"
    },
    {
      id: 162,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS332",
      nama_mk: "PERANCANGAN KONSTRUKSI JALAN RAYA",
      kelas: "11",
      nama_dosen: "Dr. Yusria Darma, ST, M.Eng.Sc",
      nip: "197301301999031002",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "14:00-15:40",
      peserta: "15"
    },
    {
      id: 163,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS334",
      nama_mk: "REKAYASA PRASARANA ANTAR MODA",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Sofyan, M.Sc.Eng., IPU",
      nip: "195905121987021001",
      ruang: "A23-205",
      hari: "Selasa",
      waktu: "9:40-11:30",
      peserta: "21"
    },
    {
      id: 164,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS334",
      nama_mk: "REKAYASA PRASARANA ANTAR MODA",
      kelas: "12",
      nama_dosen: "Dr. Yusria Darma, ST, M.Eng.Sc",
      nip: "197301301999031002",
      ruang: "A23-204",
      hari: "Selasa",
      waktu: "9:40-11:30",
      peserta: "13"
    },
    {
      id: 165,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS335",
      nama_mk: "ESTIMASI BIAYA KONSTRUKSI",
      kelas: "11",
      nama_dosen: "Febriyanti Maulina, S.T., MT.",
      nip: "197802242008122002",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "8:00-9:40",
      peserta: "1"
    },
    {
      id: 166,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS338",
      nama_mk: "ASPEK HUKUM DAN ADM PROYEK KONSTRUKSI",
      kelas: "11",
      nama_dosen: "Alfa Taras Bulba, ST, M.Sc",
      nip: "198208092008121002",
      ruang: "A12-301A",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "3"
    },
    {
      id: 167,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS338",
      nama_mk: "ASPEK HUKUM DAN ADM PROYEK KONSTRUKSI",
      kelas: "12",
      nama_dosen: "Ir. Ibnu Abbas, M.Sc",
      nip: "196203281989031002",
      ruang: "A12-302",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "23"
    },
    {
      id: 168,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS338",
      nama_mk: "ASPEK HUKUM DAN ADM PROYEK KONSTRUKSI",
      kelas: "13",
      nama_dosen: "Febriyanti Maulina, S.T., MT.",
      nip: "197802242008122002",
      ruang: "JTS",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "1"
    },
    {
      id: 169,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS340",
      nama_mk: "EKONOMI REKAYASA",
      kelas: "11",
      nama_dosen: "Nurisra, S.T., M.T",
      nip: "197010011997022001",
      ruang: "A25-201",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "21"
    },
    {
      id: 170,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS340",
      nama_mk: "EKONOMI REKAYASA",
      kelas: "12",
      nama_dosen: "Mahmuddin, S.T., M.T.",
      nip: "196912051997021001",
      ruang: "RK. MTS -I",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "10"
    },
    {
      id: 171,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS340",
      nama_mk: "EKONOMI REKAYASA",
      kelas: "13",
      nama_dosen: "Febriyanti Maulina, S.T., MT.",
      nip: "197802242008122002",
      ruang: "JTS",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "1"
    },
    {
      id: 172,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS342",
      nama_mk: "SIM PROYEK KONSTRUKSI",
      kelas: "11",
      nama_dosen: "Dr. Fachrurrazi, S.T., MT",
      nip: "197005062000121001",
      ruang: "A23-205",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "8"
    },
    {
      id: 173,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS342",
      nama_mk: "SIM PROYEK KONSTRUKSI",
      kelas: "12",
      nama_dosen: "Febriyanti Maulina, S.T., MT.",
      nip: "197802242008122002",
      ruang: "A23-204",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "8"
    },
    {
      id: 174,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS342",
      nama_mk: "SIM PROYEK KONSTRUKSI",
      kelas: "13",
      nama_dosen: "Febriyanti Maulina, S.T., MT.",
      nip: "197802242008122002",
      ruang: "JTS",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "1"
    },
    {
      id: 175,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS402",
      nama_mk: "TEORI ELASTISITAS DAN PLASTISITAS",
      kelas: "11",
      nama_dosen: "Dr. Ir. Muttaqin, M.T.",
      nip: "196606151990091001",
      ruang: "D3-V",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "11"
    },
    {
      id: 176,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS404",
      nama_mk: "MEKANIKA KONTINUM",
      kelas: "12",
      nama_dosen: "Dr. Ir. Mochammad Afifuddin, M.Eng",
      nip: "196409071989031003",
      ruang: "D3- V",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "22"
    },
    {
      id: 177,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS405",
      nama_mk: "PERANCANGAN KONSTRUKSI GEDUNG",
      kelas: "11",
      nama_dosen: "Dr. Yunita Idris, ST. M.Eng.Structure",
      nip: "198006082009122002",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "10:00-11:40",
      peserta: "33"
    },
    {
      id: 178,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS407",
      nama_mk: "KESELAMATAN KONSTRUKSI",
      kelas: "11",
      nama_dosen: "Dr. Yusria Darma, ST, M.Eng.Sc",
      nip: "197301301999031002",
      ruang: "A12-301A",
      hari: "Rabu",
      waktu: "16:20-18:00",
      peserta: "7"
    },
    {
      id: 179,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS411",
      nama_mk: "PERANCANGAN PANTAI DAN PELABUHAN",
      kelas: "11",
      nama_dosen: "Dr. Ir. Eldina Fatimah, M.Sc.Eng",
      nip: "196408281989032002",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "14:00-15:40",
      peserta: "5"
    },
    {
      id: 180,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS415",
      nama_mk: "SISTEM DAN ANALISIS KEPUTUSAN",
      kelas: "12",
      nama_dosen: "Febriyanti Maulina, S.T., MT.",
      nip: "197802242008122002",
      ruang: "JTS",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "1"
    },
    {
      id: 181,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS417",
      nama_mk: "MANAJEMEN OPERASIONAL DAN PEMELIHARAAN KONSTRUKSI",
      kelas: "11",
      nama_dosen: "Febriyanti Maulina, S.T., MT.",
      nip: "197802242008122002",
      ruang: "JTS",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "1"
    },
    {
      id: 182,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS418",
      nama_mk: "REKAYASA SISTEM AIR BERSIH",
      kelas: "11",
      nama_dosen: "Prof. Dr. Azmeri, S.T, M.T.",
      nip: "197308201998032001",
      ruang: "RK. MTS - I",
      hari: "Rabu",
      waktu: "11:30-13:10",
      peserta: "13"
    },
    {
      id: 183,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS420",
      nama_mk: "LOGISTIK DAN ANGKUTAN BARANG",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Sofyan, M.Sc.Eng., IPU",
      nip: "195905121987021001",
      ruang: "RK. MTS - I",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "18"
    },
    {
      id: 184,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS422",
      nama_mk: "KESELAMATAN TRANSPORTASI DAN LINGKUNGAN",
      kelas: "11",
      nama_dosen: "Ir. Lulusi, S.T., M.Sc",
      nip: "197301291999032001",
      ruang: "D3- X",
      hari: "Rabu",
      waktu: "14:00-15:40",
      peserta: "29"
    },
    {
      id: 185,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS422",
      nama_mk: "KESELAMATAN TRANSPORTASI DAN LINGKUNGAN",
      kelas: "12",
      nama_dosen: "Dr. Noer Fadhly, ST., MT",
      nip: "197507312002121002",
      ruang: "A12-302",
      hari: "Rabu",
      waktu: "14:00-15:40",
      peserta: "12"
    },
    {
      id: 186,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS423",
      nama_mk: "REKAYASA RAWA",
      kelas: "11",
      nama_dosen: "Dr. Ir. Eldina Fatimah, M.Sc.Eng",
      nip: "196408281989032002",
      ruang: "jts",
      hari: "Sabtu",
      waktu: "14:00-15:40",
      peserta: "1"
    },
    {
      id: 187,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS424",
      nama_mk: "TOPIK KHUSUS MRK",
      kelas: "11",
      nama_dosen: "Febriyanti Maulina, S.T., MT.",
      nip: "197802242008122002",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "14:00-15:40",
      peserta: "5"
    },
    {
      id: 188,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS424",
      nama_mk: "TOPIK KHUSUS MRK",
      kelas: "12",
      nama_dosen: "Ir. Ibnu Abbas, M.Sc",
      nip: "196203281989031002",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "14:00-15:40",
      peserta: "2"
    },
    {
      id: 189,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS424",
      nama_mk: "TOPIK KHUSUS MRK",
      kelas: "13",
      nama_dosen: "Ir. Muhammad Jamil, M.T",
      nip: "196501301991021001",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "14:00-15:40",
      peserta: "1"
    },
    {
      id: 190,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS424",
      nama_mk: "TOPIK KHUSUS MRK",
      kelas: "16",
      nama_dosen: "Dr. Anita Rauzana, S.T., MT.",
      nip: "198006202008122004",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "14:00-15:40",
      peserta: "5"
    },
    {
      id: 191,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS425",
      nama_mk: "MANAJEMEN BISNIS KONSTRUKSI",
      kelas: "11",
      nama_dosen: "Dr. Yusria Darma, ST, M.Eng.Sc",
      nip: "197301301999031002",
      ruang: "LAB. TRANSPORTASI TS",
      hari: "Sabtu",
      waktu: "14:00-15:40",
      peserta: "1"
    },
    {
      id: 192,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS425",
      nama_mk: "MANAJEMEN BISNIS KONSTRUKSI",
      kelas: "12",
      nama_dosen: "Febriyanti Maulina, S.T., MT.",
      nip: "197802242008122002",
      ruang: "JTS",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "1"
    },
    {
      id: 193,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS426",
      nama_mk: "PERANCANGAN JEMBATAN KAYU",
      kelas: "11",
      nama_dosen: "Dr. Teuku Budi Aulia, S.T.Dipl,Ing",
      nip: "196705291994031001",
      ruang: "EKSTENSI 1",
      hari: "Sabtu",
      waktu: "14:00-15:40",
      peserta: "2"
    },
    {
      id: 194,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS431",
      nama_mk: "VALUE MANAGEMENT",
      kelas: "11",
      nama_dosen: "Febriyanti Maulina, S.T., MT.",
      nip: "197802242008122002",
      ruang: "JTS",
      hari: "Jumat",
      waktu: "8:00-9:40",
      peserta: "1"
    },
    {
      id: 195,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTS433",
      nama_mk: "MANAJEMEN PERALATAN KONSTRUKSI",
      kelas: "11",
      nama_dosen: "Nurul Malahayati, ST., M.Sc",
      nip: "196911301998022001",
      ruang: "JTS",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "1"
    },
    {
      id: 196,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTSP01",
      nama_mk: "KERJA PRAKTEK",
      kelas: "11",
      nama_dosen: "Said Amir Azan, ST, M. Eng.Sc.",
      nip: "197305221998021002",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "14:00-15:40",
      peserta: "38"
    },
    {
      id: 197,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410101",
      nama_prodi: "Teknik Sipil",
      kode_mk: "PTSPA1",
      nama_mk: "TUGAS AKHIR",
      kelas: "11",
      nama_dosen: "Dr. Renni Angraini, ST., M.Eng",
      nip: "197109231997022001",
      ruang: "JTS",
      hari: "Sabtu",
      waktu: "14:00-15:40",
      peserta: "188"
    },
    {
      id: 198,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR104",
      nama_mk: "AZAS DAN METODE PERANCANGAN ARSITEKTUR I",
      kelas: "11",
      nama_dosen: "Irfandi, S.T., M.T.",
      nip: "197812232002121003",
      ruang: "A21-203",
      hari: "Jumat",
      waktu: "16:03-18:15",
      peserta: "25"
    },
    {
      id: 199,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR104",
      nama_mk: "AZAS DAN METODE PERANCANGAN ARSITEKTUR I",
      kelas: "12",
      nama_dosen: "Dr. Safwan, ST, M.Eng.Sc",
      nip: "197001011997021001",
      ruang: "A14-303",
      hari: "Jumat",
      waktu: "16:35-18:15",
      peserta: "20"
    },
    {
      id: 200,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR104",
      nama_mk: "AZAS DAN METODE PERANCANGAN ARSITEKTUR I",
      kelas: "13",
      nama_dosen: "Dr. Ir. Dyah Erti Idawati, M.T.",
      nip: "196707031997022001",
      ruang: "A14-302",
      hari: "Jumat",
      waktu: "16:35-18:15",
      peserta: "23"
    },
    {
      id: 201,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR104",
      nama_mk: "AZAS DAN METODE PERANCANGAN ARSITEKTUR I",
      kelas: "14",
      nama_dosen: "Ir. Irzaidi, MS.A",
      nip: "196311301994121001",
      ruang: "A25-203",
      hari: "Jumat",
      waktu: "16:35-18:15",
      peserta: "23"
    },
    {
      id: 202,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR104",
      nama_mk: "AZAS DAN METODE PERANCANGAN ARSITEKTUR I",
      kelas: "15",
      nama_dosen: "Sarvina Fitri Rizky, ST., MT",
      nip: "198905062019032025",
      ruang: "A14-303",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "27"
    },
    {
      id: 203,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR104",
      nama_mk: "AZAS DAN METODE PERANCANGAN ARSITEKTUR I",
      kelas: "16",
      nama_dosen: "Ir. Muslimsyah, M.Sc",
      nip: "196109281988101001",
      ruang: "A12-303",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "21"
    },
    {
      id: 204,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR112",
      nama_mk: "CAAD",
      kelas: "11",
      nama_dosen: "Burhan Nasution, S.T. MT",
      nip: "197011022000031002",
      ruang: "LAB MULTIMEDIA ARSIT",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "26"
    },
    {
      id: 205,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR112",
      nama_mk: "CAAD",
      kelas: "12",
      nama_dosen: "Zulhadi Sahputra, S.T., M.T.",
      nip: "198608122015041004",
      ruang: "LAB MULTIMEDIA ARSIT",
      hari: "Rabu",
      waktu: "14:00-16:30",
      peserta: "24"
    },
    {
      id: 206,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR112",
      nama_mk: "CAAD",
      kelas: "13",
      nama_dosen: "Riza Priandi, S.T. MT",
      nip: "197006101998021002",
      ruang: "A12-303",
      hari: "Rabu",
      waktu: "10:45-12:30",
      peserta: "25"
    },
    {
      id: 207,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR112",
      nama_mk: "CAAD",
      kelas: "14",
      nama_dosen: "Irfandi, S.T., M.T.",
      nip: "197812232002121003",
      ruang: "LAB MULTIMEDIA ARSIT",
      hari: "Rabu",
      waktu: "16:30-17:59",
      peserta: "25"
    },
    {
      id: 208,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR112",
      nama_mk: "CAAD",
      kelas: "15",
      nama_dosen: "Sofyan, S.T., M.T.",
      nip: "197112071998021001",
      ruang: "LAB MULTIMEDIA ARSIT",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 209,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR112",
      nama_mk: "CAAD",
      kelas: "16",
      nama_dosen: "Zulfikar Taqiuddin, S.Sn., M.T.",
      nip: "196912232003121001",
      ruang: "LAB MULTIMEDIA ARSIT",
      hari: "Jumat",
      waktu: "16:30-17:59",
      peserta: "12"
    },
    {
      id: 210,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR114",
      nama_mk: "STUDIO STRUKTUR, KONSTRUKSI DAN BAHAN I",
      kelas: "11",
      nama_dosen: "Ir. Mirza, M.T.",
      nip: "196212161991021001",
      ruang: "RB - 2A",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "22"
    },
    {
      id: 211,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR114",
      nama_mk: "STUDIO STRUKTUR, KONSTRUKSI DAN BAHAN I",
      kelas: "12",
      nama_dosen: "Burhan Nasution, S.T. MT",
      nip: "197011022000031002",
      ruang: "STUDIO - D",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "21"
    },
    {
      id: 212,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR114",
      nama_mk: "STUDIO STRUKTUR, KONSTRUKSI DAN BAHAN I",
      kelas: "13",
      nama_dosen: "Sofyan, S.T., M.T.",
      nip: "197112071998021001",
      ruang: "STUDIO - D",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "22"
    },
    {
      id: 213,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR114",
      nama_mk: "STUDIO STRUKTUR, KONSTRUKSI DAN BAHAN I",
      kelas: "14",
      nama_dosen: "Erna Meutia, S.T., M.T.",
      nip: "196909021998022001",
      ruang: "STUDIO - B",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "23"
    },
    {
      id: 214,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR114",
      nama_mk: "STUDIO STRUKTUR, KONSTRUKSI DAN BAHAN I",
      kelas: "15",
      nama_dosen: "Dr. Abdul Munir, S.T,. M.T.",
      nip: "197207081998021001",
      ruang: "A12-301 A",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "25"
    },
    {
      id: 215,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR114",
      nama_mk: "STUDIO STRUKTUR, KONSTRUKSI DAN BAHAN I",
      kelas: "16",
      nama_dosen: "Riza Priandi, S.T. MT",
      nip: "197006101998021002",
      ruang: "Ruang : RB - 1A",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "24"
    },
    {
      id: 216,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR116",
      nama_mk: "STUDIO PERANCANGAN ARSITEKTUR I",
      kelas: "11",
      nama_dosen: "Masdar Djamaluddin, S.T., M.T.",
      nip: "197105211999031002",
      ruang: "STUDIO - A",
      hari: "Selasa",
      waktu: "8:10-9:40",
      peserta: "22"
    },
    {
      id: 217,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR116",
      nama_mk: "STUDIO PERANCANGAN ARSITEKTUR I",
      kelas: "12",
      nama_dosen: "Dr. Laina Hilma Sari, S.T, M.Sc",
      nip: "198007122006042003",
      ruang: "STUDIO - B",
      hari: "Selasa",
      waktu: "8:10-9:40",
      peserta: "23"
    },
    {
      id: 218,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR116",
      nama_mk: "STUDIO PERANCANGAN ARSITEKTUR I",
      kelas: "13",
      nama_dosen: "Nasrullah Ridwan, S.T., MT",
      nip: "196805191999031001",
      ruang: "STUDIO - C",
      hari: "Selasa",
      waktu: "8:10-9:40",
      peserta: "20"
    },
    {
      id: 219,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR116",
      nama_mk: "STUDIO PERANCANGAN ARSITEKTUR I",
      kelas: "14",
      nama_dosen: "Dr. Cut Dewi, ST., MT,M.Sc",
      nip: "197807152002122002",
      ruang: "STUDIO - D",
      hari: "Selasa",
      waktu: "8:10-9:40",
      peserta: "22"
    },
    {
      id: 220,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR116",
      nama_mk: "STUDIO PERANCANGAN ARSITEKTUR I",
      kelas: "15",
      nama_dosen: "Dr. Safwan, ST, M.Eng.Sc",
      nip: "197001011997021001",
      ruang: "RB - 2A",
      hari: "Jumat",
      waktu: "16:35-18:15",
      peserta: "22"
    },
    {
      id: 221,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR116",
      nama_mk: "STUDIO PERANCANGAN ARSITEKTUR I",
      kelas: "16",
      nama_dosen: "Cut Nursaniah, ST., MT",
      nip: "196810131999032002",
      ruang: "RB - 2A",
      hari: "Jumat",
      waktu: "16:35-18:15",
      peserta: "22"
    },
    {
      id: 222,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR212",
      nama_mk: "MATEMATIKA ARSITEKTUR",
      kelas: "11",
      nama_dosen: "Burhan Nasution, S.T. MT",
      nip: "197011022000031002",
      ruang: "A12-303",
      hari: "Selasa",
      waktu: "14:00-16:30",
      peserta: "25"
    },
    {
      id: 223,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR212",
      nama_mk: "MATEMATIKA ARSITEKTUR",
      kelas: "12",
      nama_dosen: "Sofyan, S.T., M.T.",
      nip: "197112071998021001",
      ruang: "A14-303",
      hari: "Selasa",
      waktu: "14:00-16:30",
      peserta: "30"
    },
    {
      id: 224,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR212",
      nama_mk: "MATEMATIKA ARSITEKTUR",
      kelas: "13",
      nama_dosen: "Riza Priandi, S.T. MT",
      nip: "197006101998021002",
      ruang: "A14 - 301 A",
      hari: "Selasa",
      waktu: "14:00-16:30",
      peserta: "30"
    },
    {
      id: 225,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR212",
      nama_mk: "MATEMATIKA ARSITEKTUR",
      kelas: "14",
      nama_dosen: "Dr. Abdul Munir, S.T,. M.T.",
      nip: "197207081998021001",
      ruang: "A14 - 301 B",
      hari: "Senin",
      waktu: "14:00-16:30",
      peserta: "28"
    },
    {
      id: 226,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR214",
      nama_mk: "ARSITEKTUR LINGKUNGAN DAN KEBENCANAAN",
      kelas: "11",
      nama_dosen: "Zahriah, S.T., MT",
      nip: "196909131997032001",
      ruang: "A12 - 301 B",
      hari: "Rabu",
      waktu: "10:45-12:30",
      peserta: "40"
    },
    {
      id: 227,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR214",
      nama_mk: "ARSITEKTUR LINGKUNGAN DAN KEBENCANAAN",
      kelas: "12",
      nama_dosen: "Ir. Muslimsyah, M.Sc",
      nip: "196109281988101001",
      ruang: "A14-303",
      hari: "Rabu",
      waktu: "10:45-12:30",
      peserta: "32"
    },
    {
      id: 228,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR214",
      nama_mk: "ARSITEKTUR LINGKUNGAN DAN KEBENCANAAN",
      kelas: "13",
      nama_dosen: "Dr. Laina Hilma Sari, S.T, M.Sc",
      nip: "198007122006042003",
      ruang: "A14 - 301 B",
      hari: "Rabu",
      waktu: "10:45-12:30",
      peserta: "30"
    },
    {
      id: 229,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR214",
      nama_mk: "ARSITEKTUR LINGKUNGAN DAN KEBENCANAAN",
      kelas: "14",
      nama_dosen: "Dr. Abdul Munir, S.T,. M.T.",
      nip: "197207081998021001",
      ruang: "A14 - 301 A",
      hari: "Rabu",
      waktu: "14:00-15:30",
      peserta: "31"
    },
    {
      id: 230,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR214",
      nama_mk: "ARSITEKTUR LINGKUNGAN DAN KEBENCANAAN",
      kelas: "15",
      nama_dosen: "Muhammad Haiqal, ST, M.Sc",
      nip: "197908122006041004",
      ruang: "EXTENSI 1",
      hari: "Rabu",
      waktu: "10:45-12:30",
      peserta: "32"
    },
    {
      id: 231,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR214",
      nama_mk: "ARSITEKTUR LINGKUNGAN DAN KEBENCANAAN",
      kelas: "16",
      nama_dosen: "Ardian Ariatsyah, ST.MT",
      nip: "196909151999031001",
      ruang: "A34-202",
      hari: "Rabu",
      waktu: "10:45-12:30",
      peserta: "32"
    },
    {
      id: 232,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR215",
      nama_mk: "STUDIO PERANCANGAN ARSITEKTUR II",
      kelas: "11",
      nama_dosen: "Zulhadi Sahputra, S.T., M.T.",
      nip: "198608122015041004",
      ruang: "STUDIO - A",
      hari: "Kamis",
      waktu: "14:00-16:30",
      peserta: "7"
    },
    {
      id: 233,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR216",
      nama_mk: "PERUMAHAN DAN PEMUKIMAN",
      kelas: "11",
      nama_dosen: "Dr. Ir. Dyah Erti Idawati, M.T.",
      nip: "196707031997022001",
      ruang: "A14-303",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "34"
    },
    {
      id: 234,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR216",
      nama_mk: "PERUMAHAN DAN PEMUKIMAN",
      kelas: "12",
      nama_dosen: "Aghnia Zahrah, ST., M.Ars",
      nip: "199306072019032029",
      ruang: "A25-201",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "35"
    },
    {
      id: 235,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR216",
      nama_mk: "PERUMAHAN DAN PEMUKIMAN",
      kelas: "13",
      nama_dosen: "Dr. Safwan, ST, M.Eng.Sc",
      nip: "197001011997021001",
      ruang: "A21-201",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "33"
    },
    {
      id: 236,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR216",
      nama_mk: "PERUMAHAN DAN PEMUKIMAN",
      kelas: "14",
      nama_dosen: "Ir. Mirza, M.T.",
      nip: "196212161991021001",
      ruang: "A14-303",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "20"
    },
    {
      id: 237,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR216",
      nama_mk: "PERUMAHAN DAN PEMUKIMAN",
      kelas: "15",
      nama_dosen: "Dr.Ir. Elysa Wulandari, M.T.",
      nip: "196410191990022001",
      ruang: "A21-202",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "32"
    },
    {
      id: 238,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR216",
      nama_mk: "PERUMAHAN DAN PEMUKIMAN",
      kelas: "16",
      nama_dosen: "Muhammad Haiqal, ST, M.Sc",
      nip: "197908122006041004",
      ruang: "RB - 5A",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "34"
    },
    {
      id: 239,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR220",
      nama_mk: "STUDIO PERANCANGAN ARSITEKTUR III",
      kelas: "11",
      nama_dosen: "Muhammad Heru Arie Edytia, S.T., M.Ars.",
      nip: "198903282019031008",
      ruang: "STUDIO - A",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "19"
    },
    {
      id: 240,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR220",
      nama_mk: "STUDIO PERANCANGAN ARSITEKTUR III",
      kelas: "12",
      nama_dosen: "Ir. Muslimsyah, M.Sc",
      nip: "196109281988101001",
      ruang: "STUDIO - C",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 241,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR220",
      nama_mk: "STUDIO PERANCANGAN ARSITEKTUR III",
      kelas: "13",
      nama_dosen: "Dr. Laina Hilma Sari, S.T, M.Sc",
      nip: "198007122006042003",
      ruang: "STUDIO - B",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "26"
    },
    {
      id: 242,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR220",
      nama_mk: "STUDIO PERANCANGAN ARSITEKTUR III",
      kelas: "14",
      nama_dosen: "Husnus Sawab, S.T., M.T.",
      nip: "196808231999031002",
      ruang: "STUDIO - D",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "28"
    },
    {
      id: 243,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR222",
      nama_mk: "SEJARAH DAN PERKEMBANGAN ARSITEKTUR I",
      kelas: "11",
      nama_dosen: "Cut Nursaniah, ST., MT",
      nip: "196810131999032002",
      ruang: "A21-202",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "22"
    },
    {
      id: 244,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR222",
      nama_mk: "SEJARAH DAN PERKEMBANGAN ARSITEKTUR I",
      kelas: "12",
      nama_dosen: "Zahriah, S.T., MT",
      nip: "196909131997032001",
      ruang: "A14-302",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "22"
    },
    {
      id: 245,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR222",
      nama_mk: "SEJARAH DAN PERKEMBANGAN ARSITEKTUR I",
      kelas: "13",
      nama_dosen: "Dr. Cut Dewi, ST., MT,M.Sc",
      nip: "197807152002122002",
      ruang: "A14-303",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "27"
    },
    {
      id: 246,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR222",
      nama_mk: "SEJARAH DAN PERKEMBANGAN ARSITEKTUR I",
      kelas: "14",
      nama_dosen: "Nasrullah Ridwan, S.T., MT",
      nip: "196805191999031001",
      ruang: "A14 - 301 B",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "30"
    },
    {
      id: 247,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR226",
      nama_mk: "PERANCANGAN TAPAK",
      kelas: "11",
      nama_dosen: "Laila Qadri, S.T., M.T",
      nip: "196910111997022003",
      ruang: "A14-302",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "29"
    },
    {
      id: 248,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR226",
      nama_mk: "PERANCANGAN TAPAK",
      kelas: "12",
      nama_dosen: "Pratitou Arafat, SP., MLA",
      nip: "198811202020060111",
      ruang: "EXTENSI 4",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "24"
    },
    {
      id: 249,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR226",
      nama_mk: "PERANCANGAN TAPAK",
      kelas: "13",
      nama_dosen: "Dr. Mirza Fuady, ST., MT.",
      nip: "197002242008121002",
      ruang: "A14-303",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "29"
    },
    {
      id: 250,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR226",
      nama_mk: "PERANCANGAN TAPAK",
      kelas: "14",
      nama_dosen: "Ardian Ariatsyah, ST.MT",
      nip: "196909151999031001",
      ruang: "A12-303",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "24"
    },
    {
      id: 251,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR228",
      nama_mk: "STUDIO STRUKTUR, KONSTRUKSI DAN BAHAN III",
      kelas: "11",
      nama_dosen: "Erna Meutia, S.T., M.T.",
      nip: "196909021998022001",
      ruang: "STUDIO - A",
      hari: "Kamis",
      waktu: "8:00-10:30",
      peserta: "16"
    },
    {
      id: 252,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR228",
      nama_mk: "STUDIO STRUKTUR, KONSTRUKSI DAN BAHAN III",
      kelas: "12",
      nama_dosen: "Riza Priandi, S.T. MT",
      nip: "197006101998021002",
      ruang: "STUDIO - C",
      hari: "Kamis",
      waktu: "8:00-10:30",
      peserta: "27"
    },
    {
      id: 253,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR228",
      nama_mk: "STUDIO STRUKTUR, KONSTRUKSI DAN BAHAN III",
      kelas: "13",
      nama_dosen: "Burhan Nasution, S.T. MT",
      nip: "197011022000031002",
      ruang: "STUDIO - D",
      hari: "Kamis",
      waktu: "8:00-10:30",
      peserta: "26"
    },
    {
      id: 254,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR228",
      nama_mk: "STUDIO STRUKTUR, KONSTRUKSI DAN BAHAN III",
      kelas: "14",
      nama_dosen: "Sofyan, S.T., M.T.",
      nip: "197112071998021001",
      ruang: "STUDIO - B",
      hari: "Kamis",
      waktu: "8:00-10:30",
      peserta: "30"
    },
    {
      id: 255,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR310",
      nama_mk: "PERENCANAAN DAN PERANCANGAN KOTA",
      kelas: "11",
      nama_dosen: "Irfandi, S.T., M.T.",
      nip: "197812232002121003",
      ruang: "RB - 3A",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "16"
    },
    {
      id: 256,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR310",
      nama_mk: "PERENCANAAN DAN PERANCANGAN KOTA",
      kelas: "12",
      nama_dosen: "Dr. Mirza Fuady, ST., MT.",
      nip: "197002242008121002",
      ruang: "RB - 3A",
      hari: "Rabu",
      waktu: "16:35-18:15",
      peserta: "11"
    },
    {
      id: 257,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR310",
      nama_mk: "PERENCANAAN DAN PERANCANGAN KOTA",
      kelas: "13",
      nama_dosen: "Teuku Ivan, S.T., M.T.",
      nip: "196801051999031001",
      ruang: "RB - 3A",
      hari: "Sabtu",
      waktu: "14:00-15:40",
      peserta: "27"
    },
    {
      id: 258,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR310",
      nama_mk: "PERENCANAAN DAN PERANCANGAN KOTA",
      kelas: "14",
      nama_dosen: "Dr. Ir. Mirza Irwansyah, MBA. MLA.",
      nip: "196205261987101001",
      ruang: "Jurusan arsitektur",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "2"
    },
    {
      id: 259,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR314",
      nama_mk: "STUDIO PERANCANGAN ARSITEKTUR V",
      kelas: "11",
      nama_dosen: "Irfandi, S.T., M.T.",
      nip: "197812232002121003",
      ruang: "A14-303",
      hari: "Rabu",
      waktu: "14:00-16:30",
      peserta: "35"
    },
    {
      id: 260,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR314",
      nama_mk: "STUDIO PERANCANGAN ARSITEKTUR V",
      kelas: "12",
      nama_dosen: "Ir. Muslimsyah, M.Sc",
      nip: "196109281988101001",
      ruang: "A34-202",
      hari: "Rabu",
      waktu: "14:00-16:30",
      peserta: "33"
    },
    {
      id: 261,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR314",
      nama_mk: "STUDIO PERANCANGAN ARSITEKTUR V",
      kelas: "13",
      nama_dosen: "Dr. Ir. Izziah, M.Sc",
      nip: "196207311995122001",
      ruang: "STUDIO - A",
      hari: "Rabu",
      waktu: "14:00-16:30",
      peserta: "33"
    },
    {
      id: 262,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR323",
      nama_mk: "STUDIO PERANCANGAN ARSITEKTUR IV",
      kelas: "11",
      nama_dosen: "Ardian Ariatsyah, ST.MT",
      nip: "196909151999031001",
      ruang: " Jurusan arsitektur",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "10"
    },
    {
      id: 263,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR403",
      nama_mk: "PERANCANGAN TEMATIK",
      kelas: "11",
      nama_dosen: "Dr. Mirza Fuady, ST., MT.",
      nip: "197002242008121002",
      ruang: "A12-303",
      hari: "Sabtu",
      waktu: "16:35-17:59",
      peserta: "6"
    },
    {
      id: 264,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TAR407",
      nama_mk: "STUDIO PERANCANGAN ARSITEKTUR VI",
      kelas: "11",
      nama_dosen: "Ir. Muslimsyah, M.Sc",
      nip: "196109281988101001",
      ruang: " Jurusan arsitektur",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "3"
    },
    {
      id: 265,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP05",
      nama_mk: "SEMINAR PERANCANGAN",
      kelas: "11",
      nama_dosen: "Ir. Muslimsyah, M.Sc",
      nip: "196109281988101001",
      ruang: " Jurusan arsitektur",
      hari: "Sabtu",
      waktu: "10:45-12:30",
      peserta: "12"
    },
    {
      id: 266,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP07",
      nama_mk: "SEMINAR PENELITIAN",
      kelas: "11",
      nama_dosen: "Dr. Ir. Izziah, M.Sc",
      nip: "196207311995122001",
      ruang: " Jurusan arsitektur",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "7"
    },
    {
      id: 267,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP09",
      nama_mk: "ETIKA DAN PRAKTEK PROFESI",
      kelas: "11",
      nama_dosen: "Ir. Mirza, M.T.",
      nip: "196212161991021001",
      ruang: " Jurusan arsitektur",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "31"
    },
    {
      id: 268,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP11",
      nama_mk: "MORFOLOGI DAN TIPOLOGI KOTA",
      kelas: "11",
      nama_dosen: "Dr. Mirza Fuady, ST., MT.",
      nip: "197002242008121002",
      ruang: "A12-303",
      hari: "Sabtu",
      waktu: "14:00-16:30",
      peserta: "4"
    },
    {
      id: 269,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP12",
      nama_mk: "PERANCANGAN PERMUKIMAN",
      kelas: "11",
      nama_dosen: "Aghnia Zahrah, ST., M.Ars",
      nip: "199306072019032029",
      ruang: "Jurusan arsitektur",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "26"
    },
    {
      id: 270,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP13",
      nama_mk: "PERMUKIMAN TANGGAP BENCANA",
      kelas: "11",
      nama_dosen: "Dr. Safwan, ST, M.Eng.Sc",
      nip: "197001011997021001",
      ruang: "A34-202",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "-1"
    },
    {
      id: 271,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP14",
      nama_mk: "ARSITEKTUR LANSKAP",
      kelas: "11",
      nama_dosen: "Dr. Ir. Mirza Irwansyah, MBA. MLA.",
      nip: "196205261987101001",
      ruang: " Jurusan arsitektur",
      hari: "Selasa",
      waktu: "10:00-10:30",
      peserta: "14"
    },
    {
      id: 272,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP16",
      nama_mk: "ARSITEKTUR KOTA",
      kelas: "11",
      nama_dosen: "Irfandi, S.T., M.T.",
      nip: "197812232002121003",
      ruang: " A12-303",
      hari: "Senin",
      waktu: "10:45-12:30",
      peserta: "21"
    },
    {
      id: 273,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP17",
      nama_mk: "STATISTIKA",
      kelas: "11",
      nama_dosen: "Dr. Abdul Munir, S.T,. M.T.",
      nip: "197207081998021001",
      ruang: " A14-303",
      hari: "Sabtu",
      waktu: "14:00-16:30",
      peserta: "0"
    },
    {
      id: 274,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP22",
      nama_mk: "FOTOGRAFI ARSITEKTUR",
      kelas: "11",
      nama_dosen: "Masdar Djamaluddin, S.T., M.T.",
      nip: "197105211999031002",
      ruang: "A34-202",
      hari: "Sabtu",
      waktu: "14:00-14:50",
      peserta: "22"
    },
    {
      id: 275,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP24",
      nama_mk: "ARSITEKTUR PERILAKU",
      kelas: "11",
      nama_dosen: "Ir. Muslimsyah, M.Sc",
      nip: "196109281988101001",
      ruang: " A14-303",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "29"
    },
    {
      id: 276,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP26",
      nama_mk: "KRITIK ARSITEKTUR",
      kelas: "11",
      nama_dosen: "Dr. Safwan, ST, M.Eng.Sc",
      nip: "197001011997021001",
      ruang: "A14-303",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "20"
    },
    {
      id: 277,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP34",
      nama_mk: "TEORI ARSITEKTUR II",
      kelas: "11",
      nama_dosen: "Dr. Ir. Izziah, M.Sc",
      nip: "196207311995122001",
      ruang: "A12-303",
      hari: "Selasa",
      waktu: "10:45-12:30",
      peserta: "27"
    },
    {
      id: 278,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP36",
      nama_mk: "ARSITEKTUR ISLAM LANJUTAN",
      kelas: "11",
      nama_dosen: "Dr. Ir. Izziah, M.Sc",
      nip: "196207311995122001",
      ruang: "A12-303",
      hari: "Rabu",
      waktu: "14:00-16:30",
      peserta: "5"
    },
    {
      id: 279,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP38",
      nama_mk: "SEJARAH DAN PERKEMBANGAN ARSITEKTUR III",
      kelas: "11",
      nama_dosen: "Cut Nursaniah, ST., MT",
      nip: "196810131999032002",
      ruang: "A14-303",
      hari: "Sabtu",
      waktu: "10:45-12:25",
      peserta: "25"
    },
    {
      id: 280,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP42",
      nama_mk: "PERENCANAAN DAN PERANCANGAN KOTA LANJUTAN",
      kelas: "11",
      nama_dosen: "Irfandi, S.T., M.T.",
      nip: "197812232002121003",
      ruang: "Jurusan arsitektur",
      hari: "Jumat",
      waktu: "14:00-16:30",
      peserta: "23"
    },
    {
      id: 281,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP42",
      nama_mk: "PERENCANAAN DAN PERANCANGAN KOTA LANJUTAN",
      kelas: "12",
      nama_dosen: "Cut Nursaniah, ST., MT",
      nip: "196810131999032002",
      ruang: "EXTENSI 1",
      hari: "Jumat",
      waktu: "14:00-16:30",
      peserta: "0"
    },
    {
      id: 282,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP44",
      nama_mk: "ARSITEKTUR LINGKUNGAN DAN KEBENCANAAN LANJUTAN",
      kelas: "11",
      nama_dosen: "Zahriah, S.T., MT",
      nip: "196909131997032001",
      ruang: "A21-203",
      hari: "Senin",
      waktu: "14:00-16:30",
      peserta: "14"
    },
    {
      id: 283,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP46",
      nama_mk: "MANAJEMEN PROYEK LANJUTAN",
      kelas: "11",
      nama_dosen: "Ir. Mirza, M.T.",
      nip: "196212161991021001",
      ruang: "A14-303",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "7"
    },
    {
      id: 284,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP52",
      nama_mk: "PERANCANGAN RUANG DALAM II",
      kelas: "11",
      nama_dosen: "Zulfikar Taqiuddin, S.Sn., M.T.",
      nip: "196912232003121001",
      ruang: "A12-303",
      hari: "Selasa",
      waktu: "16:35-18:00",
      peserta: "27"
    },
    {
      id: 285,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP54",
      nama_mk: "TEKNOLOGI BANGUNAN",
      kelas: "11",
      nama_dosen: "Erna Meutia, S.T., M.T.",
      nip: "196909021998022001",
      ruang: "A14-303",
      hari: "Senin",
      waktu: "16:30-18:00",
      peserta: "25"
    },
    {
      id: 286,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP56",
      nama_mk: "MATERIAL BANGUNAN",
      kelas: "11",
      nama_dosen: "Riza Priandi, S.T. MT",
      nip: "197006101998021002",
      ruang: "A14-303",
      hari: "Rabu",
      waktu: "16:35-17:59",
      peserta: "29"
    },
    {
      id: 287,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP62",
      nama_mk: "ARSITEKTUR PARAMETRIK",
      kelas: "11",
      nama_dosen: "Burhan Nasution, S.T. MT",
      nip: "197011022000031002",
      ruang: " Lab Multimedia",
      hari: "Senin",
      waktu: "16:30-17:59",
      peserta: "24"
    },
    {
      id: 288,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP64",
      nama_mk: "BUILDING INFORMATION MODELLING",
      kelas: "11",
      nama_dosen: "Riza Priandi, S.T. MT",
      nip: "197006101998021002",
      ruang: "A12-301 A",
      hari: "Rabu",
      waktu: "10:45-12:25",
      peserta: "27"
    },
    {
      id: 289,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP71",
      nama_mk: "REAL ESTATE",
      kelas: "11",
      nama_dosen: "Dr. Ir. Dyah Erti Idawati, M.T.",
      nip: "196707031997022001",
      ruang: " A12-301 A",
      hari: "Sabtu",
      waktu: "14:00-16:30",
      peserta: "22"
    },
    {
      id: 290,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP73",
      nama_mk: "LANSKAP KOTA",
      kelas: "11",
      nama_dosen: "Laila Qadri, S.T., M.T",
      nip: "196910111997022003",
      ruang: "A23-204",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "8"
    },
    {
      id: 291,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP75",
      nama_mk: "KONSERVASI ARSITEKTUR",
      kelas: "11",
      nama_dosen: "Dr. Cut Dewi, ST., MT,M.Sc",
      nip: "197807152002122002",
      ruang: "A23-205",
      hari: "Kamis",
      waktu: "14:00-16:30",
      peserta: "18"
    },
    {
      id: 292,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP81",
      nama_mk: "SIMULASI KINERJA BANGUNAN",
      kelas: "11",
      nama_dosen: "Erna Meutia, S.T., M.T.",
      nip: "196909021998022001",
      ruang: "SEMINAR TA",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "4"
    },
    {
      id: 293,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP83",
      nama_mk: "DESAIN DIGITAL ARSITEKTUR",
      kelas: "11",
      nama_dosen: "Burhan Nasution, S.T. MT",
      nip: "197011022000031002",
      ruang: "Lab Multimedia",
      hari: "Sabtu",
      waktu: "8:00-10:03",
      peserta: "26"
    },
    {
      id: 294,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP85",
      nama_mk: "MEDIA DESAIN ARSITEKTUR",
      kelas: "11",
      nama_dosen: "Muhammad Heru Arie Edytia, S.T., M.Ars.",
      nip: "198903282019031008",
      ruang: "A34-202",
      hari: "Senin",
      waktu: "10:45-12:30",
      peserta: "4"
    },
    {
      id: 295,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP91",
      nama_mk: "LANSKAP MANAJEMEN DAN RESTORASI",
      kelas: "11",
      nama_dosen: "Dr. Ir. Mirza Irwansyah, MBA. MLA.",
      nip: "196205261987101001",
      ruang: "SEMINAR TA",
      hari: "Jumat",
      waktu: "14:00-16:30",
      peserta: "2"
    },
    {
      id: 296,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP93",
      nama_mk: "SAINS ARSITEKTUR LANJUTAN",
      kelas: "11",
      nama_dosen: "Dr. Abdul Munir, S.T,. M.T.",
      nip: "197207081998021001",
      ruang: "A34-202",
      hari: "Sabtu",
      waktu: "10:30-12:35",
      peserta: "2"
    },
    {
      id: 297,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARP95",
      nama_mk: "SEMIOTIKA ARSITEKTUR",
      kelas: "11",
      nama_dosen: "Dr.Ir. Elysa Wulandari, M.T.",
      nip: "196410191990022001",
      ruang: " Jurusan arsitektur",
      hari: "Sabtu",
      waktu: "14:00-16:30",
      peserta: "0"
    },
    {
      id: 298,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410401",
      nama_prodi: "Arsitektur",
      kode_mk: "TARPA1",
      nama_mk: "TUGAS AKHIR (TA)",
      kelas: "11",
      nama_dosen: "Husnus Sawab, S.T., M.T.",
      nip: "196808231999031002",
      ruang: " Jurusan arsitektur",
      hari: "Minggu",
      waktu: "8:00-10:30",
      peserta: "121"
    },
    {
      id: 299,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK102",
      nama_mk: "MATEMATIKA II",
      kelas: "11",
      nama_dosen: "Zuhra, ST., M.Sc",
      nip: "197403181999032001",
      ruang: "A12-201",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "27"
    },
    {
      id: 300,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK102",
      nama_mk: "MATEMATIKA II",
      kelas: "12",
      nama_dosen: "Hisbullah, S.T., M.Eng.Sc",
      nip: "197007131997021002",
      ruang: "A12-202",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "26"
    },
    {
      id: 301,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK102",
      nama_mk: "MATEMATIKA II",
      kelas: "13",
      nama_dosen: "Mirna Rahmah Lubis, ST., MS",
      nip: "197710012003122001",
      ruang: "A23-205",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "25"
    },
    {
      id: 302,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK102",
      nama_mk: "MATEMATIKA II",
      kelas: "14",
      nama_dosen: "Dr. Azwar, ST, M.Eng.Sc",
      nip: "197005211998021001",
      ruang: "RB 4A",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "25"
    },
    {
      id: 303,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK104",
      nama_mk: "KIMIA ANALISIS II",
      kelas: "11",
      nama_dosen: "Dr. Ir. Cut Meurah Rosnelly, M.T",
      nip: "196801091994032003",
      ruang: "A21-201",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "30"
    },
    {
      id: 304,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK104",
      nama_mk: "KIMIA ANALISIS II",
      kelas: "12",
      nama_dosen: "Dr. Umi Fathanah, S.T., M.T.",
      nip: "197404032000122001",
      ruang: "A21-202",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "25"
    },
    {
      id: 305,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK104",
      nama_mk: "KIMIA ANALISIS II",
      kelas: "13",
      nama_dosen: "Ir. Pocut Nurul Alam, M.T.",
      nip: "196608221997022002",
      ruang: "RB 4A",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "11"
    },
    {
      id: 306,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK104",
      nama_mk: "KIMIA ANALISIS II",
      kelas: "14",
      nama_dosen: "Prof. Dr. Sri Mulyati, ST, MT",
      nip: "197302272000122001",
      ruang: "A23-205",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "25"
    },
    {
      id: 307,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK106",
      nama_mk: "PRAKTIKUM ANALISIS BAHAN",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Sri Aprilia, M.T",
      nip: "196704121993032001",
      ruang: "Jurusan.T.Kimia",
      hari: "Selasa",
      waktu: "8:00-15:40",
      peserta: "90"
    },
    {
      id: 308,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK108",
      nama_mk: "KIMIA ORGANIK II",
      kelas: "11",
      nama_dosen: "Dr. Umi Fathanah, S.T., M.T.",
      nip: "197404032000122001",
      ruang: "A21-201",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "30"
    },
    {
      id: 309,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK108",
      nama_mk: "KIMIA ORGANIK II",
      kelas: "12",
      nama_dosen: "Prof. Dr. Sri Mulyati, ST, MT",
      nip: "197302272000122001",
      ruang: "A21-202",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "25"
    },
    {
      id: 310,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK108",
      nama_mk: "KIMIA ORGANIK II",
      kelas: "13",
      nama_dosen: "Suraiya, S.T., MT",
      nip: "196806031998022001",
      ruang: "A23-205",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "25"
    },
    {
      id: 311,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK108",
      nama_mk: "KIMIA ORGANIK II",
      kelas: "14",
      nama_dosen: "Dr. Hesti Meilina, ST., M.Si",
      nip: "197605052003122001",
      ruang: "RB 4A",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "14"
    },
    {
      id: 312,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK110",
      nama_mk: "KIMIA FISIKA",
      kelas: "11",
      nama_dosen: "Lia Mairiza, S.T.MT",
      nip: "197405232000032001",
      ruang: "A21-202",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "30"
    },
    {
      id: 313,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK110",
      nama_mk: "KIMIA FISIKA",
      kelas: "12",
      nama_dosen: "Dr. Fachrul Razi, ST., M.T",
      nip: "197610182006041003",
      ruang: "A21-201",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 314,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK110",
      nama_mk: "KIMIA FISIKA",
      kelas: "13",
      nama_dosen: "Prof. Dr. M. Faisal, ST., M.Eng",
      nip: "197309061998021001",
      ruang: "A23-205",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "23"
    },
    {
      id: 315,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK110",
      nama_mk: "KIMIA FISIKA",
      kelas: "14",
      nama_dosen: "Mirna Rahmah Lubis, ST., MS",
      nip: "197710012003122001",
      ruang: "RB 4A",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "16"
    },
    {
      id: 316,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK112",
      nama_mk: "PENGANTAR TEKNIK KIMIA",
      kelas: "11",
      nama_dosen: "Dr. Ir. Syaubari, M.Sc",
      nip: "196508041992031002",
      ruang: "A21-201",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "30"
    },
    {
      id: 317,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK112",
      nama_mk: "PENGANTAR TEKNIK KIMIA",
      kelas: "12",
      nama_dosen: "Prof. Dr. Ir. Medyan Riza, M.Eng",
      nip: "195901151985031003",
      ruang: "A21-202",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "18"
    },
    {
      id: 318,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK112",
      nama_mk: "PENGANTAR TEKNIK KIMIA",
      kelas: "13",
      nama_dosen: "Prof. Dr. Ir. Yunardi, MA.Sc",
      nip: "196009151988101001",
      ruang: "A23-205",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "25"
    },
    {
      id: 319,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK112",
      nama_mk: "PENGANTAR TEKNIK KIMIA",
      kelas: "14",
      nama_dosen: "Prof. Dr. Ir. Mariana, M.Si",
      nip: "196707151993032003",
      ruang: "RB 4A",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "25"
    },
    {
      id: 320,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK114",
      nama_mk: "PENGETAHUAN BAHAN KONSTRUKSI TEKNIK KIMIA",
      kelas: "11",
      nama_dosen: "Ir. Mukhlishien, M.Eng.Sc",
      nip: "196208021992031003",
      ruang: "A21-201",
      hari: "Rabu",
      waktu: "10:45-12:25",
      peserta: "30"
    },
    {
      id: 321,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK114",
      nama_mk: "PENGETAHUAN BAHAN KONSTRUKSI TEKNIK KIMIA",
      kelas: "12",
      nama_dosen: "Sofyana, S.T., M.T.",
      nip: "197106261998022001",
      ruang: "A21-202",
      hari: "Rabu",
      waktu: "10:45-12:25",
      peserta: "25"
    },
    {
      id: 322,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK114",
      nama_mk: "PENGETAHUAN BAHAN KONSTRUKSI TEKNIK KIMIA",
      kelas: "13",
      nama_dosen: "Dr. Ir. Asri Gani, M.Eng",
      nip: "196603121998021001",
      ruang: "A21-202",
      hari: "Rabu",
      waktu: "10:45-12:25",
      peserta: "24"
    },
    {
      id: 323,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK114",
      nama_mk: "PENGETAHUAN BAHAN KONSTRUKSI TEKNIK KIMIA",
      kelas: "14",
      nama_dosen: "Dr. Farid Mulana, S.T., M.Eng",
      nip: "197202081997021001",
      ruang: "A21-202",
      hari: "Rabu",
      waktu: "10:45-12:25",
      peserta: "12"
    },
    {
      id: 324,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK202",
      nama_mk: "PROSES INDUSTRI KIMIA II",
      kelas: "11",
      nama_dosen: "Ir. Pocut Nurul Alam, M.T.",
      nip: "196608221997022002",
      ruang: "A12-201",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "23"
    },
    {
      id: 325,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK202",
      nama_mk: "PROSES INDUSTRI KIMIA II",
      kelas: "12",
      nama_dosen: "Prof. Dr. Ir. Husni Husin, M.T.",
      nip: "196506011994122001",
      ruang: "A21-201",
      hari: "Selasa",
      waktu: "16:35-18:15",
      peserta: "20"
    },
    {
      id: 326,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK202",
      nama_mk: "PROSES INDUSTRI KIMIA II",
      kelas: "13",
      nama_dosen: "Prof. Dr. Ir. Syahiddin DS, M.T.",
      nip: "195512051987021001",
      ruang: "A21-202",
      hari: "Selasa",
      waktu: "10:45-12:25",
      peserta: "15"
    },
    {
      id: 327,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK202",
      nama_mk: "PROSES INDUSTRI KIMIA II",
      kelas: "14",
      nama_dosen: "Dr. Farid Mulana, S.T., M.Eng",
      nip: "197202081997021001",
      ruang: "A21-202",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "20"
    },
    {
      id: 328,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK204",
      nama_mk: "OPERASI TEKNIK KIMIA II",
      kelas: "11",
      nama_dosen: "Lia Mairiza, S.T.MT",
      nip: "197405232000032001",
      ruang: "A21-201",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "30"
    },
    {
      id: 329,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK204",
      nama_mk: "OPERASI TEKNIK KIMIA II",
      kelas: "12",
      nama_dosen: "Prof. Dr. Ir. Syahiddin DS, M.T.",
      nip: "195512051987021001",
      ruang: "A21-202",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "16"
    },
    {
      id: 330,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK204",
      nama_mk: "OPERASI TEKNIK KIMIA II",
      kelas: "13",
      nama_dosen: "Prof. Dr. Ir. Suhendrayatna, M.Eng",
      nip: "196701011993031004",
      ruang: "A23-205",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "11"
    },
    {
      id: 331,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK204",
      nama_mk: "OPERASI TEKNIK KIMIA II",
      kelas: "14",
      nama_dosen: "Dr. Farid Mulana, S.T., M.Eng",
      nip: "197202081997021001",
      ruang: "RB 4A",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "26"
    },
    {
      id: 332,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK206",
      nama_mk: "TERMODINAMIKA TEKNIK KIMIA I",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Syahiddin DS, M.T.",
      nip: "195512051987021001",
      ruang: "A23-205",
      hari: "Kamis",
      waktu: "14:00-16:30",
      peserta: "30"
    },
    {
      id: 333,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK206",
      nama_mk: "TERMODINAMIKA TEKNIK KIMIA I",
      kelas: "12",
      nama_dosen: "Dr. Farid Mulana, S.T., M.Eng",
      nip: "197202081997021001",
      ruang: "Jurusan T.Kimia",
      hari: "Kamis",
      waktu: "14:00-16:30",
      peserta: "27"
    },
    {
      id: 334,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK206",
      nama_mk: "TERMODINAMIKA TEKNIK KIMIA I",
      kelas: "13",
      nama_dosen: "Prof. Dr. Mahidin, S.T., M.T.",
      nip: "197004031995121001",
      ruang: "A23-205",
      hari: "Kamis",
      waktu: "14:00-16:30",
      peserta: "25"
    },
    {
      id: 335,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK206",
      nama_mk: "TERMODINAMIKA TEKNIK KIMIA I",
      kelas: "14",
      nama_dosen: "Dr. Ir. Adisalamun, M.T.",
      nip: "196705271993031003",
      ruang: "Jurusan T.Kimia",
      hari: "Kamis",
      waktu: "14:00-16:30",
      peserta: "0"
    },
    {
      id: 336,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK208",
      nama_mk: "KINETIKA DAN KATALISA",
      kelas: "11",
      nama_dosen: "Dr. Ir. H. Komala Pontas",
      nip: "195805261987021001",
      ruang: "A23-205",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "19"
    },
    {
      id: 337,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK208",
      nama_mk: "KINETIKA DAN KATALISA",
      kelas: "12",
      nama_dosen: "Dr. Ir. Yanna Syamsuddin, M.Sc",
      nip: "196901131998022001",
      ruang: "A21-202",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "25"
    },
    {
      id: 338,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK208",
      nama_mk: "KINETIKA DAN KATALISA",
      kelas: "13",
      nama_dosen: "Prof. Dr. Ir. Husni Husin, M.T.",
      nip: "196506011994122001",
      ruang: "A23-205",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "25"
    },
    {
      id: 339,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK208",
      nama_mk: "KINETIKA DAN KATALISA",
      kelas: "14",
      nama_dosen: "Dr. Ir. Syaubari, M.Sc",
      nip: "196508041992031002",
      ruang: "RB4A",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "14"
    },
    {
      id: 340,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK210",
      nama_mk: "MATEMATIKA TEKNIK KIMIA",
      kelas: "11",
      nama_dosen: "Prof. Dr. Muhammad Dani Supardan, ST., MT",
      nip: "197207102000031002",
      ruang: "A21-201",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "30"
    },
    {
      id: 341,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK210",
      nama_mk: "MATEMATIKA TEKNIK KIMIA",
      kelas: "12",
      nama_dosen: "Dr. Fauzi, ST, MT.",
      nip: "197008111998031003",
      ruang: "A21-202",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 342,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK210",
      nama_mk: "MATEMATIKA TEKNIK KIMIA",
      kelas: "13",
      nama_dosen: "Dr. Ir. Darmadi, M.T.",
      nip: "196603231993031003",
      ruang: "RB 4A",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "6"
    },
    {
      id: 343,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK210",
      nama_mk: "MATEMATIKA TEKNIK KIMIA",
      kelas: "14",
      nama_dosen: "Prof. Dr. Abrar Muslim, S.T., M.Eng.",
      nip: "197205251999031002",
      ruang: "A23-205",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 344,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK212",
      nama_mk: "PRAKTIKUM TEKNIK KIMIA I",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Sri Aprilia, M.T",
      nip: "196704121993032001",
      ruang: "Jurusan T.Kimia",
      hari: "Senin",
      waktu: "8:00-16:30",
      peserta: "84"
    },
    {
      id: 345,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK214",
      nama_mk: "OPERASI TEKNIK KIMIA III",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Suhendrayatna, M.Eng",
      nip: "196701011993031004",
      ruang: "A21-201",
      hari: "Kamis",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 346,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK214",
      nama_mk: "OPERASI TEKNIK KIMIA III",
      kelas: "12",
      nama_dosen: "Dr. Mukramah, S.T.",
      nip: "199206102020011101",
      ruang: "A21-202",
      hari: "Kamis",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 347,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK214",
      nama_mk: "OPERASI TEKNIK KIMIA III",
      kelas: "13",
      nama_dosen: "Dr. Ir. Jakfar, M.Si",
      nip: "195905091991031002",
      ruang: "A23-205",
      hari: "Kamis",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 348,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK214",
      nama_mk: "OPERASI TEKNIK KIMIA III",
      kelas: "14",
      nama_dosen: "Prof. Dr. Ir. Medyan Riza, M.Eng",
      nip: "195901151985031003",
      ruang: "RB 4A",
      hari: "Kamis",
      waktu: "8:00-10:30",
      peserta: "11"
    },
    {
      id: 349,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK302",
      nama_mk: "KEWIRAUSAHAAN DAN PROFESIONALISME",
      kelas: "11",
      nama_dosen: "Ir. Anwar Thaib, M.Sc",
      nip: "195904181988111001",
      ruang: "jurusan T.Kimia",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "18"
    },
    {
      id: 350,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK302",
      nama_mk: "KEWIRAUSAHAAN DAN PROFESIONALISME",
      kelas: "12",
      nama_dosen: "Ir. Anwar Thaib, M.Sc",
      nip: "195904181988111001",
      ruang: "Jurusan T.Kimia",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "0"
    },
    {
      id: 351,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK302",
      nama_mk: "KEWIRAUSAHAAN DAN PROFESIONALISME",
      kelas: "13",
      nama_dosen: "Suraiya, S.T., MT",
      nip: "196806031998022001",
      ruang: "Jurusan T.Kimia",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "17"
    },
    {
      id: 352,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK304",
      nama_mk: "TEKNOLOGI BUANGAN INDUSTRI",
      kelas: "11",
      nama_dosen: "Prof. Dr. Nasrul, ST, MT",
      nip: "197210202000121001",
      ruang: "A21-201",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "33"
    },
    {
      id: 353,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK304",
      nama_mk: "TEKNOLOGI BUANGAN INDUSTRI",
      kelas: "12",
      nama_dosen: "Prof. Dr. Ir. Izarul Machdar, M.Eng",
      nip: "196509201992031003",
      ruang: "A21-202",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "20"
    },
    {
      id: 354,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK304",
      nama_mk: "TEKNOLOGI BUANGAN INDUSTRI",
      kelas: "13",
      nama_dosen: "Sofyana, S.T., M.T.",
      nip: "197106261998022001",
      ruang: "A23-205",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 355,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK306",
      nama_mk: "EKONOMI TEKNIK",
      kelas: "11",
      nama_dosen: "Ir. Anwar Thaib, M.Sc",
      nip: "195904181988111001",
      ruang: "A21-201",
      hari: "Jumat",
      waktu: "16:40-18:15",
      peserta: "22"
    },
    {
      id: 356,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK306",
      nama_mk: "EKONOMI TEKNIK",
      kelas: "12",
      nama_dosen: "Dr. Fachrul Razi, ST., M.T",
      nip: "197610182006041003",
      ruang: "A21-202",
      hari: "Jumat",
      waktu: "16:35-18:15",
      peserta: "26"
    },
    {
      id: 357,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK306",
      nama_mk: "EKONOMI TEKNIK",
      kelas: "13",
      nama_dosen: "Wahyu Rinaldi, ST, M. Sc",
      nip: "198101062006041001",
      ruang: "A23-205",
      hari: "Jumat",
      waktu: "16:35-18:15",
      peserta: "25"
    },
    {
      id: 358,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK308",
      nama_mk: "DINAMIKA DAN PENGENDALIAN PROSES",
      kelas: "11",
      nama_dosen: "Prof. Dr. Abrar Muslim, S.T., M.Eng.",
      nip: "197205251999031002",
      ruang: "RB4A",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "20"
    },
    {
      id: 359,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK308",
      nama_mk: "DINAMIKA DAN PENGENDALIAN PROSES",
      kelas: "12",
      nama_dosen: "Hisbullah, S.T., M.Eng.Sc",
      nip: "197007131997021002",
      ruang: "Jurusan T.Kimia",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 360,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK308",
      nama_mk: "DINAMIKA DAN PENGENDALIAN PROSES",
      kelas: "13",
      nama_dosen: "Dr. Azwar, ST, M.Eng.Sc",
      nip: "197005211998021001",
      ruang: "Jurusan T.Kimia",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "26"
    },
    {
      id: 361,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK310",
      nama_mk: "SINTESIS PROSES",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Izarul Machdar, M.Eng",
      nip: "196509201992031003",
      ruang: "Jurusan T.Kimia",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "28"
    },
    {
      id: 362,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK310",
      nama_mk: "SINTESIS PROSES",
      kelas: "12",
      nama_dosen: "Prof. Dr. Sri Mulyati, ST, MT",
      nip: "197302272000122001",
      ruang: "Jurusan T.Kimia",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "29"
    },
    {
      id: 363,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK310",
      nama_mk: "SINTESIS PROSES",
      kelas: "13",
      nama_dosen: "Prof. Dr. Ir. Mariana, M.Si",
      nip: "196707151993032003",
      ruang: "Jurusan T.Kimia",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 364,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK312",
      nama_mk: "MENGGAMBAR TEKNIK",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Suhendrayatna, M.Eng",
      nip: "196701011993031004",
      ruang: "A21-201",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "30"
    },
    {
      id: 365,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK312",
      nama_mk: "MENGGAMBAR TEKNIK",
      kelas: "12",
      nama_dosen: "Wahyu Rinaldi, ST, M. Sc",
      nip: "198101062006041001",
      ruang: "A21-202",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "30"
    },
    {
      id: 366,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK312",
      nama_mk: "MENGGAMBAR TEKNIK",
      kelas: "13",
      nama_dosen: "Dr. Ir. Asri Gani, M.Eng",
      nip: "196603121998021001",
      ruang: "A23-205",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "20"
    },
    {
      id: 367,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK314",
      nama_mk: "METODOLOGI PENELITIAN+SEMINAR PROPOSAL",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Sri Aprilia, M.T",
      nip: "196704121993032001",
      ruang: "Jurusan T.Kimia",
      hari: "Senin",
      waktu: "14:00-16:30",
      peserta: "26"
    },
    {
      id: 368,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK314",
      nama_mk: "METODOLOGI PENELITIAN+SEMINAR PROPOSAL",
      kelas: "12",
      nama_dosen: "Dr. Hesti Meilina, ST., M.Si",
      nip: "197605052003122001",
      ruang: "Jurusan T.Kimia",
      hari: "Senin",
      waktu: "14:00-16:30",
      peserta: "24"
    },
    {
      id: 369,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK314",
      nama_mk: "METODOLOGI PENELITIAN+SEMINAR PROPOSAL",
      kelas: "13",
      nama_dosen: "Prof. Dr. Muhammad Dani Supardan, ST., MT",
      nip: "197207102000031002",
      ruang: "Jurusan T.Kimia",
      hari: "Senin",
      waktu: "14:00-16:30",
      peserta: "24"
    },
    {
      id: 370,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK316",
      nama_mk: "REKAYASA BIOPROSES",
      kelas: "11",
      nama_dosen: "Sofyana, S.T., M.T.",
      nip: "197106261998022001",
      ruang: "Jurusan T.Kimia",
      hari: "Senin",
      waktu: "16:35-18:15",
      peserta: "19"
    },
    {
      id: 371,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK318",
      nama_mk: "TEKNOLOGI PENGOLAHAN LIMBAH CAIR DOMESTIK",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Izarul Machdar, M.Eng",
      nip: "196509201992031003",
      ruang: "Jurusan T.Kimia",
      hari: "Kamis",
      waktu: "16:35-18:15",
      peserta: "8"
    },
    {
      id: 372,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK320",
      nama_mk: "TEKNOLOGI FERMENTASI",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Suhendrayatna, M.Eng",
      nip: "196701011993031004",
      ruang: "Jurusan T.Kimia",
      hari: "Selasa",
      waktu: "16:35-18:15",
      peserta: "30"
    },
    {
      id: 373,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK322",
      nama_mk: "PENGENALAN PROSES AEROBIK DAN ANAEROBIK",
      kelas: "11",
      nama_dosen: "Prof. Dr. M. Faisal, ST., M.Eng",
      nip: "197309061998021001",
      ruang: "Jurusan T.Kimia",
      hari: "Rabu",
      waktu: "16:35-18:15",
      peserta: "8"
    },
    {
      id: 374,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK324",
      nama_mk: "PENGENDALIAN PENCEMARAN UDARA",
      kelas: "11",
      nama_dosen: "Dr. Ir. Asri Gani, M.Eng",
      nip: "196603121998021001",
      ruang: "A21-201",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "6"
    },
    {
      id: 375,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEK326",
      nama_mk: "PENGOLAHAN LIMBAH PADAT",
      kelas: "11",
      nama_dosen: "Dr. Ir. Asri Gani, M.Eng",
      nip: "196603121998021001",
      ruang: "RB4A",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "0"
    },
    {
      id: 376,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEKP02",
      nama_mk: "UJIAN KOMPREHENSIF",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Izarul Machdar, M.Eng",
      nip: "196509201992031003",
      ruang: "Jurusan T.Kimia",
      hari: "Senin",
      waktu: "8:00-16:30",
      peserta: "6"
    },
    {
      id: 377,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEKP04",
      nama_mk: "KERJA PRAKTEK",
      kelas: "11",
      nama_dosen: "Zuhra, ST., M.Sc",
      nip: "197403181999032001",
      ruang: "Jurusan T.Kimia",
      hari: "Senin",
      waktu: "8:00-16:30",
      peserta: "31"
    },
    {
      id: 378,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEKPA1",
      nama_mk: "PENELITIAN",
      kelas: "11",
      nama_dosen: "Prof. Dr. Sri Mulyati, ST, MT",
      nip: "197302272000122001",
      ruang: "Jurusan T.Kimia",
      hari: "Senin",
      waktu: "8:00-16:30",
      peserta: "63"
    },
    {
      id: 379,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410301",
      nama_prodi: "Teknik Kimia",
      kode_mk: "TEKPA2",
      nama_mk: "TUGAS AKHIR (PRARANCANGAN PABRIK)",
      kelas: "11",
      nama_dosen: "Prof. Dr. Sri Mulyati, ST, MT",
      nip: "197302272000122001",
      ruang: "Jurusan T.Kimia",
      hari: "Senin",
      waktu: "8:00-16:30",
      peserta: "100"
    },
    {
      id: 380,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL102",
      nama_mk: "KALKULUS II",
      kelas: "11",
      nama_dosen: "Mahmudi, S.Si., M.Si.",
      nip: "198207142014041002",
      ruang: "prodi",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "20"
    },
    {
      id: 381,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL102",
      nama_mk: "KALKULUS II",
      kelas: "12",
      nama_dosen: "Muhammad Arief Akbar, B.Sc., M.Sc",
      nip: "199106242020101101",
      ruang: "prodi",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "20"
    },
    {
      id: 382,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL102",
      nama_mk: "KALKULUS II",
      kelas: "13",
      nama_dosen: "Mahmudi, S.Si., M.Si.",
      nip: "198207142014041002",
      ruang: "prodi",
      hari: "Kamis",
      waktu: "16:35-18:00",
      peserta: "14"
    },
    {
      id: 383,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL104",
      nama_mk: "FISIKA II",
      kelas: "11",
      nama_dosen: "Dr. Muksin, S.Si, M.Si,M.Phil",
      nip: "197406252000121001",
      ruang: "prodi",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "10"
    },
    {
      id: 384,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL104",
      nama_mk: "FISIKA II",
      kelas: "12",
      nama_dosen: "Evi Yufita, S.Si., M.Si",
      nip: "197509202000122001",
      ruang: "prodi",
      hari: "Senin",
      waktu: "10:45-12:30",
      peserta: "20"
    },
    {
      id: 385,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL104",
      nama_mk: "FISIKA II",
      kelas: "13",
      nama_dosen: "Dr. Muksin, S.Si, M.Si,M.Phil",
      nip: "197406252000121001",
      ruang: "prod",
      hari: "Selasa",
      waktu: "9:45-0:00",
      peserta: "20"
    },
    {
      id: 386,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL106",
      nama_mk: "KIMIA II",
      kelas: "11",
      nama_dosen: "Ir. Pocut Nurul Alam, M.T.",
      nip: "196608221997022002",
      ruang: "prodi",
      hari: "Senin",
      waktu: "9:45-12:30",
      peserta: "20"
    },
    {
      id: 387,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL106",
      nama_mk: "KIMIA II",
      kelas: "12",
      nama_dosen: "Ir. Pocut Nurul Alam, M.T.",
      nip: "196608221997022002",
      ruang: "prodi",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "20"
    },
    {
      id: 388,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL106",
      nama_mk: "KIMIA II",
      kelas: "13",
      nama_dosen: "Ir. Pocut Nurul Alam, M.T.",
      nip: "196608221997022002",
      ruang: "prodi",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "15"
    },
    {
      id: 389,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL110",
      nama_mk: "PETROLOGI",
      kelas: "11",
      nama_dosen: "Fahri Adrian, B.Sc., M.Sc",
      nip: "199004292016011101",
      ruang: "prodi",
      hari: "Kamis",
      waktu: "9:45-11:30",
      peserta: "10"
    },
    {
      id: 390,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL110",
      nama_mk: "PETROLOGI",
      kelas: "12",
      nama_dosen: "Dewi Sartika, S.T., M.Eng.",
      nip: "198906072019032018",
      ruang: "prodi",
      hari: "Kamis",
      waktu: "8:00-9:45",
      peserta: "20"
    },
    {
      id: 391,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL110",
      nama_mk: "PETROLOGI",
      kelas: "13",
      nama_dosen: "Fahri Adrian, B.Sc., M.Sc",
      nip: "199004292016011101",
      ruang: "prodi",
      hari: "Jumat",
      waktu: "16:35-18:15",
      peserta: "20"
    },
    {
      id: 392,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL112",
      nama_mk: "GEOMORFOLOGI",
      kelas: "11",
      nama_dosen: "Fahri Adrian, B.Sc., M.Sc",
      nip: "199004292016011101",
      ruang: "r ktg 1",
      hari: "Rabu",
      waktu: "16:35-18:05",
      peserta: "21"
    },
    {
      id: 393,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL112",
      nama_mk: "GEOMORFOLOGI",
      kelas: "12",
      nama_dosen: "Fahri Adrian, B.Sc., M.Sc",
      nip: "199004292016011101",
      ruang: "r ktg 2",
      hari: "Rabu",
      waktu: "16:35-18:05",
      peserta: "21"
    },
    {
      id: 394,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL112",
      nama_mk: "GEOMORFOLOGI",
      kelas: "13",
      nama_dosen: "Fahri Adrian, B.Sc., M.Sc",
      nip: "199004292016011101",
      ruang: "r ktg 1",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "20"
    },
    {
      id: 395,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL112",
      nama_mk: "GEOMORFOLOGI",
      kelas: "14",
      nama_dosen: "Dr. Bambang Setiawan, ST, M.Eng.Sc.",
      nip: "197106032006041002",
      ruang: "RKTG 2",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "24"
    },
    {
      id: 396,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL112",
      nama_mk: "GEOMORFOLOGI",
      kelas: "15",
      nama_dosen: "Dr. Bambang Setiawan, ST, M.Eng.Sc.",
      nip: "197106032006041002",
      ruang: "RKTG 2",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "21"
    },
    {
      id: 397,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL204",
      nama_mk: "GEOLOGI SUMBER DAYA MINERAL",
      kelas: "11",
      nama_dosen: "Muhammad Arief Akbar, B.Sc., M.Sc",
      nip: "199106242020101101",
      ruang: "Prodi",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "30"
    },
    {
      id: 398,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL204",
      nama_mk: "GEOLOGI SUMBER DAYA MINERAL",
      kelas: "12",
      nama_dosen: "Muhammad Arief Akbar, B.Sc., M.Sc",
      nip: "199106242020101101",
      ruang: "Prodi",
      hari: "Kamis",
      waktu: "10:00-12:25",
      peserta: "3"
    },
    {
      id: 399,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL204",
      nama_mk: "GEOLOGI SUMBER DAYA MINERAL",
      kelas: "13",
      nama_dosen: "Muhammad Arief Akbar, B.Sc., M.Sc",
      nip: "199106242020101101",
      ruang: "Prodi",
      hari: "Kamis",
      waktu: "10:00-12:25",
      peserta: "14"
    },
    {
      id: 400,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL206",
      nama_mk: "SEDIMENTOLOGI DAN STRATIGRAFI",
      kelas: "11",
      nama_dosen: "Fahri Adrian, B.Sc., M.Sc",
      nip: "199004292016011101",
      ruang: "Prodi",
      hari: "Kamis",
      waktu: "14:00-15:00",
      peserta: "29"
    },
    {
      id: 401,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL206",
      nama_mk: "SEDIMENTOLOGI DAN STRATIGRAFI",
      kelas: "12",
      nama_dosen: "Hidayat Syah Putra, S.T., M.Sc.",
      nip: "198803292019031012",
      ruang: "Prodi",
      hari: "Kamis",
      waktu: "14:00-15:00",
      peserta: "13"
    },
    {
      id: 402,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL206",
      nama_mk: "SEDIMENTOLOGI DAN STRATIGRAFI",
      kelas: "13",
      nama_dosen: "Hidayat Syah Putra, S.T., M.Sc.",
      nip: "198803292019031012",
      ruang: "Prodi",
      hari: "Kamis",
      waktu: "14:00-15:00",
      peserta: "17"
    },
    {
      id: 403,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL208",
      nama_mk: "MITIGASI BENCANA GEOLOGI",
      kelas: "11",
      nama_dosen: "Dr. Bambang Setiawan, ST, M.Eng.Sc.",
      nip: "197106032006041002",
      ruang: "Prodi",
      hari: "Kamis",
      waktu: "14:00-15:00",
      peserta: "14"
    },
    {
      id: 404,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL208",
      nama_mk: "MITIGASI BENCANA GEOLOGI",
      kelas: "12",
      nama_dosen: "Dr. Bambang Setiawan, ST, M.Eng.Sc.",
      nip: "197106032006041002",
      ruang: "Prodi",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "29"
    },
    {
      id: 405,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL208",
      nama_mk: "MITIGASI BENCANA GEOLOGI",
      kelas: "13",
      nama_dosen: "Dr. Bambang Setiawan, ST, M.Eng.Sc.",
      nip: "197106032006041002",
      ruang: "Prodi",
      hari: "Sabtu",
      waktu: "8:00-9:40",
      peserta: "16"
    },
    {
      id: 406,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL210",
      nama_mk: "GEOLOGI LINGKUNGAN",
      kelas: "11",
      nama_dosen: "Dr. Bambang Setiawan, ST, M.Eng.Sc.",
      nip: "197106032006041002",
      ruang: "Prodi",
      hari: "Jumat",
      waktu: "8:00-12:25",
      peserta: "29"
    },
    {
      id: 407,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL210",
      nama_mk: "GEOLOGI LINGKUNGAN",
      kelas: "12",
      nama_dosen: "Dr. Bambang Setiawan, ST, M.Eng.Sc.",
      nip: "197106032006041002",
      ruang: "Prodi",
      hari: "Jumat",
      waktu: "8:00-12:25",
      peserta: "15"
    },
    {
      id: 408,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL210",
      nama_mk: "GEOLOGI LINGKUNGAN",
      kelas: "13",
      nama_dosen: "Dina Gunarsih, S.T., M.T.",
      nip: "199306292020102101",
      ruang: "Prodi",
      hari: "Jumat",
      waktu: "8:00-12:25",
      peserta: "14"
    },
    {
      id: 409,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL212",
      nama_mk: "PENGANTAR EKSPLORASI GEOFISIKA",
      kelas: "11",
      nama_dosen: "Akmal Muhni, S.T., M.T.",
      nip: "199301162019031016",
      ruang: "Prodi",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "29"
    },
    {
      id: 410,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL212",
      nama_mk: "PENGANTAR EKSPLORASI GEOFISIKA",
      kelas: "12",
      nama_dosen: "Akmal Muhni, S.T., M.T.",
      nip: "199301162019031016",
      ruang: "Prodi",
      hari: "Rabu",
      waktu: "10:45-12:25",
      peserta: "15"
    },
    {
      id: 411,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL212",
      nama_mk: "PENGANTAR EKSPLORASI GEOFISIKA",
      kelas: "13",
      nama_dosen: "Akmal Muhni, S.T., M.T.",
      nip: "199301162019031016",
      ruang: "Prodi",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "15"
    },
    {
      id: 412,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL214",
      nama_mk: "GEOLOGI SEJARAH",
      kelas: "11",
      nama_dosen: "Rifqan, S.Si., M.T.",
      nip: "199101242019031007",
      ruang: "Prodi",
      hari: "Rabu",
      waktu: "14:00-15:40",
      peserta: "6"
    },
    {
      id: 413,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL214",
      nama_mk: "GEOLOGI SEJARAH",
      kelas: "12",
      nama_dosen: "Muhammad Arief Akbar, B.Sc., M.Sc",
      nip: "199106242020101101",
      ruang: "Prodi",
      hari: "Rabu",
      waktu: "14:00-15:40",
      peserta: "0"
    },
    {
      id: 414,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL214",
      nama_mk: "GEOLOGI SEJARAH",
      kelas: "13",
      nama_dosen: "Rifqan, S.Si., M.T.",
      nip: "199101242019031007",
      ruang: "Prodi",
      hari: "Rabu",
      waktu: "14:00-15:40",
      peserta: "1"
    },
    {
      id: 415,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL216",
      nama_mk: "HIDROGEOLOGI",
      kelas: "11",
      nama_dosen: "Akmal Muhni, S.T., M.T.",
      nip: "199301162019031016",
      ruang: "Prodi",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "29"
    },
    {
      id: 416,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL216",
      nama_mk: "HIDROGEOLOGI",
      kelas: "12",
      nama_dosen: "Dewi Sartika, S.T., M.Eng.",
      nip: "198906072019032018",
      ruang: "Prodi",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "16"
    },
    {
      id: 417,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL216",
      nama_mk: "HIDROGEOLOGI",
      kelas: "13",
      nama_dosen: "Dewi Sartika, S.T., M.Eng.",
      nip: "198906072019032018",
      ruang: "Prodi",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "13"
    },
    {
      id: 418,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL218",
      nama_mk: "METODE EKSPLORASI GEOLOGI",
      kelas: "11",
      nama_dosen: "Dewi Sartika, S.T., M.Eng.",
      nip: "198906072019032018",
      ruang: "Prodi",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "30"
    },
    {
      id: 419,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL218",
      nama_mk: "METODE EKSPLORASI GEOLOGI",
      kelas: "12",
      nama_dosen: "Haqul Baramsyah, S.T., M.Eng.Sc.",
      nip: "198411282015041002",
      ruang: "Prodi",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "30"
    },
    {
      id: 420,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL218",
      nama_mk: "METODE EKSPLORASI GEOLOGI",
      kelas: "13",
      nama_dosen: "Hidayat Syah Putra, S.T., M.Sc.",
      nip: "198803292019031012",
      ruang: "Prodi",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "30"
    },
    {
      id: 421,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL304",
      nama_mk: "GEOLOGI INDONESIA",
      kelas: "11",
      nama_dosen: "Lia Fitria Rahmatillah, S.T., M.T.",
      nip: "199212072020102101",
      ruang: "Prodi",
      hari: "Rabu",
      waktu: "16:35-18:15",
      peserta: "16"
    },
    {
      id: 422,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL304",
      nama_mk: "GEOLOGI INDONESIA",
      kelas: "12",
      nama_dosen: "Hidayat Syah Putra, S.T., M.Sc.",
      nip: "198803292019031012",
      ruang: "Prodi",
      hari: "Rabu",
      waktu: "16:35-18:15",
      peserta: "1"
    },
    {
      id: 423,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL306",
      nama_mk: "KOMPUTASI GEOLOGI",
      kelas: "11",
      nama_dosen: "Dr. Bambang Setiawan, ST, M.Eng.Sc.",
      nip: "197106032006041002",
      ruang: "Prodi",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "31"
    },
    {
      id: 424,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL306",
      nama_mk: "KOMPUTASI GEOLOGI",
      kelas: "12",
      nama_dosen: "Dr. Syamsidik, S.T., M.Sc.",
      nip: "197502251999031001",
      ruang: "Prodi",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "3"
    },
    {
      id: 425,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL308",
      nama_mk: "METODOLOGI PENELITIAN",
      kelas: "11",
      nama_dosen: "Dr. Munira Sungkar, S.T., M.T.",
      nip: "197606062000122001",
      ruang: "Prodi",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "34"
    },
    {
      id: 426,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL308",
      nama_mk: "METODOLOGI PENELITIAN",
      kelas: "12",
      nama_dosen: "Dr. Munira Sungkar, S.T., M.T.",
      nip: "197606062000122001",
      ruang: "Prodi",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "-1"
    },
    {
      id: 427,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL310",
      nama_mk: "SEISMIK STRATIGRAFI",
      kelas: "11",
      nama_dosen: "Iqbal Nurfarid, B.Eng., M.Phil.P.Eng",
      nip: "199201192021021101",
      ruang: "Prodi",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "31"
    },
    {
      id: 428,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL310",
      nama_mk: "SEISMIK STRATIGRAFI",
      kelas: "12",
      nama_dosen: "Iqbal Nurfarid, B.Eng., M.Phil.P.Eng",
      nip: "199201192021021101",
      ruang: "Prodi",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "2"
    },
    {
      id: 429,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL312",
      nama_mk: "GEOSTATISTIK",
      kelas: "11",
      nama_dosen: "Sangga Rima Roman Selia, S.T., M.T.",
      nip: "199006132019031009",
      ruang: "Prodi",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "30"
    },
    {
      id: 430,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL312",
      nama_mk: "GEOSTATISTIK",
      kelas: "12",
      nama_dosen: "Sangga Rima Roman Selia, S.T., M.T.",
      nip: "199006132019031009",
      ruang: "Prodi",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "23"
    },
    {
      id: 431,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL314",
      nama_mk: "KULIAH LAPANGAN II",
      kelas: "11",
      nama_dosen: "Ibnu Rusydy, S.Si., M.Sc.",
      nip: "198307262014041001",
      ruang: "Prodi",
      hari: "Selasa",
      waktu: "16:35-18:15",
      peserta: "39"
    },
    {
      id: 432,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL314",
      nama_mk: "KULIAH LAPANGAN II",
      kelas: "12",
      nama_dosen: "Hidayat Syah Putra, S.T., M.Sc.",
      nip: "198803292019031012",
      ruang: "Prodi",
      hari: "Selasa",
      waktu: "16:35-18:15",
      peserta: "2"
    },
    {
      id: 433,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL316",
      nama_mk: "MEKANIKA BATUAN",
      kelas: "11",
      nama_dosen: "Ibnu Rusydy, S.Si., M.Sc.",
      nip: "198307262014041001",
      ruang: "Prodi",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "30"
    },
    {
      id: 434,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL316",
      nama_mk: "MEKANIKA BATUAN",
      kelas: "12",
      nama_dosen: "Nafisah Al-Huda, S.T., MT.",
      nip: "197901102008122001",
      ruang: "Prodi",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "7"
    },
    {
      id: 435,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL504",
      nama_mk: "GEOMEKANIKA PERMINYAKAN",
      kelas: "11",
      nama_dosen: "Iqbal Nurfarid, B.Eng., M.Phil.P.Eng",
      nip: "199201192021021101",
      ruang: "Prodi",
      hari: "Senin",
      waktu: "16:35-18:15",
      peserta: "4"
    },
    {
      id: 436,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL506",
      nama_mk: "GEOLOGI BATU MULIA",
      kelas: "11",
      nama_dosen: "Lia Fitria Rahmatillah, S.T., M.T.",
      nip: "199212072020102101",
      ruang: "Prodi",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "1"
    },
    {
      id: 437,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL508",
      nama_mk: "GEOLOGI BAHAN KONSTRUKSI",
      kelas: "11",
      nama_dosen: "Nafisah Al-Huda, S.T., MT.",
      nip: "197901102008122001",
      ruang: "Prodi",
      hari: "Jumat",
      waktu: "8:00-9:40",
      peserta: "0"
    },
    {
      id: 438,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL510",
      nama_mk: "SEISMOLOGI",
      kelas: "11",
      nama_dosen: "Ibnu Rusydy, S.Si., M.Sc.",
      nip: "198307262014041001",
      ruang: "Prodi",
      hari: "Jumat",
      waktu: "16:35-18:15",
      peserta: "17"
    },
    {
      id: 439,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL514",
      nama_mk: "GEOLOGI TATA RUANG",
      kelas: "11",
      nama_dosen: "Dr. Halida Yunita, S.T., M.T.",
      nip: "197806132002122002",
      ruang: "Prodi",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "22"
    },
    {
      id: 440,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL528",
      nama_mk: "PEMODELAN RESERVOIR LANJUT",
      kelas: "11",
      nama_dosen: "Iqbal Nurfarid, B.Eng., M.Phil.P.Eng",
      nip: "199201192021021101",
      ruang: "Prodi",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "3"
    },
    {
      id: 441,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGL530",
      nama_mk: "REKAYASA KEGEMPAAN LANJUT",
      kelas: "11",
      nama_dosen: "Dr. Halida Yunita, S.T., M.T.",
      nip: "197806132002122002",
      ruang: "Prodi",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "0"
    },
    {
      id: 442,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGLP01",
      nama_mk: "PROPOSAL TUGAS AKHIR",
      kelas: "11",
      nama_dosen: "Fahri Adrian, B.Sc., M.Sc",
      nip: "199004292016011101",
      ruang: "Prodi",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "60"
    },
    {
      id: 443,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGLP02",
      nama_mk: "GEOLOGI MINYAK DAN GAS BUMI NON KONVENSIONAL",
      kelas: "11",
      nama_dosen: "Hidayat Syah Putra, S.T., M.Sc.",
      nip: "198803292019031012",
      ruang: "Prodi",
      hari: "Rabu",
      waktu: "14:00-15:40",
      peserta: "21"
    },
    {
      id: 444,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGLP12",
      nama_mk: "INVESTIGASI GEOTEKNIK",
      kelas: "11",
      nama_dosen: "Nafisah Al-Huda, S.T., MT.",
      nip: "197901102008122001",
      ruang: "Prodi",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "0"
    },
    {
      id: 445,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGLP16",
      nama_mk: "GEOARKEOLOGI",
      kelas: "11",
      nama_dosen: "Dr. Nazli, S.Si, M.Si",
      nip: "197104212000031002",
      ruang: "Prodi",
      hari: "Selasa",
      waktu: "10:45-12:25",
      peserta: "4"
    },
    {
      id: 446,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGLP18",
      nama_mk: "TOPIK KHUSUS GEOLOGI",
      kelas: "11",
      nama_dosen: "Ibnu Rusydy, S.Si., M.Sc.",
      nip: "198307262014041001",
      ruang: "Prodi",
      hari: "Selasa",
      waktu: "10:45-12:25",
      peserta: "27"
    },
    {
      id: 447,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGLP22",
      nama_mk: "KONSEP TEKNOLOGI",
      kelas: "11",
      nama_dosen: "Dr. Bambang Setiawan, ST, M.Eng.Sc.",
      nip: "197106032006041002",
      ruang: "Prodi",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "3"
    },
    {
      id: 448,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGLP24",
      nama_mk: "MANAJEMEN BENCANA GEOLOGI",
      kelas: "11",
      nama_dosen: "Muhammad Arief Akbar, B.Sc., M.Sc",
      nip: "199106242020101101",
      ruang: "Prodi",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "26"
    },
    {
      id: 449,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410901",
      nama_prodi: "Teknik Geologi",
      kode_mk: "TGLPA2",
      nama_mk: "TUGAS AKHIR",
      kelas: "11",
      nama_dosen: "Fahri Adrian, B.Sc., M.Sc",
      nip: "199004292016011101",
      ruang: "Prodi",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "35"
    },
    {
      id: 450,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS102",
      nama_mk: "KALKULUS  II",
      kelas: "11",
      nama_dosen: "Prof. Dr. Marwan, S.Si., M.Si",
      nip: "197111251999031003",
      ruang: "Jurusan",
      hari: "Senin",
      waktu: "9:50-11:50",
      peserta: "25"
    },
    {
      id: 451,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS102",
      nama_mk: "KALKULUS  II",
      kelas: "12",
      nama_dosen: "Dr. Evi Ramadhani, S.Si.,M.Si",
      nip: "197309281998022001",
      ruang: "Jurusan",
      hari: "Selasa",
      waktu: "9:50-11:50",
      peserta: "26"
    },
    {
      id: 452,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS104",
      nama_mk: "TEKNOLOGI INFORMASI DAN KOMUNIKASI",
      kelas: "11",
      nama_dosen: "Dr. Ir. Rizal Munadi, M.M., MT",
      nip: "196708151993031005",
      ruang: "Jurusan",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "26"
    },
    {
      id: 453,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS104",
      nama_mk: "TEKNOLOGI INFORMASI DAN KOMUNIKASI",
      kelas: "12",
      nama_dosen: "Dr. Ir. Rizal Munadi, M.M., MT",
      nip: "196708151993031005",
      ruang: "Jurusan",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "23"
    },
    {
      id: 454,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS106",
      nama_mk: "FISIKA DASAR II",
      kelas: "11",
      nama_dosen: "Dr. Rini Safitri, S.Si., M.Si.",
      nip: "197004251995122001",
      ruang: "Jurusan",
      hari: "Selasa",
      waktu: "8:00-10:40",
      peserta: "17"
    },
    {
      id: 455,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS106",
      nama_mk: "FISIKA DASAR II",
      kelas: "12",
      nama_dosen: "Gunawati, S.Si., M.Si",
      nip: "197309032000122001",
      ruang: "Jurusan",
      hari: "Selasa",
      waktu: "10:40-12:20",
      peserta: "15"
    },
    {
      id: 456,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS106",
      nama_mk: "FISIKA DASAR II",
      kelas: "13",
      nama_dosen: "Dr. Suhrawardi Ilyas, S.Si, M.Sc",
      nip: "197107061994121001",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-10:40",
      peserta: "17"
    },
    {
      id: 457,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS108",
      nama_mk: "GEOLOGI STRUKTUR",
      kelas: "11",
      nama_dosen: "Dr. Nazli, S.Si, M.Si",
      nip: "197104212000031002",
      ruang: "Jurusan",
      hari: "Senin",
      waktu: "15:50-16:20",
      peserta: "24"
    },
    {
      id: 458,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS108",
      nama_mk: "GEOLOGI STRUKTUR",
      kelas: "12",
      nama_dosen: "Dr. Didik Sugiyanto, M.T.",
      nip: "197312072003121002",
      ruang: "Jurusan",
      hari: "Senin",
      waktu: "16:20-18:10",
      peserta: "26"
    },
    {
      id: 459,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS110",
      nama_mk: "TEKNOLOGI SUMBER DAYA BUMI",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir.. Muhammad Syukri, S.Si, M.T., IPM.,",
      nip: "197005181994121001",
      ruang: "Jurusan",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "26"
    },
    {
      id: 460,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS110",
      nama_mk: "TEKNOLOGI SUMBER DAYA BUMI",
      kelas: "12",
      nama_dosen: "Zakia Masrurah, S.T., M.T.",
      nip: "199311132019032017",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "9:50-10:40",
      peserta: "23"
    },
    {
      id: 461,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS212",
      nama_mk: "METODE GAYA BERAT DAN MAGNETIK",
      kelas: "11",
      nama_dosen: "Muhammad Yanis, S.Si., M.Si",
      nip: "199106052017011101",
      ruang: "Jurusan",
      hari: "Selasa",
      waktu: "16:20-18:10",
      peserta: "18"
    },
    {
      id: 462,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS212",
      nama_mk: "METODE GAYA BERAT DAN MAGNETIK",
      kelas: "12",
      nama_dosen: "Prof. Dr. Ir.. Muhammad Syukri, S.Si, M.T., IPM.,",
      nip: "197005181994121001",
      ruang: "Jurusan",
      hari: "Selasa",
      waktu: "14:00-16:30",
      peserta: "26"
    },
    {
      id: 463,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS213",
      nama_mk: "GEO-MATEMATIKA I",
      kelas: "11",
      nama_dosen: "Dr. Muksin, S.Si, M.Si,M.Phil",
      nip: "197406252000121001",
      ruang: "Jurusan",
      hari: "Jumat",
      waktu: "10:35-11:50",
      peserta: "0"
    },
    {
      id: 464,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS214",
      nama_mk: "GELOMBANG DALAM GEOFISIKA",
      kelas: "11",
      nama_dosen: "Dr. Muksin, S.Si, M.Si,M.Phil",
      nip: "197406252000121001",
      ruang: "Jurusan",
      hari: "Kamis",
      waktu: "8:00-10:40",
      peserta: "26"
    },
    {
      id: 465,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS214",
      nama_mk: "GELOMBANG DALAM GEOFISIKA",
      kelas: "12",
      nama_dosen: "Irwandi, S.Si,M.Si., Ph.D",
      nip: "197503041999031001",
      ruang: "Jurusan",
      hari: "Jumat",
      waktu: "8:00-10:40",
      peserta: "24"
    },
    {
      id: 466,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS220",
      nama_mk: "INSTRUMENTASI GEOFISIKA",
      kelas: "11",
      nama_dosen: "Dr. M. Syukri Surbakti, S.Si., M.Si",
      nip: "197310302000121001",
      ruang: "Jurusan",
      hari: "Sabtu",
      waktu: "16:30-18:10",
      peserta: "27"
    },
    {
      id: 467,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS220",
      nama_mk: "INSTRUMENTASI GEOFISIKA",
      kelas: "12",
      nama_dosen: "Dr. M. Syukri Surbakti, S.Si., M.Si",
      nip: "197310302000121001",
      ruang: "Jurusan",
      hari: "Kamis",
      waktu: "10:35-12:25",
      peserta: "23"
    },
    {
      id: 468,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS222",
      nama_mk: "GEOTERMODINAMIKA",
      kelas: "11",
      nama_dosen: "Dr. Muhammad Isa, S.Si., M.Si",
      nip: "197404202006041002",
      ruang: "R. Seminar Jurusan",
      hari: "Senin",
      waktu: "14:40-16:30",
      peserta: "22"
    },
    {
      id: 469,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS222",
      nama_mk: "GEOTERMODINAMIKA",
      kelas: "12",
      nama_dosen: "Zakia Masrurah, S.T., M.T.",
      nip: "199311132019032017",
      ruang: "jurusan",
      hari: "Kamis",
      waktu: "10:45-12:20",
      peserta: "20"
    },
    {
      id: 470,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS226",
      nama_mk: "GEO-MATEMATIKA II",
      kelas: "11",
      nama_dosen: "Dr. Muksin, S.Si, M.Si,M.Phil",
      nip: "197406252000121001",
      ruang: "Jurusan",
      hari: "Jumat",
      waktu: "10:50-12:20",
      peserta: "26"
    },
    {
      id: 471,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS226",
      nama_mk: "GEO-MATEMATIKA II",
      kelas: "12",
      nama_dosen: "Dr. Suhrawardi Ilyas, S.Si, M.Sc",
      nip: "197107061994121001",
      ruang: "Jurusan",
      hari: "Kamis",
      waktu: "16:20-18:10",
      peserta: "19"
    },
    {
      id: 472,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS228",
      nama_mk: "GEOMATIKA",
      kelas: "11",
      nama_dosen: "Yurda Marvita, S.T., M.T.",
      nip: "199302192019032024",
      ruang: "Jurusan",
      hari: "Jumat",
      waktu: "10:35-11:50",
      peserta: "14"
    },
    {
      id: 473,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS228",
      nama_mk: "GEOMATIKA",
      kelas: "12",
      nama_dosen: "Muzakir, S.Si., M.Si",
      nip: "199009162016011101",
      ruang: "RB4A",
      hari: "Kamis",
      waktu: "16:35-18:15",
      peserta: "33"
    },
    {
      id: 474,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS330",
      nama_mk: "GEOFISIKA KEBENCANAAN",
      kelas: "11",
      nama_dosen: "Zakia Masrurah, S.T., M.T.",
      nip: "199311132019032017",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "29"
    },
    {
      id: 475,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS330",
      nama_mk: "GEOFISIKA KEBENCANAAN",
      kelas: "12",
      nama_dosen: "Yurda Marvita, S.T., M.T.",
      nip: "199302192019032024",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "7"
    },
    {
      id: 476,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS332",
      nama_mk: "METODOLOGI PENELITIAN",
      kelas: "11",
      nama_dosen: "Dr. Muksin, S.Si, M.Si,M.Phil",
      nip: "197406252000121001",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "31"
    },
    {
      id: 477,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS332",
      nama_mk: "METODOLOGI PENELITIAN",
      kelas: "12",
      nama_dosen: "Prof. Dr. Ir.. Muhammad Syukri, S.Si, M.T., IPM.,",
      nip: "197005181994121001",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "24"
    },
    {
      id: 478,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS338",
      nama_mk: "KERJA PRAKTEK",
      kelas: "11",
      nama_dosen: "Zakia Masrurah, S.T., M.T.",
      nip: "199311132019032017",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "37"
    },
    {
      id: 479,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS542",
      nama_mk: "SEISMOLOGI LANJUT",
      kelas: "11",
      nama_dosen: "Dr. Muksin, S.Si, M.Si,M.Phil",
      nip: "197406252000121001",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "16"
    },
    {
      id: 480,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS544",
      nama_mk: "METODE NUMERIK",
      kelas: "11",
      nama_dosen: "Irwandi, S.Si,M.Si., Ph.D",
      nip: "197503041999031001",
      ruang: "Jurusan",
      hari: "Selasa",
      waktu: "10:45-12:40",
      peserta: "0"
    },
    {
      id: 481,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS548",
      nama_mk: "PERMODELAN & SIMULASI GEOFISIKA",
      kelas: "11",
      nama_dosen: "Dr. Muksin, S.Si, M.Si,M.Phil",
      nip: "197406252000121001",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "0"
    },
    {
      id: 482,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS548",
      nama_mk: "PERMODELAN & SIMULASI GEOFISIKA",
      kelas: "12",
      nama_dosen: "Dr. Nazli, S.Si, M.Si",
      nip: "197104212000031002",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "13"
    },
    {
      id: 483,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS552",
      nama_mk: "HIDROGEOLOGI",
      kelas: "11",
      nama_dosen: "Amsir, S.Si., M.Sc.",
      nip: "198806162019011101",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "19"
    },
    {
      id: 484,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS556",
      nama_mk: "GEOFISIKA EKSPLORASI",
      kelas: "11",
      nama_dosen: "Dr. Ir. Marwan, S.Si., M.T., IPM., ASEAN Eng",
      nip: "197112311998021003",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "18"
    },
    {
      id: 485,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS558",
      nama_mk: "GEOFISIKA LUBANG BOR",
      kelas: "11",
      nama_dosen: "Sangga Rima Roman Selia, S.T., M.T.",
      nip: "199006132019031009",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "37"
    },
    {
      id: 486,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS560",
      nama_mk: "ANALISA DATA WELL LOG",
      kelas: "11",
      nama_dosen: "Sangga Rima Roman Selia, S.T., M.T.",
      nip: "199006132019031009",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "35"
    },
    {
      id: 487,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS564",
      nama_mk: "GEOKRONOLOGI",
      kelas: "11",
      nama_dosen: "Dr. Nazli, S.Si, M.Si",
      nip: "197104212000031002",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "8"
    },
    {
      id: 488,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS565",
      nama_mk: "SEISMIK STRATIGRAFI",
      kelas: "11",
      nama_dosen: "Zul Fadhli, S.Si., M.Sc.",
      nip: "198604192019031007",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "32"
    },
    {
      id: 489,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS566",
      nama_mk: "PALEOMAGNETIK",
      kelas: "11",
      nama_dosen: "Dr. Nazli, S.Si, M.Si",
      nip: "197104212000031002",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "5"
    },
    {
      id: 490,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS568",
      nama_mk: "GEOFISIKA LAUT",
      kelas: "11",
      nama_dosen: "Dr. Ir. Marwan, S.Si., M.T., IPM., ASEAN Eng",
      nip: "197112311998021003",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "1"
    },
    {
      id: 491,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS574",
      nama_mk: "KRISTALOGI & MINERALOGI",
      kelas: "11",
      nama_dosen: "Dr. Nazli, S.Si, M.Si",
      nip: "197104212000031002",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "14"
    },
    {
      id: 492,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGS588",
      nama_mk: "GEOFISIKA REGULASI & HUKUM KONTRAK",
      kelas: "11",
      nama_dosen: "Dr. Ir. Marwan, S.Si., M.T., IPM., ASEAN Eng",
      nip: "197112311998021003",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "24"
    },
    {
      id: 493,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGU202",
      nama_mk: "KOMPUTASI GEOFISIKA",
      kelas: "11",
      nama_dosen: "Dr. Nazli, S.Si, M.Si",
      nip: "197104212000031002",
      ruang: "Jurusan",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "29"
    },
    {
      id: 494,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGU202",
      nama_mk: "KOMPUTASI GEOFISIKA",
      kelas: "12",
      nama_dosen: "Dr. Muksin, S.Si, M.Si,M.Phil",
      nip: "197406252000121001",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "9:50-11:20",
      peserta: "37"
    },
    {
      id: 495,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGU202",
      nama_mk: "KOMPUTASI GEOFISIKA",
      kelas: "13",
      nama_dosen: "Irwandi, S.Si,M.Si., Ph.D",
      nip: "197503041999031001",
      ruang: "Jurusan",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "34"
    },
    {
      id: 496,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGU302",
      nama_mk: "METODE SEISMIK II",
      kelas: "11",
      nama_dosen: "Dr. Muksin, S.Si, M.Si,M.Phil",
      nip: "197406252000121001",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "26"
    },
    {
      id: 497,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGU302",
      nama_mk: "METODE SEISMIK II",
      kelas: "12",
      nama_dosen: "Dr. Muksin, S.Si, M.Si,M.Phil",
      nip: "197406252000121001",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "27"
    },
    {
      id: 498,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGU302",
      nama_mk: "METODE SEISMIK II",
      kelas: "13",
      nama_dosen: "Dr. Muhammad Isa, S.Si., M.Si",
      nip: "197404202006041002",
      ruang: "R. Jurusan",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "45"
    },
    {
      id: 499,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGU304",
      nama_mk: "WORKSHOP GEOFISIKA",
      kelas: "11",
      nama_dosen: "Dr. Ir. Marwan, S.Si., M.T., IPM., ASEAN Eng",
      nip: "197112311998021003",
      ruang: "R. Seminar Jurusan",
      hari: "Senin",
      waktu: "10:50-12:25",
      peserta: "55"
    },
    {
      id: 500,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGU401",
      nama_mk: "TUGAS AKHIR I",
      kelas: "11",
      nama_dosen: "Zul Fadhli, S.Si., M.Sc.",
      nip: "198604192019031007",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "46"
    },
    {
      id: 501,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGU504",
      nama_mk: "GEOTEKNIK DAN LINGKUNGAN",
      kelas: "11",
      nama_dosen: "Zul Fadhli, S.Si., M.Sc.",
      nip: "198604192019031007",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "0"
    },
    {
      id: 502,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGU506",
      nama_mk: "GEOLOGI MINYAK DAN GAS BUMI",
      kelas: "11",
      nama_dosen: "Yurda Marvita, S.T., M.T.",
      nip: "199302192019032024",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "16:20-18:00",
      peserta: "0"
    },
    {
      id: 503,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGU506",
      nama_mk: "GEOLOGI MINYAK DAN GAS BUMI",
      kelas: "12",
      nama_dosen: "Muchlis, S.Si., M.Sc",
      nip: "197912182009121001",
      ruang: "Jurusan",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "0"
    },
    {
      id: 504,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGU508",
      nama_mk: "BAHASA INGGRIS ILMIAH",
      kelas: "11",
      nama_dosen: "Dr. Faisal, S.Si, M.Sc",
      nip: "197012201998021001",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "0"
    },
    {
      id: 505,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGU510",
      nama_mk: "PENGINDERAAN JAUH",
      kelas: "11",
      nama_dosen: "Dr. Saumi Syahreza, S.Si., M.Si",
      nip: "197609172005011002",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "14"
    },
    {
      id: 506,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGU510",
      nama_mk: "PENGINDERAAN JAUH",
      kelas: "12",
      nama_dosen: "Dr. Faisal, S.Si, M.Sc",
      nip: "197012201998021001",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "4"
    },
    {
      id: 507,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGUP02",
      nama_mk: "TUGAS AKHIR II",
      kelas: "11",
      nama_dosen: "Zul Fadhli, S.Si., M.Sc.",
      nip: "198604192019031007",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "66"
    },
    {
      id: 508,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGUP04",
      nama_mk: "MAGANG KERJA B",
      kelas: "11",
      nama_dosen: "Dr. Muhammad Isa, S.Si., M.Si",
      nip: "197404202006041002",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "0"
    },
    {
      id: 509,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGUP05",
      nama_mk: "MAGANG KERJA C",
      kelas: "11",
      nama_dosen: "Yurda Marvita, S.T., M.T.",
      nip: "199302192019032024",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "0"
    },
    {
      id: 510,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGUP07",
      nama_mk: "MENGAJAR DI SEKOLAH B",
      kelas: "11",
      nama_dosen: "Zul Fadhli, S.Si., M.Sc.",
      nip: "198604192019031007",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "0"
    },
    {
      id: 511,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGUP08",
      nama_mk: "MENGAJAR DI SEKOLAH C",
      kelas: "11",
      nama_dosen: "Muhammad Yanis, S.Si., M.Si",
      nip: "199106052017011101",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "0"
    },
    {
      id: 512,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGUP10",
      nama_mk: "PROYEK PEMBANGUNAN DESA B",
      kelas: "11",
      nama_dosen: "Dr. Muksin, S.Si, M.Si,M.Phil",
      nip: "197406252000121001",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "0"
    },
    {
      id: 513,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGUP11",
      nama_mk: "PROYEK PEMBANGUNAN DESA C",
      kelas: "11",
      nama_dosen: "Freddy Sapta Wirandha, S.T., M.Eng",
      nip: "199308072020011101",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "5"
    },
    {
      id: 514,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGUP12",
      nama_mk: "RISET DAN PUBLIKASI ILMIAH",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir.. Muhammad Syukri, S.Si, M.T., IPM.,",
      nip: "197005181994121001",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "0"
    },
    {
      id: 515,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGUP13",
      nama_mk: "RISET DI LUAR PROGRAM STUDI",
      kelas: "11",
      nama_dosen: "Yurda Marvita, S.T., M.T.",
      nip: "199302192019032024",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "3"
    },
    {
      id: 516,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGUP18",
      nama_mk: "WIRAUSAHA B",
      kelas: "11",
      nama_dosen: "Muzakir, S.Si., M.Si",
      nip: "199009162016011101",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "0"
    },
    {
      id: 517,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGUP19",
      nama_mk: "WIRAUSAHA C",
      kelas: "11",
      nama_dosen: "Amsir, S.Si., M.Sc.",
      nip: "198806162019011101",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "0"
    },
    {
      id: 518,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410701",
      nama_prodi: "Teknik Geofisika",
      kode_mk: "TGUP21",
      nama_mk: "PERTUKARAN MAHASISWA MERDEKA",
      kelas: "11",
      nama_dosen: "Zakia Masrurah, S.T., M.T.",
      nip: "199311132019032017",
      ruang: "Jurusan",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "0"
    },
    {
      id: 519,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK102",
      nama_mk: "KALKULUS II",
      kelas: "11",
      nama_dosen: "Muhammad Irhamsyah, ST., MT",
      nip: "197207182001121001",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "14:00-16:30",
      peserta: "10"
    },
    {
      id: 520,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK102",
      nama_mk: "KALKULUS II",
      kelas: "12",
      nama_dosen: "Ir.. Hubbul Walidainy, S.T., M.T., IPM",
      nip: "197308262000121001",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "14:00-16:30",
      peserta: "19"
    },
    {
      id: 521,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK102",
      nama_mk: "KALKULUS II",
      kelas: "13",
      nama_dosen: "Dr. Yunida, S.T.",
      nip: "199106152021022101",
      ruang: "RKTE 1",
      hari: "Kamis",
      waktu: "10:45-13:15",
      peserta: "30"
    },
    {
      id: 522,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK102",
      nama_mk: "KALKULUS II",
      kelas: "14",
      nama_dosen: "Dr. Khairun  Saddami, S.T",
      nip: "199103182020071101",
      ruang: "RKTE 1",
      hari: "Kamis",
      waktu: "8:00-10:30",
      peserta: "12"
    },
    {
      id: 523,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK102",
      nama_mk: "KALKULUS II",
      kelas: "15",
      nama_dosen: "Dr. Maulisa Oktiana, S.T.",
      nip: "199010252020072101",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "16:30-19:00",
      peserta: "29"
    },
    {
      id: 524,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK104",
      nama_mk: "FISIKA II + LAB",
      kelas: "11",
      nama_dosen: "Yunidar, S.Si.,MT",
      nip: "197406292000032001",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-10:40",
      peserta: "7"
    },
    {
      id: 525,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK104",
      nama_mk: "FISIKA II + LAB",
      kelas: "12",
      nama_dosen: "Al Bahri, S.ST., M.T.",
      nip: "199109102019031014",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-10:40",
      peserta: "28"
    },
    {
      id: 526,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK104",
      nama_mk: "FISIKA II + LAB",
      kelas: "13",
      nama_dosen: "Dr. Maulisa Oktiana, S.T.",
      nip: "199010252020072101",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-10:40",
      peserta: "30"
    },
    {
      id: 527,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK104",
      nama_mk: "FISIKA II + LAB",
      kelas: "14",
      nama_dosen: "Fathurrahman, S.T., M.Eng.Sc",
      nip: "198701052019031009",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-10:40",
      peserta: "24"
    },
    {
      id: 528,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK106",
      nama_mk: "MATEMATIKA DISKRIT",
      kelas: "11",
      nama_dosen: "Masduki Khamdan Muchamad, S.Kom., M.Sc.",
      nip: "199404082019031009",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "8:00-10:40",
      peserta: "35"
    },
    {
      id: 529,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK106",
      nama_mk: "MATEMATIKA DISKRIT",
      kelas: "12",
      nama_dosen: "Maya Fitria, S.Kom., M.Sc.",
      nip: "199005012019032020",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "8:00-10:40",
      peserta: "31"
    },
    {
      id: 530,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK106",
      nama_mk: "MATEMATIKA DISKRIT",
      kelas: "13",
      nama_dosen: "Dr. Melinda, ST., M.Sc",
      nip: "197906102002122001",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "8:00-10:40",
      peserta: "30"
    },
    {
      id: 531,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK106",
      nama_mk: "MATEMATIKA DISKRIT",
      kelas: "14",
      nama_dosen: "Maya Fitria, S.Kom., M.Sc.",
      nip: "199005012019032020",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "8:00-10:40",
      peserta: "30"
    },
    {
      id: 532,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK106",
      nama_mk: "MATEMATIKA DISKRIT",
      kelas: "15",
      nama_dosen: "Dr. Yunida, S.T.",
      nip: "199106152021022101",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "8:00-10:40",
      peserta: "30"
    },
    {
      id: 533,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK106",
      nama_mk: "MATEMATIKA DISKRIT",
      kelas: "16",
      nama_dosen: "Dr. Yunida, S.T.",
      nip: "199106152021022101",
      ruang: "RKTE",
      hari: "Senin",
      waktu: "10:05-12:05",
      peserta: "18"
    },
    {
      id: 534,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK108",
      nama_mk: "ALJABAR LINEAR DAN VARIABEL KOMPLEKS",
      kelas: "11",
      nama_dosen: "Dr. Ramzi Adriman, S.T, M. Sc",
      nip: "197901302005011001",
      ruang: "RKTE 1",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "30"
    },
    {
      id: 535,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK108",
      nama_mk: "ALJABAR LINEAR DAN VARIABEL KOMPLEKS",
      kelas: "12",
      nama_dosen: "Dr. Kahlil, S.T., M.Eng.",
      nip: "198512022019031006",
      ruang: "RKTE 1",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "14"
    },
    {
      id: 536,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK108",
      nama_mk: "ALJABAR LINEAR DAN VARIABEL KOMPLEKS",
      kelas: "13",
      nama_dosen: "Maya Fitria, S.Kom., M.Sc.",
      nip: "199005012019032020",
      ruang: "RKTE 1",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "19"
    },
    {
      id: 537,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK108",
      nama_mk: "ALJABAR LINEAR DAN VARIABEL KOMPLEKS",
      kelas: "14",
      nama_dosen: "Dr. Maulisa Oktiana, S.T.",
      nip: "199010252020072101",
      ruang: "RKTE 1",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "24"
    },
    {
      id: 538,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK108",
      nama_mk: "ALJABAR LINEAR DAN VARIABEL KOMPLEKS",
      kelas: "15",
      nama_dosen: "Dr. Khairun  Saddami, S.T",
      nip: "199103182020071101",
      ruang: "RKTE 1",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "7"
    },
    {
      id: 539,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK110",
      nama_mk: "PENGANTAR DESAIN DIGITAL",
      kelas: "11",
      nama_dosen: "Zulfikar, S.T., M.Sc",
      nip: "197507202006041003",
      ruang: "RKTE 1",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "38"
    },
    {
      id: 540,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK110",
      nama_mk: "PENGANTAR DESAIN DIGITAL",
      kelas: "12",
      nama_dosen: "Zulfikar, S.T., M.Sc",
      nip: "197507202006041003",
      ruang: "RKTE 1",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "40"
    },
    {
      id: 541,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK110",
      nama_mk: "PENGANTAR DESAIN DIGITAL",
      kelas: "13",
      nama_dosen: "Fardian, S.T., M.Sc.",
      nip: "197901022003121004",
      ruang: "RKTE 1",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "40"
    },
    {
      id: 542,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK110",
      nama_mk: "PENGANTAR DESAIN DIGITAL",
      kelas: "14",
      nama_dosen: "Fardian, S.T., M.Sc.",
      nip: "197901022003121004",
      ruang: "RKTE 1",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "40"
    },
    {
      id: 543,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK110",
      nama_mk: "PENGANTAR DESAIN DIGITAL",
      kelas: "5",
      nama_dosen: "Fardian, S.T., M.Sc.",
      nip: "197901022003121004",
      ruang: "RKTE",
      hari: "Senin",
      waktu: "10:05-12:05",
      peserta: "11"
    },
    {
      id: 544,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK112",
      nama_mk: "PEMROGRAMAN II + LAB",
      kelas: "11",
      nama_dosen: "Fardian, S.T., M.Sc.",
      nip: "197901022003121004",
      ruang: "Lab Komputer",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "30"
    },
    {
      id: 545,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK112",
      nama_mk: "PEMROGRAMAN II + LAB",
      kelas: "12",
      nama_dosen: "Masduki Khamdan Muchamad, S.Kom., M.Sc.",
      nip: "199404082019031009",
      ruang: "Lab Komputer",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "22"
    },
    {
      id: 546,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK112",
      nama_mk: "PEMROGRAMAN II + LAB",
      kelas: "13",
      nama_dosen: "Ardiansyah, BSEE.,M.Sc",
      nip: "197212261992011001",
      ruang: "Lab Komputer",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "30"
    },
    {
      id: 547,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK112",
      nama_mk: "PEMROGRAMAN II + LAB",
      kelas: "14",
      nama_dosen: "Ir. Rahmad Dawood, S.Kom, M.Sc., IPM., ASEAN.Eng",
      nip: "197203181995121001",
      ruang: "Lab Komputer",
      hari: "Sabtu",
      waktu: "10:45-12:25",
      peserta: "20"
    },
    {
      id: 548,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK202",
      nama_mk: "ARTIFICIAL INTELLIGENCE",
      kelas: "11",
      nama_dosen: "Yudha Nurdin, ST.,MT",
      nip: "197910012010121002",
      ruang: "RKTE 1",
      hari: "Selasa",
      waktu: "8:00-10:40",
      peserta: "40"
    },
    {
      id: 549,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK202",
      nama_mk: "ARTIFICIAL INTELLIGENCE",
      kelas: "12",
      nama_dosen: "Sayed Muchallil, S.T., M. Sc",
      nip: "198006162005011002",
      ruang: "RKTE 1",
      hari: "Selasa",
      waktu: "8:00-10:40",
      peserta: "37"
    },
    {
      id: 550,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK202",
      nama_mk: "ARTIFICIAL INTELLIGENCE",
      kelas: "14",
      nama_dosen: "Masduki Khamdan Muchamad, S.Kom., M.Sc.",
      nip: "199404082019031009",
      ruang: "RKTE 1",
      hari: "Selasa",
      waktu: "8:00-10:40",
      peserta: "39"
    },
    {
      id: 551,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK202",
      nama_mk: "ARTIFICIAL INTELLIGENCE",
      kelas: "15",
      nama_dosen: "Dr. Khairun  Saddami, S.T",
      nip: "199103182020071101",
      ruang: "RKTE",
      hari: "Kamis",
      waktu: "14:00-16:40",
      peserta: "30"
    },
    {
      id: 552,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK204",
      nama_mk: "REKAYASA PERANGKAT LUNAK + LAB",
      kelas: "11",
      nama_dosen: "Sayed Muchallil, S.T., M. Sc",
      nip: "198006162005011002",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "31"
    },
    {
      id: 553,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK204",
      nama_mk: "REKAYASA PERANGKAT LUNAK + LAB",
      kelas: "12",
      nama_dosen: "Maya Fitria, S.Kom., M.Sc.",
      nip: "199005012019032020",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "28"
    },
    {
      id: 554,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK204",
      nama_mk: "REKAYASA PERANGKAT LUNAK + LAB",
      kelas: "13",
      nama_dosen: "Yudha Nurdin, ST.,MT",
      nip: "197910012010121002",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "36"
    },
    {
      id: 555,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK204",
      nama_mk: "REKAYASA PERANGKAT LUNAK + LAB",
      kelas: "14",
      nama_dosen: "Dr. Khairun  Saddami, S.T",
      nip: "199103182020071101",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "26"
    },
    {
      id: 556,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK204",
      nama_mk: "REKAYASA PERANGKAT LUNAK + LAB",
      kelas: "15",
      nama_dosen: "Dr. Khairun  Saddami, S.T",
      nip: "199103182020071101",
      ruang: "RKTE",
      hari: "Rabu",
      waktu: "14:00-16:40",
      peserta: "25"
    },
    {
      id: 557,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK206",
      nama_mk: "DESAIN DATABASE",
      kelas: "11",
      nama_dosen: "Ardiansyah, BSEE.,M.Sc",
      nip: "197212261992011001",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "16:00-18:00",
      peserta: "7"
    },
    {
      id: 558,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK206",
      nama_mk: "DESAIN DATABASE",
      kelas: "12",
      nama_dosen: "Fardian, S.T., M.Sc.",
      nip: "197901022003121004",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "16:00-18:00",
      peserta: "5"
    },
    {
      id: 559,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK206",
      nama_mk: "DESAIN DATABASE",
      kelas: "13",
      nama_dosen: "Yudha Nurdin, ST.,MT",
      nip: "197910012010121002",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "16:00-18:00",
      peserta: "6"
    },
    {
      id: 560,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK206",
      nama_mk: "DESAIN DATABASE",
      kelas: "14",
      nama_dosen: "Sayed Muchallil, S.T., M. Sc",
      nip: "198006162005011002",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "16:00-18:00",
      peserta: "0"
    },
    {
      id: 561,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK208",
      nama_mk: "SISTEM OPERASI",
      kelas: "11",
      nama_dosen: "Dr. Ramzi Adriman, S.T, M. Sc",
      nip: "197901302005011001",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "10:45-13:15",
      peserta: "30"
    },
    {
      id: 562,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK208",
      nama_mk: "SISTEM OPERASI",
      kelas: "12",
      nama_dosen: "Afdhal, S.T., M.Sc.",
      nip: "197907062005011001",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "10:45-13:15",
      peserta: "30"
    },
    {
      id: 563,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK208",
      nama_mk: "SISTEM OPERASI",
      kelas: "13",
      nama_dosen: "Akhyar, S.T., M.Eng",
      nip: "198709082019031007",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "10:45-13:15",
      peserta: "8"
    },
    {
      id: 564,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK208",
      nama_mk: "SISTEM OPERASI",
      kelas: "14",
      nama_dosen: "Dr. Ramzi Adriman, S.T, M. Sc",
      nip: "197901302005011001",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "10:45-13:15",
      peserta: "19"
    },
    {
      id: 565,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK210",
      nama_mk: "JARINGAN KOMPUTER + LAB",
      kelas: "11",
      nama_dosen: "Prof. Dr. Teuku Yuliar Arif, S.T, M.Kom",
      nip: "197307031999031003",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "15"
    },
    {
      id: 566,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK210",
      nama_mk: "JARINGAN KOMPUTER + LAB",
      kelas: "12",
      nama_dosen: "Afdhal, S.T., M.Sc.",
      nip: "197907062005011001",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "30"
    },
    {
      id: 567,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK210",
      nama_mk: "JARINGAN KOMPUTER + LAB",
      kelas: "13",
      nama_dosen: "Dr. Ir. Rizal Munadi, M.M., MT",
      nip: "196708151993031005",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "28"
    },
    {
      id: 568,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK210",
      nama_mk: "JARINGAN KOMPUTER + LAB",
      kelas: "14",
      nama_dosen: "Dr. Ir. Syahrial, M.Eng",
      nip: "196401121992031003",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "13"
    },
    {
      id: 569,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK212",
      nama_mk: "RANGKAIAN ELEKTRONIKA + LAB",
      kelas: "11",
      nama_dosen: "Dr. Tarmizi, ST., M.Sc",
      nip: "197202011999031002",
      ruang: "RKTE 1",
      hari: "Jumat",
      waktu: "16:00-18:00",
      peserta: "22"
    },
    {
      id: 570,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK212",
      nama_mk: "RANGKAIAN ELEKTRONIKA + LAB",
      kelas: "12",
      nama_dosen: "Al Bahri, S.ST., M.T.",
      nip: "199109102019031014",
      ruang: "RKTE 1",
      hari: "Jumat",
      waktu: "16:00-18:00",
      peserta: "30"
    },
    {
      id: 571,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK212",
      nama_mk: "RANGKAIAN ELEKTRONIKA + LAB",
      kelas: "13",
      nama_dosen: "Yunidar, S.Si.,MT",
      nip: "197406292000032001",
      ruang: "RKTE 1",
      hari: "Jumat",
      waktu: "16:00-18:00",
      peserta: "14"
    },
    {
      id: 572,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK212",
      nama_mk: "RANGKAIAN ELEKTRONIKA + LAB",
      kelas: "14",
      nama_dosen: "Zulfikar, S.T., M.Sc",
      nip: "197507202006041003",
      ruang: "RKTE 1",
      hari: "Jumat",
      waktu: "16:00-18:00",
      peserta: "22"
    },
    {
      id: 573,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK302",
      nama_mk: "METODOLOGI PENELITIAN",
      kelas: "11",
      nama_dosen: "Dr. Melinda, ST., M.Sc",
      nip: "197906102002122001",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "26"
    },
    {
      id: 574,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK302",
      nama_mk: "METODOLOGI PENELITIAN",
      kelas: "12",
      nama_dosen: "Prof. Dr. Nasaruddin, S.T.,M.Eng.",
      nip: "197404021999031003",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "9:45-12:25",
      peserta: "30"
    },
    {
      id: 575,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK304",
      nama_mk: "ETIKA DAN PROFESIONALISME",
      kelas: "11",
      nama_dosen: "Ahmadiar, S.T., M.Sc.",
      nip: "198006252008121001",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-10:40",
      peserta: "19"
    },
    {
      id: 576,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK502",
      nama_mk: "KAPITA SELEKTA: SISTEM EMBEDDED I",
      kelas: "11",
      nama_dosen: "Ir. Rahmad Dawood, S.Kom, M.Sc., IPM., ASEAN.Eng",
      nip: "197203181995121001",
      ruang: "Lab Komputer",
      hari: "Sabtu",
      waktu: "10:40-13:10",
      peserta: "-2"
    },
    {
      id: 577,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK504",
      nama_mk: "PERANCANGAN INTERNET OF THINGS + LAB",
      kelas: "11",
      nama_dosen: "Ir. Rahmad Dawood, S.Kom, M.Sc., IPM., ASEAN.Eng",
      nip: "197203181995121001",
      ruang: "Lab Komputer",
      hari: "Sabtu",
      waktu: "14:00-15:40",
      peserta: "17"
    },
    {
      id: 578,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK506",
      nama_mk: "SENSOR DAN TRANSDmk",
      kelas: "11",
      nama_dosen: "Dr. Saumi Syahreza, S.Si., M.Si",
      nip: "197609172005011002",
      ruang: "RKTE 1",
      hari: "Selasa",
      waktu: "8:00-10:40",
      peserta: "0"
    },
    {
      id: 579,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK508",
      nama_mk: "MIKROPROSESOR DAN TEKNIK ANTARMUKA",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Yuwaldi Away, M.Sc",
      nip: "196412061990021001",
      ruang: "RKTE 1",
      hari: "Selasa",
      waktu: "10:40-13:15",
      peserta: "3"
    },
    {
      id: 580,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK510",
      nama_mk: "KAPITA SELEKTA: JARINGAN KOMPUTER DAN HPC I",
      kelas: "11",
      nama_dosen: "Prof. Dr. Teuku Yuliar Arif, S.T, M.Kom",
      nip: "197307031999031003",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "8:00-10:40",
      peserta: "3"
    },
    {
      id: 581,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK512",
      nama_mk: "PERANCANGAN INFRASTRUKTUR JARINGAN + LAB",
      kelas: "11",
      nama_dosen: "Afdhal, S.T., M.Sc.",
      nip: "197907062005011001",
      ruang: "RKTE 1",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "34"
    },
    {
      id: 582,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK514",
      nama_mk: "KOMPUTASI BERKINERJA TINGGI + LAB",
      kelas: "11",
      nama_dosen: "Masduki Khamdan Muchamad, S.Kom., M.Sc.",
      nip: "199404082019031009",
      ruang: "RKTE 1",
      hari: "Jumat",
      waktu: "8:00-9:40",
      peserta: "25"
    },
    {
      id: 583,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK514",
      nama_mk: "KOMPUTASI BERKINERJA TINGGI + LAB",
      kelas: "12",
      nama_dosen: "Masduki Khamdan Muchamad, S.Kom., M.Sc.",
      nip: "199404082019031009",
      ruang: "A25-202",
      hari: "Sabtu",
      waktu: "10:45-13:45",
      peserta: "19"
    },
    {
      id: 584,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK516",
      nama_mk: "PEMROGRAMAN DAN SIMULASI JARINGAN",
      kelas: "11",
      nama_dosen: "Prof. Dr. Teuku Yuliar Arif, S.T, M.Kom",
      nip: "197307031999031003",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-10:40",
      peserta: "29"
    },
    {
      id: 585,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK518",
      nama_mk: "KAPITA SELEKTA: TEKNOLOGI MULTIMEDIA I",
      kelas: "11",
      nama_dosen: "Roslidar, ST., M.Sc",
      nip: "197807192002122002",
      ruang: "RKTE 1",
      hari: "Kamis",
      waktu: "10:45-13:15",
      peserta: "-7"
    },
    {
      id: 586,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK520",
      nama_mk: "KOMPUTASI MULTIMEDIA + LAB",
      kelas: "11",
      nama_dosen: "Prof. Dr. Fitri Arnia, S.T., M.Eng.Sc",
      nip: "197311121999032001",
      ruang: "RKTE 1",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "26"
    },
    {
      id: 587,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK522",
      nama_mk: "VISI KOMPUTER",
      kelas: "11",
      nama_dosen: "Dr. Kahlil, S.T., M.Eng.",
      nip: "198512022019031006",
      ruang: "RKTE 1",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "30"
    },
    {
      id: 588,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK524",
      nama_mk: "GRAFIKA KOMPUTER",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Yuwaldi Away, M.Sc",
      nip: "196412061990021001",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "32"
    },
    {
      id: 589,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK526",
      nama_mk: "DEEP LEARNING",
      kelas: "11",
      nama_dosen: "Dr. Khairun  Saddami, S.T",
      nip: "199103182020071101",
      ruang: "RKTE 1",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "33"
    },
    {
      id: 590,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK526",
      nama_mk: "DEEP LEARNING",
      kelas: "12",
      nama_dosen: "Roslidar, ST., M.Sc",
      nip: "197807192002122002",
      ruang: "prodi te",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "14"
    },
    {
      id: 591,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK528",
      nama_mk: "QUANTUM COMPUTING",
      kelas: "11",
      nama_dosen: "Ardiansyah, BSEE.,M.Sc",
      nip: "197212261992011001",
      ruang: "RKTE 1",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "16"
    },
    {
      id: 592,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK530",
      nama_mk: "E-SPORT",
      kelas: "11",
      nama_dosen: "Fardian, S.T., M.Sc.",
      nip: "197901022003121004",
      ruang: "RKTE 1",
      hari: "Kamis",
      waktu: "16:30-18:00",
      peserta: "40"
    },
    {
      id: 593,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIK532",
      nama_mk: "EXPERT SYSTEM",
      kelas: "11",
      nama_dosen: "Sayed Muchallil, S.T., M. Sc",
      nip: "198006162005011002",
      ruang: "RKTE 1",
      hari: "Jumat",
      waktu: "16:30-18:00",
      peserta: "11"
    },
    {
      id: 594,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIKP01",
      nama_mk: "HARDWARE-SOFTWARE CO-DESIGN + LAB",
      kelas: "11",
      nama_dosen: "Ir. Rahmad Dawood, S.Kom, M.Sc., IPM., ASEAN.Eng",
      nip: "197203181995121001",
      ruang: "Lab Komputer",
      hari: "Sabtu",
      waktu: "16:30-17:20",
      peserta: "31"
    },
    {
      id: 595,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIKP01",
      nama_mk: "HARDWARE-SOFTWARE CO-DESIGN + LAB",
      kelas: "12",
      nama_dosen: "Masduki Khamdan Muchamad, S.Kom., M.Sc.",
      nip: "199404082019031009",
      ruang: "RKTE",
      hari: "Senin",
      waktu: "14:00-16:40",
      peserta: "32"
    },
    {
      id: 596,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIKP01",
      nama_mk: "HARDWARE-SOFTWARE CO-DESIGN + LAB",
      kelas: "13",
      nama_dosen: "Masduki Khamdan Muchamad, S.Kom., M.Sc.",
      nip: "199404082019031009",
      ruang: "RKTE",
      hari: "Selasa",
      waktu: "14:00-16:40",
      peserta: "0"
    },
    {
      id: 597,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIKP02",
      nama_mk: "KERJA PRAKTIK",
      kelas: "11",
      nama_dosen: "Dr. Kahlil, S.T., M.Eng.",
      nip: "198512022019031006",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-10:40",
      peserta: "23"
    },
    {
      id: 598,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIKPA1",
      nama_mk: "TUGAS AKHIR I",
      kelas: "11",
      nama_dosen: "Dr. Kahlil, S.T., M.Eng.",
      nip: "198512022019031006",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "64"
    },
    {
      id: 599,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411101",
      nama_prodi: "Teknik Komputer",
      kode_mk: "TIKPA2",
      nama_mk: "TUGAS AKHIR II",
      kelas: "11",
      nama_dosen: "Dr. Kahlil, S.T., M.Eng.",
      nip: "198512022019031006",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "52"
    },
    {
      id: 600,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU102",
      nama_mk: "KALKULUS II",
      kelas: "11",
      nama_dosen: "Ikhsan Maulidi, S.Si., M.Si",
      nip: "199210022018031001",
      ruang: "PRODI TI",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "26"
    },
    {
      id: 601,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU102",
      nama_mk: "KALKULUS II",
      kelas: "12",
      nama_dosen: "Ikhsan Maulidi, S.Si., M.Si",
      nip: "199210022018031001",
      ruang: "PRODI TI",
      hari: "Rabu",
      waktu: "14:00-12:25",
      peserta: "24"
    },
    {
      id: 602,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU102",
      nama_mk: "KALKULUS II",
      kelas: "13",
      nama_dosen: "Ikhsan Maulidi, S.Si., M.Si",
      nip: "199210022018031001",
      ruang: "PRODI TI",
      hari: "Rabu",
      waktu: "16:35-18:15",
      peserta: "32"
    },
    {
      id: 603,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU104",
      nama_mk: "FISIKA DASAR II + PRAKTIKUM",
      kelas: "11",
      nama_dosen: "Dr. Ir. Adi Rahwanto, M.Eng.Sc",
      nip: "196008091987031003",
      ruang: "PRODI TI",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "16"
    },
    {
      id: 604,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU104",
      nama_mk: "FISIKA DASAR II + PRAKTIKUM",
      kelas: "12",
      nama_dosen: "Evi Yufita, S.Si., M.Si",
      nip: "197509202000122001",
      ruang: "PRODI TI",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "33"
    },
    {
      id: 605,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU104",
      nama_mk: "FISIKA DASAR II + PRAKTIKUM",
      kelas: "13",
      nama_dosen: "Zulfalina, S.Si., M.Si",
      nip: "197507072002122001",
      ruang: "PRODI TI",
      hari: "Jumat",
      waktu: "16:35-18:15",
      peserta: "31"
    },
    {
      id: 606,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU106",
      nama_mk: "STATISTIK INDUSTRI I",
      kelas: "11",
      nama_dosen: "Sarika Zuhri, S.T., M.T.",
      nip: "198908142019032019",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "8:00-10:50",
      peserta: "24"
    },
    {
      id: 607,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU106",
      nama_mk: "STATISTIK INDUSTRI I",
      kelas: "12",
      nama_dosen: "Friesca Erwan, S.T., MProjMgt",
      nip: "198405172019011101",
      ruang: "PRODI TI",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "23"
    },
    {
      id: 608,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU106",
      nama_mk: "STATISTIK INDUSTRI I",
      kelas: "13",
      nama_dosen: "Nur Izzaty, S.T.,M.Sc.Eng",
      nip: "198408072019032011",
      ruang: "PRODI TI",
      hari: "Senin",
      waktu: "14:00-16:30",
      peserta: "25"
    },
    {
      id: 609,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU106",
      nama_mk: "STATISTIK INDUSTRI I",
      kelas: "14",
      nama_dosen: "Awal Aflizal Zubir, S.T., M.Sc",
      nip: "199104092021021101",
      ruang: "PRODI TI",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "5"
    },
    {
      id: 610,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU108",
      nama_mk: "MEKANIKA TEKNIK",
      kelas: "11",
      nama_dosen: "Awal Aflizal Zubir, S.T., M.Sc",
      nip: "199104092021021101",
      ruang: "PRODI TI",
      hari: "Jumat",
      waktu: "8:00-9:40",
      peserta: "21"
    },
    {
      id: 611,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU108",
      nama_mk: "MEKANIKA TEKNIK",
      kelas: "12",
      nama_dosen: "Dr. Irwansyah, ST, M.Eng",
      nip: "197609182002121004",
      ruang: "PRODI TI",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "28"
    },
    {
      id: 612,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU108",
      nama_mk: "MEKANIKA TEKNIK",
      kelas: "13",
      nama_dosen: "Amir Zaki Mubarak, S.T, M. Sc",
      nip: "197910122006041002",
      ruang: "PRODI TI",
      hari: "Jumat",
      waktu: "8:00-9:40",
      peserta: "29"
    },
    {
      id: 613,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU110",
      nama_mk: "MATRIKS DAN RUANG VEKTOR",
      kelas: "11",
      nama_dosen: "Vera Halfiani, S.Si., M.Mat",
      nip: "198910062019032014",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "25"
    },
    {
      id: 614,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU110",
      nama_mk: "MATRIKS DAN RUANG VEKTOR",
      kelas: "12",
      nama_dosen: "Vera Halfiani, S.Si., M.Mat",
      nip: "198910062019032014",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "31"
    },
    {
      id: 615,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU110",
      nama_mk: "MATRIKS DAN RUANG VEKTOR",
      kelas: "13",
      nama_dosen: "Vera Halfiani, S.Si., M.Mat",
      nip: "198910062019032014",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "24"
    },
    {
      id: 616,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU202",
      nama_mk: "PEMROGRAMAN  + PRAKTIKUM",
      kelas: "11",
      nama_dosen: "Rizki Agam Syahputra, S.T., M.Si., M.AppIn&E",
      nip: "199307212021021101",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "32"
    },
    {
      id: 617,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU202",
      nama_mk: "PEMROGRAMAN  + PRAKTIKUM",
      kelas: "12",
      nama_dosen: "Rizki Agam Syahputra, S.T., M.Si., M.AppIn&E",
      nip: "199307212021021101",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "40"
    },
    {
      id: 618,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU202",
      nama_mk: "PEMROGRAMAN  + PRAKTIKUM",
      kelas: "13",
      nama_dosen: "Rizki Agam Syahputra, S.T., M.Si., M.AppIn&E",
      nip: "199307212021021101",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "29"
    },
    {
      id: 619,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU204",
      nama_mk: "PENELITIAN OPERASIONAL I",
      kelas: "11",
      nama_dosen: "Raihan Dara Lufika, S.T., M.Sc",
      nip: "199412282020122004",
      ruang: "PRODI TI",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "31"
    },
    {
      id: 620,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU204",
      nama_mk: "PENELITIAN OPERASIONAL I",
      kelas: "12",
      nama_dosen: "Sri Rahmawati, S.T., M.T",
      nip: "199401092019032012",
      ruang: "PRODI TI",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "35"
    },
    {
      id: 621,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU204",
      nama_mk: "PENELITIAN OPERASIONAL I",
      kelas: "13",
      nama_dosen: "Sri Rahmawati, S.T., M.T",
      nip: "199401092019032012",
      ruang: "PRODI TI",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "34"
    },
    {
      id: 622,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU206",
      nama_mk: "PEMODELAN SISTEM",
      kelas: "11",
      nama_dosen: "Raihan Dara Lufika, S.T., M.Sc",
      nip: "199412282020122004",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "16:35-18:15",
      peserta: "35"
    },
    {
      id: 623,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU206",
      nama_mk: "PEMODELAN SISTEM",
      kelas: "12",
      nama_dosen: "Nur Izzaty, S.T.,M.Sc.Eng",
      nip: "198408072019032011",
      ruang: "PRODI TI",
      hari: "Senin",
      waktu: "16:35-18:15",
      peserta: "34"
    },
    {
      id: 624,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU206",
      nama_mk: "PEMODELAN SISTEM",
      kelas: "13",
      nama_dosen: "Riski Arifin, S.T., M.T.",
      nip: "199505272021021101",
      ruang: "PRODI TI",
      hari: "Jumat",
      waktu: "16:35-12:25",
      peserta: "25"
    },
    {
      id: 625,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU208",
      nama_mk: "ELEKTRONIKA INDUSTRI",
      kelas: "11",
      nama_dosen: "Ir. Syahriza, M.Eng",
      nip: "196708081994031005",
      ruang: "PRODI TI",
      hari: "Jumat",
      waktu: "10:40-12:25",
      peserta: "40"
    },
    {
      id: 626,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU208",
      nama_mk: "ELEKTRONIKA INDUSTRI",
      kelas: "12",
      nama_dosen: "Ir. Syahriza, M.Eng",
      nip: "196708081994031005",
      ruang: "PRODI TI",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "31"
    },
    {
      id: 627,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU208",
      nama_mk: "ELEKTRONIKA INDUSTRI",
      kelas: "13",
      nama_dosen: "Awal Aflizal Zubir, S.T., M.Sc",
      nip: "199104092021021101",
      ruang: "PRODI TI",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "25"
    },
    {
      id: 628,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU210",
      nama_mk: "PERANCANGAN SISTEM KERJA",
      kelas: "11",
      nama_dosen: "Friesca Erwan, S.T., MProjMgt",
      nip: "198405172019011101",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "10:45-12:25",
      peserta: "25"
    },
    {
      id: 629,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU210",
      nama_mk: "PERANCANGAN SISTEM KERJA",
      kelas: "12",
      nama_dosen: "Riski Arifin, S.T., M.T.",
      nip: "199505272021021101",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "10:45-12:25",
      peserta: "37"
    },
    {
      id: 630,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU210",
      nama_mk: "PERANCANGAN SISTEM KERJA",
      kelas: "13",
      nama_dosen: "Riski Arifin, S.T., M.T.",
      nip: "199505272021021101",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "10:45-12:25",
      peserta: "35"
    },
    {
      id: 631,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU212",
      nama_mk: "ANALISIS DAN ESTIMASI BIAYA",
      kelas: "11",
      nama_dosen: "Didi Asmadi, S.T.P., M.T., IPM.",
      nip: "198405132019031006",
      ruang: "PRODI TI",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "28"
    },
    {
      id: 632,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU212",
      nama_mk: "ANALISIS DAN ESTIMASI BIAYA",
      kelas: "12",
      nama_dosen: "Didi Asmadi, S.T.P., M.T., IPM.",
      nip: "198405132019031006",
      ruang: "PRODI TI",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "35"
    },
    {
      id: 633,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU212",
      nama_mk: "ANALISIS DAN ESTIMASI BIAYA",
      kelas: "13",
      nama_dosen: "Sri Rahmawati, S.T., M.T",
      nip: "199401092019032012",
      ruang: "PRODI TI",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "35"
    },
    {
      id: 634,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU214",
      nama_mk: "PSIKOLOGI INDUSTRI",
      kelas: "11",
      nama_dosen: "Didi Asmadi, S.T.P., M.T., IPM.",
      nip: "198405132019031006",
      ruang: "PRODI TI",
      hari: "Jumat",
      waktu: "16:35-18:15",
      peserta: "28"
    },
    {
      id: 635,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU214",
      nama_mk: "PSIKOLOGI INDUSTRI",
      kelas: "12",
      nama_dosen: "Didi Asmadi, S.T.P., M.T., IPM.",
      nip: "198405132019031006",
      ruang: "PRODI TI",
      hari: "Jumat",
      waktu: "16:35-18:15",
      peserta: "24"
    },
    {
      id: 636,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU214",
      nama_mk: "PSIKOLOGI INDUSTRI",
      kelas: "13",
      nama_dosen: "Sri Rahmawati, S.T., M.T",
      nip: "199401092019032012",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "10:45-12:25",
      peserta: "38"
    },
    {
      id: 637,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU216",
      nama_mk: "KESELAMATAN DAN KESEHATAN KERJA",
      kelas: "11",
      nama_dosen: "Suhendrianto, ST, M. Sc.",
      nip: "197712092006041001",
      ruang: "PRODI TI",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "36"
    },
    {
      id: 638,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU216",
      nama_mk: "KESELAMATAN DAN KESEHATAN KERJA",
      kelas: "12",
      nama_dosen: "Suhendrianto, ST, M. Sc.",
      nip: "197712092006041001",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "10:45-12:25",
      peserta: "19"
    },
    {
      id: 639,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU216",
      nama_mk: "KESELAMATAN DAN KESEHATAN KERJA",
      kelas: "13",
      nama_dosen: "Suhendrianto, ST, M. Sc.",
      nip: "197712092006041001",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "35"
    },
    {
      id: 640,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU302",
      nama_mk: "MANAJEMEN PROYEK",
      kelas: "11",
      nama_dosen: "Dr. Ir. Hasan Yudie Sastra, DEA",
      nip: "195703301986031004",
      ruang: "PRODI TI",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "34"
    },
    {
      id: 641,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU302",
      nama_mk: "MANAJEMEN PROYEK",
      kelas: "12",
      nama_dosen: "Dr. Ir. Hasan Yudie Sastra, DEA",
      nip: "195703301986031004",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "27"
    },
    {
      id: 642,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU302",
      nama_mk: "MANAJEMEN PROYEK",
      kelas: "13",
      nama_dosen: "Friesca Erwan, S.T., MProjMgt",
      nip: "198405172019011101",
      ruang: "PRODI TI",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "30"
    },
    {
      id: 643,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU304",
      nama_mk: "SISTEM PRODUKSI",
      kelas: "11",
      nama_dosen: "Raihan Dara Lufika, S.T., M.Sc",
      nip: "199412282020122004",
      ruang: "PRODI TI",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "40"
    },
    {
      id: 644,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU304",
      nama_mk: "SISTEM PRODUKSI",
      kelas: "12",
      nama_dosen: "Riski Arifin, S.T., M.T.",
      nip: "199505272021021101",
      ruang: "PRODI TI",
      hari: "Rabu",
      waktu: "16:35-18:15",
      peserta: "28"
    },
    {
      id: 645,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU304",
      nama_mk: "SISTEM PRODUKSI",
      kelas: "13",
      nama_dosen: "Raihan Dara Lufika, S.T., M.Sc",
      nip: "199412282020122004",
      ruang: "PRODI TI",
      hari: "Rabu",
      waktu: "16:35-18:15",
      peserta: "32"
    },
    {
      id: 646,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU306",
      nama_mk: "PRAKTIKUM TERINTEGRASI",
      kelas: "11",
      nama_dosen: "Raihan Dara Lufika, S.T., M.Sc",
      nip: "199412282020122004",
      ruang: "PRODI TI",
      hari: "Sabtu",
      waktu: "10:45-12:25",
      peserta: "97"
    },
    {
      id: 647,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU308",
      nama_mk: "METODOLOGI PENELITIAN",
      kelas: "11",
      nama_dosen: "Friesca Erwan, S.T., MProjMgt",
      nip: "198405172019011101",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "33"
    },
    {
      id: 648,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU308",
      nama_mk: "METODOLOGI PENELITIAN",
      kelas: "12",
      nama_dosen: "Nur Izzaty, S.T.,M.Sc.Eng",
      nip: "198408072019032011",
      ruang: "PRODI TI",
      hari: "Kamis",
      waktu: "16:35-16:15",
      peserta: "41"
    },
    {
      id: 649,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU308",
      nama_mk: "METODOLOGI PENELITIAN",
      kelas: "13",
      nama_dosen: "Sarika Zuhri, S.T., M.T.",
      nip: "198908142019032019",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "16:35-16:15",
      peserta: "19"
    },
    {
      id: 650,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU310",
      nama_mk: "PENGENDALIAN DAN PENJAMINAN MUTU",
      kelas: "11",
      nama_dosen: "Sarika Zuhri, S.T., M.T.",
      nip: "198908142019032019",
      ruang: "PRODI TI",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "26"
    },
    {
      id: 651,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU310",
      nama_mk: "PENGENDALIAN DAN PENJAMINAN MUTU",
      kelas: "12",
      nama_dosen: "Ir. Ilyas, M.T.",
      nip: "196302061991021001",
      ruang: "PRODI TI",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "36"
    },
    {
      id: 652,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU310",
      nama_mk: "PENGENDALIAN DAN PENJAMINAN MUTU",
      kelas: "13",
      nama_dosen: "Ir. Ilyas, M.T.",
      nip: "196302061991021001",
      ruang: "PRODI TI",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "32"
    },
    {
      id: 653,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU312",
      nama_mk: "ANALISIS DAN PERANCANGAN SISTEM INFORMASI + PRAKTIKUM",
      kelas: "11",
      nama_dosen: "Nur Izzaty, S.T.,M.Sc.Eng",
      nip: "198408072019032011",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "16:35-18:15",
      peserta: "40"
    },
    {
      id: 654,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU312",
      nama_mk: "ANALISIS DAN PERANCANGAN SISTEM INFORMASI + PRAKTIKUM",
      kelas: "12",
      nama_dosen: "Rizki Agam Syahputra, S.T., M.Si., M.AppIn&E",
      nip: "199307212021021101",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "16:35-18:15",
      peserta: "28"
    },
    {
      id: 655,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU312",
      nama_mk: "ANALISIS DAN PERANCANGAN SISTEM INFORMASI + PRAKTIKUM",
      kelas: "13",
      nama_dosen: "Sri Rahmawati, S.T., M.T",
      nip: "199401092019032012",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "16:35-18:15",
      peserta: "26"
    },
    {
      id: 656,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU314",
      nama_mk: "MANAJEMEN RANTAI PASOK",
      kelas: "11",
      nama_dosen: "Raihan Dara Lufika, S.T., M.Sc",
      nip: "199412282020122004",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "9:40-12:20",
      peserta: "30"
    },
    {
      id: 657,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU314",
      nama_mk: "MANAJEMEN RANTAI PASOK",
      kelas: "12",
      nama_dosen: "Raihan Dara Lufika, S.T., M.Sc",
      nip: "199412282020122004",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "9:40-12:20",
      peserta: "38"
    },
    {
      id: 658,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIU314",
      nama_mk: "MANAJEMEN RANTAI PASOK",
      kelas: "13",
      nama_dosen: "Raihan Dara Lufika, S.T., M.Sc",
      nip: "199412282020122004",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "9:40-12:20",
      peserta: "31"
    },
    {
      id: 659,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP01",
      nama_mk: "KERJA PRAKTEK",
      kelas: "11",
      nama_dosen: "Sarika Zuhri, S.T., M.T.",
      nip: "198908142019032019",
      ruang: "PRODI TI",
      hari: "Sabtu",
      waktu: "9:00-11:00",
      peserta: "4"
    },
    {
      id: 660,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP10",
      nama_mk: "ERGONOMI UNTUK PERANCANGAN",
      kelas: "11",
      nama_dosen: "Dr. Ir. Iskandar, ST.M.Eng.Sc., IPM., ASEAN Eng",
      nip: "197303041998021002",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "4"
    },
    {
      id: 661,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP11",
      nama_mk: "DESIGN FOR MANUFACTURE, ASSEMBLY & ENVIRONMENT",
      kelas: "11",
      nama_dosen: "Ir. Syahriza, M.Eng",
      nip: "196708081994031005",
      ruang: "PRODI TI",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "0"
    },
    {
      id: 662,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP12",
      nama_mk: "REKAYASA CONCURRENT",
      kelas: "11",
      nama_dosen: "",
      nip: "197712092006041001",
      ruang: "PRODI TI",
      hari: "Kamis",
      waktu: "14:00-16:30",
      peserta: "0"
    },
    {
      id: 663,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP13",
      nama_mk: "MANUFAKTUR RAMPING",
      kelas: "11",
      nama_dosen: "Ir. Ilyas, M.T.",
      nip: "196302061991021001",
      ruang: "PRODI TI",
      hari: "Senin",
      waktu: "10:45-12:30",
      peserta: "7"
    },
    {
      id: 664,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP14",
      nama_mk: "SISTEM MANUFAKTUR TERINTEGRASI KOMPUTER",
      kelas: "11",
      nama_dosen: "Ir. Syahriza, M.Eng",
      nip: "196708081994031005",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "3"
    },
    {
      id: 665,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP15",
      nama_mk: "MANUFAKTUR BERKELANJUTAN",
      kelas: "11",
      nama_dosen: "Suhendrianto, ST, M. Sc.",
      nip: "197712092006041001",
      ruang: "PRODI TI",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "1"
    },
    {
      id: 666,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP16",
      nama_mk: "MANAJEMEN TEKNOLOGI DAN INOVASI",
      kelas: "11",
      nama_dosen: "Suhendrianto, ST, M. Sc.",
      nip: "197712092006041001",
      ruang: "PRODI TI",
      hari: "Kamis",
      waktu: "10:40-13:15",
      peserta: "1"
    },
    {
      id: 667,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP17",
      nama_mk: "TEKNIK KEANDALAN DAN PERAWATAN",
      kelas: "11",
      nama_dosen: "Awal Aflizal Zubir, S.T., M.Sc",
      nip: "199104092021021101",
      ruang: "PRODI TI",
      hari: "Senin",
      waktu: "16:35-18:00",
      peserta: "2"
    },
    {
      id: 668,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP18",
      nama_mk: "PEMASARAN INDUSTRI",
      kelas: "11",
      nama_dosen: "Edy Fradinata, ST., M.T., Ph.D. IPM",
      nip: "196911182017091101",
      ruang: "PRODI TI",
      hari: "Rabu",
      waktu: "10:45-13:15",
      peserta: "39"
    },
    {
      id: 669,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP19",
      nama_mk: "PENINGKATAN KUALITAS",
      kelas: "11",
      nama_dosen: "Sarika Zuhri, S.T., M.T.",
      nip: "198908142019032019",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "5"
    },
    {
      id: 670,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP20",
      nama_mk: "SISTEM DAN KEBIJAKAN INDUSTRI",
      kelas: "11",
      nama_dosen: "Dr. Ir. Hasan Yudie Sastra, DEA",
      nip: "195703301986031004",
      ruang: "PRODI TI",
      hari: "Jumat",
      waktu: "14:00-16:30",
      peserta: "0"
    },
    {
      id: 671,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP21",
      nama_mk: "STRATEGI KOMPETITIF",
      kelas: "11",
      nama_dosen: "Dr. Ir. Hasan Yudie Sastra, DEA",
      nip: "195703301986031004",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "0"
    },
    {
      id: 672,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP22",
      nama_mk: "PENGAMBILAN KEPUTUSAN MULTI KRITERIA",
      kelas: "11",
      nama_dosen: "Didi Asmadi, S.T.P., M.T., IPM.",
      nip: "198405132019031006",
      ruang: "PRODI TI",
      hari: "Kamis",
      waktu: "8:00-10:30",
      peserta: "0"
    },
    {
      id: 673,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP23",
      nama_mk: "SISTEM INVENTORI",
      kelas: "11",
      nama_dosen: "Raihan Dara Lufika, S.T., M.Sc",
      nip: "199412282020122004",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "16:30-18:00",
      peserta: "1"
    },
    {
      id: 674,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP24",
      nama_mk: "METAHEURISTIK",
      kelas: "11",
      nama_dosen: "Rizki Agam Syahputra, S.T., M.Si., M.AppIn&E",
      nip: "199307212021021101",
      ruang: "PRODI TI",
      hari: "Jumat",
      waktu: "16:35-18:15",
      peserta: "0"
    },
    {
      id: 675,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP25",
      nama_mk: "MANAJEMEN KINERJA",
      kelas: "11",
      nama_dosen: "Didi Asmadi, S.T.P., M.T., IPM.",
      nip: "198405132019031006",
      ruang: "PRODI TI",
      hari: "Selasa",
      waktu: "16:30-18:00",
      peserta: "1"
    },
    {
      id: 676,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP26",
      nama_mk: "DESIGN OF EXPERIMENT",
      kelas: "11",
      nama_dosen: "Sarika Zuhri, S.T., M.T.",
      nip: "198908142019032019",
      ruang: "PRODI TI",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "0"
    },
    {
      id: 677,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP27",
      nama_mk: "REKAYASA PRODUKTIVITAS",
      kelas: "11",
      nama_dosen: "Edy Fradinata, ST., M.T., Ph.D. IPM",
      nip: "196911182017091101",
      ruang: "PRODI TI",
      hari: "Rabu",
      waktu: "14:00-15:30",
      peserta: "1"
    },
    {
      id: 678,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP29",
      nama_mk: "REKAYASA PROSES BISNIS",
      kelas: "11",
      nama_dosen: "Nur Izzaty, S.T.,M.Sc.Eng",
      nip: "198408072019032011",
      ruang: "PRODI TI",
      hari: "Rabu",
      waktu: "16:30-18:00",
      peserta: "7"
    },
    {
      id: 679,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP31",
      nama_mk: "SISTEM DINAMIK",
      kelas: "11",
      nama_dosen: "Ir. Ilyas, M.T.",
      nip: "196302061991021001",
      ruang: "PRODI TI",
      hari: "Kamis",
      waktu: "14:00-15:30",
      peserta: "4"
    },
    {
      id: 680,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP33",
      nama_mk: "ENTERPRISE RESOURCE PLANNING SYSTEM",
      kelas: "11",
      nama_dosen: "Nur Izzaty, S.T.,M.Sc.Eng",
      nip: "198408072019032011",
      ruang: "PRODI TI",
      hari: "Kamis",
      waktu: "16:30-18:00",
      peserta: "0"
    },
    {
      id: 681,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP35",
      nama_mk: "DATA ANALITIK",
      kelas: "11",
      nama_dosen: "Riski Arifin, S.T., M.T.",
      nip: "199505272021021101",
      ruang: "PRODI TI",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "0"
    },
    {
      id: 682,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUP37",
      nama_mk: "PERANCANGAN E-BISNIS",
      kelas: "11",
      nama_dosen: "Nur Izzaty, S.T.,M.Sc.Eng",
      nip: "198408072019032011",
      ruang: "PRODI TI",
      hari: "Jumat",
      waktu: "10:35-13:15",
      peserta: "16"
    },
    {
      id: 683,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUPA1",
      nama_mk: "PROPOSAL TUGAS AKHIR",
      kelas: "11",
      nama_dosen: "Riski Arifin, S.T., M.T.",
      nip: "199505272021021101",
      ruang: "PRODI TI",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "59"
    },
    {
      id: 684,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410601",
      nama_prodi: "Teknik Industri",
      kode_mk: "TIUPA2",
      nama_mk: "TUGAS AKHIR",
      kelas: "11",
      nama_dosen: "Riski Arifin, S.T., M.T.",
      nip: "199505272021021101",
      ruang: "PRODI TI",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "97"
    },
    {
      id: 685,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE102",
      nama_mk: "KALKULUS II",
      kelas: "11",
      nama_dosen: "Muhammad Irhamsyah, ST., MT",
      nip: "197207182001121001",
      ruang: "A23 - 206",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "30"
    },
    {
      id: 686,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE102",
      nama_mk: "KALKULUS II",
      kelas: "12",
      nama_dosen: "Ir.. Hubbul Walidainy, S.T., M.T., IPM",
      nip: "197308262000121001",
      ruang: "A14 - 302",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "29"
    },
    {
      id: 687,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE102",
      nama_mk: "KALKULUS II",
      kelas: "13",
      nama_dosen: "Dr. Yunida, S.T.",
      nip: "199106152021022101",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "30"
    },
    {
      id: 688,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE102",
      nama_mk: "KALKULUS II",
      kelas: "14",
      nama_dosen: "Ir. Ernita Dewi Meutia, PG.Dipl., M.Sc",
      nip: "196809041992032001",
      ruang: "A14 - 301",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "16"
    },
    {
      id: 689,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE104",
      nama_mk: "ALJABAR LINIER DAN VARIABEL KOMPLEKS",
      kelas: "11",
      nama_dosen: "Muhammad Irhamsyah, ST., MT",
      nip: "197207182001121001",
      ruang: "TE",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "30"
    },
    {
      id: 690,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE104",
      nama_mk: "ALJABAR LINIER DAN VARIABEL KOMPLEKS",
      kelas: "12",
      nama_dosen: "Ir. Mansur Gapy, M.T.",
      nip: "195810041986031001",
      ruang: "A14 - 301",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "30"
    },
    {
      id: 691,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE104",
      nama_mk: "ALJABAR LINIER DAN VARIABEL KOMPLEKS",
      kelas: "13",
      nama_dosen: "Alfatirta Mufti, S.T, M.Sc",
      nip: "198003062005011002",
      ruang: "TE",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "30"
    },
    {
      id: 692,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE104",
      nama_mk: "ALJABAR LINIER DAN VARIABEL KOMPLEKS",
      kelas: "14",
      nama_dosen: "Roslidar, ST., M.Sc",
      nip: "197807192002122002",
      ruang: "TE",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "26"
    },
    {
      id: 693,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE108",
      nama_mk: "FISIKA II",
      kelas: "11",
      nama_dosen: "Yunidar, S.Si.,MT",
      nip: "197406292000032001",
      ruang: "A14 - 301",
      hari: "Rabu",
      waktu: "8:00-10:40",
      peserta: "20"
    },
    {
      id: 694,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE108",
      nama_mk: "FISIKA II",
      kelas: "12",
      nama_dosen: "Alfatirta Mufti, S.T, M.Sc",
      nip: "198003062005011002",
      ruang: "A14 - 302",
      hari: "Rabu",
      waktu: "8:00-10:40",
      peserta: "30"
    },
    {
      id: 695,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE108",
      nama_mk: "FISIKA II",
      kelas: "13",
      nama_dosen: "Akhyar, S.T., M.Eng",
      nip: "198709082019031007",
      ruang: "A23 - 206",
      hari: "Rabu",
      waktu: "8:00-10:40",
      peserta: "30"
    },
    {
      id: 696,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE108",
      nama_mk: "FISIKA II",
      kelas: "14",
      nama_dosen: "Fathurrahman, S.T., M.Eng.Sc",
      nip: "198701052019031009",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "8:00-10:40",
      peserta: "30"
    },
    {
      id: 697,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE110",
      nama_mk: "PEMROGRAMAN II + LAB",
      kelas: "11",
      nama_dosen: "Akhyar, S.T., M.Eng",
      nip: "198709082019031007",
      ruang: "LAB JARINGAN",
      hari: "Sabtu",
      waktu: "10:45-13:15",
      peserta: "29"
    },
    {
      id: 698,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE110",
      nama_mk: "PEMROGRAMAN II + LAB",
      kelas: "12",
      nama_dosen: "Sayed Muchallil, S.T., M. Sc",
      nip: "198006162005011002",
      ruang: "LAB DATA",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "30"
    },
    {
      id: 699,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE110",
      nama_mk: "PEMROGRAMAN II + LAB",
      kelas: "13",
      nama_dosen: "Sayed Muchallil, S.T., M. Sc",
      nip: "198006162005011002",
      ruang: "LAB KOMPUTER",
      hari: "Sabtu",
      waktu: "10:45-13:15",
      peserta: "30"
    },
    {
      id: 700,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE110",
      nama_mk: "PEMROGRAMAN II + LAB",
      kelas: "14",
      nama_dosen: "Ardiansyah, BSEE.,M.Sc",
      nip: "197212261992011001",
      ruang: "LAB KOMPUTER",
      hari: "Sabtu",
      waktu: "10:45-13:15",
      peserta: "16"
    },
    {
      id: 701,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE112",
      nama_mk: "KIMIA + LAB",
      kelas: "11",
      nama_dosen: "Dr. Muliadi Ramli, S.Si., M.Si",
      nip: "197303011998021001",
      ruang: "A14 - 301",
      hari: "Rabu",
      waktu: "16:30-18:00",
      peserta: "29"
    },
    {
      id: 702,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE112",
      nama_mk: "KIMIA + LAB",
      kelas: "12",
      nama_dosen: "Prof. Dr. Rahmi, S.Si, M.Si",
      nip: "197209271999032001",
      ruang: "A14 - 302",
      hari: "Rabu",
      waktu: "16:30-18:00",
      peserta: "29"
    },
    {
      id: 703,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE112",
      nama_mk: "KIMIA + LAB",
      kelas: "13",
      nama_dosen: "Dr. Surya Lubis, S.Si, M.Si",
      nip: "196905101999032001",
      ruang: "A23 - 206",
      hari: "Rabu",
      waktu: "16:30-18:00",
      peserta: "30"
    },
    {
      id: 704,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE112",
      nama_mk: "KIMIA + LAB",
      kelas: "14",
      nama_dosen: "Dr. Nurhaida, S.Si, M.Si",
      nip: "197003301999032005",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "16:30-18:00",
      peserta: "42"
    },
    {
      id: 705,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE112",
      nama_mk: "KIMIA + LAB",
      kelas: "15",
      nama_dosen: "Dr. Muliadi Ramli, S.Si., M.Si",
      nip: "197303011998021001",
      ruang: "A23-206",
      hari: "Kamis",
      waktu: "16:30-18:00",
      peserta: "27"
    },
    {
      id: 706,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE112",
      nama_mk: "KIMIA + LAB",
      kelas: "16",
      nama_dosen: "Dr. Khairi, S.Si., M.Si.",
      nip: "196906141999031002",
      ruang: "A25-202",
      hari: "Senin",
      waktu: "14:00-15:45",
      peserta: "31"
    },
    {
      id: 707,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE206",
      nama_mk: "MANAJEMEN DAN EKONOMI TEKNIK",
      kelas: "11",
      nama_dosen: "Dr. Ir. Syahrial, M.Eng",
      nip: "196401121992031003",
      ruang: "A14 - 301",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "16"
    },
    {
      id: 708,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE206",
      nama_mk: "MANAJEMEN DAN EKONOMI TEKNIK",
      kelas: "12",
      nama_dosen: "Dr. Ir. Rizal Munadi, M.M., MT",
      nip: "196708151993031005",
      ruang: "A14 - 302",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "22"
    },
    {
      id: 709,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE206",
      nama_mk: "MANAJEMEN DAN EKONOMI TEKNIK",
      kelas: "13",
      nama_dosen: "Ahmadiar, S.T., M.Sc.",
      nip: "198006252008121001",
      ruang: "A23 - 206",
      hari: "Selasa",
      waktu: "10:45-13:45",
      peserta: "29"
    },
    {
      id: 710,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE206",
      nama_mk: "MANAJEMEN DAN EKONOMI TEKNIK",
      kelas: "14",
      nama_dosen: "Ahmadiar, S.T., M.Sc.",
      nip: "198006252008121001",
      ruang: "RKTE 1",
      hari: "Selasa",
      waktu: "10:45-13:45",
      peserta: "26"
    },
    {
      id: 711,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE208",
      nama_mk: "MATERIAL TEKNIK ELEKTRO",
      kelas: "11",
      nama_dosen: "Ir. Ramdhan Halid Siregar, S.T., M.T., IPM",
      nip: "196909201999031001",
      ruang: "A14 - 301",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "27"
    },
    {
      id: 712,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE208",
      nama_mk: "MATERIAL TEKNIK ELEKTRO",
      kelas: "12",
      nama_dosen: "Ir. Agus Adria, M.Sc.",
      nip: "196308131990021003",
      ruang: "A14 - 302",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "10"
    },
    {
      id: 713,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE208",
      nama_mk: "MATERIAL TEKNIK ELEKTRO",
      kelas: "13",
      nama_dosen: "Dr. Ira Devi Sara, ST., M. Eng.Sc.",
      nip: "197705252001122001",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "28"
    },
    {
      id: 714,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE208",
      nama_mk: "MATERIAL TEKNIK ELEKTRO",
      kelas: "14",
      nama_dosen: "Dr. Rakhmad Syafutra Lubis, S.T., M.T.",
      nip: "196901051999031001",
      ruang: "RKTE 2",
      hari: "Rabu",
      waktu: "8:00-9:40",
      peserta: "30"
    },
    {
      id: 715,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE214",
      nama_mk: "MATEMATIKA DISKRIT",
      kelas: "11",
      nama_dosen: "Masduki Khamdan Muchamad, S.Kom., M.Sc.",
      nip: "199404082019031009",
      ruang: "A14 - 301",
      hari: "Senin",
      waktu: "8:00-10:45",
      peserta: "8"
    },
    {
      id: 716,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE214",
      nama_mk: "MATEMATIKA DISKRIT",
      kelas: "12",
      nama_dosen: "Roslidar, ST., M.Sc",
      nip: "197807192002122002",
      ruang: "A14 - 302",
      hari: "Senin",
      waktu: "8:00-10:45",
      peserta: "0"
    },
    {
      id: 717,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE214",
      nama_mk: "MATEMATIKA DISKRIT",
      kelas: "13",
      nama_dosen: "Dr. Yunida, S.T.",
      nip: "199106152021022101",
      ruang: "A23 - 206",
      hari: "Senin",
      waktu: "8:00-10:45",
      peserta: "20"
    },
    {
      id: 718,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE214",
      nama_mk: "MATEMATIKA DISKRIT",
      kelas: "14",
      nama_dosen: "Yudha Nurdin, ST.,MT",
      nip: "197910012010121002",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-10:45",
      peserta: "0"
    },
    {
      id: 719,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE216",
      nama_mk: "DASAR SISTEM TELEKOMUNIKASI + LAB",
      kelas: "11",
      nama_dosen: "Dr. Melinda, ST., M.Sc",
      nip: "197906102002122001",
      ruang: "A14 - 302",
      hari: "Jumat",
      waktu: "8:00-9:40",
      peserta: "23"
    },
    {
      id: 720,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE216",
      nama_mk: "DASAR SISTEM TELEKOMUNIKASI + LAB",
      kelas: "12",
      nama_dosen: "Dr. Ir. Syahrial, M.Eng",
      nip: "196401121992031003",
      ruang: "RKTE 1",
      hari: "Jumat",
      waktu: "8:00-9:40",
      peserta: "28"
    },
    {
      id: 721,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE216",
      nama_mk: "DASAR SISTEM TELEKOMUNIKASI + LAB",
      kelas: "13",
      nama_dosen: "Dr. Ir. Rizal Munadi, M.M., MT",
      nip: "196708151993031005",
      ruang: "A23 - 206",
      hari: "Jumat",
      waktu: "8:00-9:40",
      peserta: "30"
    },
    {
      id: 722,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE216",
      nama_mk: "DASAR SISTEM TELEKOMUNIKASI + LAB",
      kelas: "14",
      nama_dosen: "Ir. Ernita Dewi Meutia, PG.Dipl., M.Sc",
      nip: "196809041992032001",
      ruang: "RKTE 2",
      hari: "Jumat",
      waktu: "8:00-9:40",
      peserta: "11"
    },
    {
      id: 723,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE218",
      nama_mk: "DASAR SISTEM KENDALI + LAB",
      kelas: "11",
      nama_dosen: "Dr. Rakhmad Syafutra Lubis, S.T., M.T.",
      nip: "196901051999031001",
      ruang: "A14 - 301",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "32"
    },
    {
      id: 724,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE218",
      nama_mk: "DASAR SISTEM KENDALI + LAB",
      kelas: "12",
      nama_dosen: "Ir. Fahri Heltha, M.Eng",
      nip: "196309161990021001",
      ruang: "A14 - 302",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "30"
    },
    {
      id: 725,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE218",
      nama_mk: "DASAR SISTEM KENDALI + LAB",
      kelas: "13",
      nama_dosen: "Dr. Rusdha Muharar, S.T, M. Sc",
      nip: "197804182006041003",
      ruang: "RKTE 1",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "0"
    },
    {
      id: 726,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE218",
      nama_mk: "DASAR SISTEM KENDALI + LAB",
      kelas: "14",
      nama_dosen: "Alfatirta Mufti, S.T, M.Sc",
      nip: "198003062005011002",
      ruang: "RKTE 2",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "29"
    },
    {
      id: 727,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE220",
      nama_mk: "RANGKAIAN LISTRIK II + LAB",
      kelas: "11",
      nama_dosen: "Ir. Mahdi Syukri, S.T., MT., IPM",
      nip: "196812101998021001",
      ruang: "A14 - 301",
      hari: "Rabu",
      waktu: "10:45-12:25",
      peserta: "6"
    },
    {
      id: 728,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE220",
      nama_mk: "RANGKAIAN LISTRIK II + LAB",
      kelas: "12",
      nama_dosen: "Ir. Ramdhan Halid Siregar, S.T., M.T., IPM",
      nip: "196909201999031001",
      ruang: "A14 - 302",
      hari: "Rabu",
      waktu: "10:45-12:25",
      peserta: "3"
    },
    {
      id: 729,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE220",
      nama_mk: "RANGKAIAN LISTRIK II + LAB",
      kelas: "13",
      nama_dosen: "Dr. Ir. Suriadi, S.T, M.Sc., IPM., ASEAN Eng",
      nip: "197206061998021001",
      ruang: "RKTE 3",
      hari: "Selasa",
      waktu: "16:30-18:00",
      peserta: "3"
    },
    {
      id: 730,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE220",
      nama_mk: "RANGKAIAN LISTRIK II + LAB",
      kelas: "14",
      nama_dosen: "Syukriyadin, S.T., M.T.",
      nip: "197108201999031004",
      ruang: "A23 - 206",
      hari: "Rabu",
      waktu: "10:45-12:25",
      peserta: "0"
    },
    {
      id: 731,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE222",
      nama_mk: "DASAR ELEKTRONIKA + LAB",
      kelas: "11",
      nama_dosen: "Zulfikar, S.T., M.Sc",
      nip: "197507202006041003",
      ruang: "A14 - 301",
      hari: "Senin",
      waktu: "14:00-16:30",
      peserta: "24"
    },
    {
      id: 732,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE222",
      nama_mk: "DASAR ELEKTRONIKA + LAB",
      kelas: "12",
      nama_dosen: "Zulfikar, S.T., M.Sc",
      nip: "197507202006041003",
      ruang: "A14 - 302",
      hari: "Senin",
      waktu: "14:00-16:30",
      peserta: "28"
    },
    {
      id: 733,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE222",
      nama_mk: "DASAR ELEKTRONIKA + LAB",
      kelas: "13",
      nama_dosen: "Yunidar, S.Si.,MT",
      nip: "197406292000032001",
      ruang: "A23 - 206",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "30"
    },
    {
      id: 734,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE222",
      nama_mk: "DASAR ELEKTRONIKA + LAB",
      kelas: "14",
      nama_dosen: "Zulfikar, S.T., M.Sc",
      nip: "197507202006041003",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "9"
    },
    {
      id: 735,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE302",
      nama_mk: "METODE PENELITIAN",
      kelas: "11",
      nama_dosen: "Roslidar, ST., M.Sc",
      nip: "197807192002122002",
      ruang: "RKTE 3",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "23"
    },
    {
      id: 736,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE302",
      nama_mk: "METODE PENELITIAN",
      kelas: "12",
      nama_dosen: "Dr. Ira Devi Sara, ST., M. Eng.Sc.",
      nip: "197705252001122001",
      ruang: "RKTE 3",
      hari: "Selasa",
      waktu: "10:45-12:25",
      peserta: "30"
    },
    {
      id: 737,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE302",
      nama_mk: "METODE PENELITIAN",
      kelas: "13",
      nama_dosen: "Dr. Melinda, ST., M.Sc",
      nip: "197906102002122001",
      ruang: "RKTE 1",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "12"
    },
    {
      id: 738,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE302",
      nama_mk: "METODE PENELITIAN",
      kelas: "14",
      nama_dosen: "Roslidar, ST., M.Sc",
      nip: "197807192002122002",
      ruang: "Daring",
      hari: "Selasa",
      waktu: "10:45-13:15",
      peserta: "1"
    },
    {
      id: 739,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE302",
      nama_mk: "METODE PENELITIAN",
      kelas: "15",
      nama_dosen: "Dr. Melinda, ST., M.Sc",
      nip: "197906102002122001",
      ruang: "RKTE 1",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "0"
    },
    {
      id: 740,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE502",
      nama_mk: "ELEKTRONIKA DAYA + LAB",
      kelas: "11",
      nama_dosen: "Dr. Melinda, ST., M.Sc",
      nip: "197906102002122001",
      ruang: "RKTE",
      hari: "Senin",
      waktu: "14:15-16:15",
      peserta: "11"
    },
    {
      id: 741,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE504",
      nama_mk: "TEKNIK TEGANGAN TINGGI",
      kelas: "12",
      nama_dosen: "Dr. Rakhmad Syafutra Lubis, S.T., M.T.",
      nip: "196901051999031001",
      ruang: "RKTE 3",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "11"
    },
    {
      id: 742,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE506",
      nama_mk: "PROTEKSI SISTEM TENAGA DAN GIS",
      kelas: "11",
      nama_dosen: "Syukriyadin, S.T., M.T.",
      nip: "197108201999031004",
      ruang: "RKTE 1",
      hari: "Jumat",
      waktu: "14:00-16:30",
      peserta: "6"
    },
    {
      id: 743,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE508",
      nama_mk: "SISTEM SCADA + LAB",
      kelas: "11",
      nama_dosen: "Ir. Syahrizal, M.T",
      nip: "196709151995121002",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "14:00-16:30",
      peserta: "14"
    },
    {
      id: 744,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE510",
      nama_mk: "SISTEM DISTRIBUSI TENAGA LISTRIK + LAB",
      kelas: "11",
      nama_dosen: "Ir. Mansur Gapy, M.T.",
      nip: "195810041986031001",
      ruang: "A23-206",
      hari: "Kamis",
      waktu: "8:00-10:30",
      peserta: "-1"
    },
    {
      id: 745,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE512",
      nama_mk: "TEKNIK ENERGI TERBARUKAN + LAB",
      kelas: "11",
      nama_dosen: "Dr. Ira Devi Sara, ST., M. Eng.Sc.",
      nip: "197705252001122001",
      ruang: "A14 - 301",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "15"
    },
    {
      id: 746,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE514",
      nama_mk: "OPTOELEKTRONIKA + LAB",
      kelas: "11",
      nama_dosen: "Yunidar, S.Si.,MT",
      nip: "197406292000032001",
      ruang: "RKTE 1",
      hari: "Sabtu",
      waktu: "10:45-12:25",
      peserta: "0"
    },
    {
      id: 747,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE516",
      nama_mk: "PERANCANGAN VLSI + LAB",
      kelas: "11",
      nama_dosen: "Zulfikar, S.T., M.Sc",
      nip: "197507202006041003",
      ruang: "RKTE 1",
      hari: "Jumat",
      waktu: "14:00-16:30",
      peserta: "0"
    },
    {
      id: 748,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE518",
      nama_mk: "INSTRUMENTASI ELEKTRONIKA + LAB",
      kelas: "11",
      nama_dosen: "Ir. Agus Adria, M.Sc.",
      nip: "196308131990021003",
      ruang: "RKTE 2",
      hari: "Rabu",
      waktu: "14:00-15:40",
      peserta: "0"
    },
    {
      id: 749,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE520",
      nama_mk: "PERANCANGAN SISTEM ELEKTRONIKA",
      kelas: "11",
      nama_dosen: "Ir. Agus Adria, M.Sc.",
      nip: "196308131990021003",
      ruang: "RKTE 3",
      hari: "Rabu",
      waktu: "10:45-13:15",
      peserta: "0"
    },
    {
      id: 750,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE524",
      nama_mk: "TEORI INFORMASI",
      kelas: "11",
      nama_dosen: "Prof. Dr. Nasaruddin, S.T.,M.Eng.",
      nip: "197404021999031003",
      ruang: "RKTE 2",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "18"
    },
    {
      id: 751,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE526",
      nama_mk: "SISTEM DAN TEKNOLOGI KOMUNIKASI OPTIK + LAB",
      kelas: "11",
      nama_dosen: "Prof. Dr. Nasaruddin, S.T.,M.Eng.",
      nip: "197404021999031003",
      ruang: "A14 - 302",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "13"
    },
    {
      id: 752,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE528",
      nama_mk: "TRANSMISI TELEKOMUNIKASI MULTIMEDIA",
      kelas: "11",
      nama_dosen: "Dr. Ir. Syahrial, M.Eng",
      nip: "196401121992031003",
      ruang: "RKTE 3",
      hari: "Kamis",
      waktu: "8:00-10:40",
      peserta: "15"
    },
    {
      id: 753,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE530",
      nama_mk: "SISTEM KOMUNIKASI MULTIMEDIA + LAB",
      kelas: "11",
      nama_dosen: "Prof. Dr. Teuku Yuliar Arif, S.T, M.Kom",
      nip: "197307031999031003",
      ruang: "A14 - 301",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "21"
    },
    {
      id: 754,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE532",
      nama_mk: "JARINGAN SATELIT DAN PENGINDERAAN JAUH",
      kelas: "11",
      nama_dosen: "Dr. Ir. Rizal Munadi, M.M., MT",
      nip: "196708151993031005",
      ruang: "A14 - 302",
      hari: "Selasa",
      waktu: "14:00-16:30",
      peserta: "18"
    },
    {
      id: 755,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE534",
      nama_mk: "MIKROKONTROLER DAN APLIKASINYA + LAB",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Yuwaldi Away, M.Sc",
      nip: "196412061990021001",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "16:35-18:15",
      peserta: "18"
    },
    {
      id: 756,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE536",
      nama_mk: "SISTEM KENDALI LOGIKA FUZZY",
      kelas: "11",
      nama_dosen: "Alfatirta Mufti, S.T, M.Sc",
      nip: "198003062005011002",
      ruang: "RKTE 2",
      hari: "Senin",
      waktu: "8:00-10:40",
      peserta: "15"
    },
    {
      id: 757,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE538",
      nama_mk: "SISTEM KENDALI VARIABEL JAMAK",
      kelas: "11",
      nama_dosen: "Ir. Fahri Heltha, M.Eng",
      nip: "196309161990021001",
      ruang: "RKTE 1",
      hari: "Rabu",
      waktu: "14:00-16:30",
      peserta: "17"
    },
    {
      id: 758,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE540",
      nama_mk: "SISTEM KENDALI PROSES",
      kelas: "11",
      nama_dosen: "Alfatirta Mufti, S.T, M.Sc",
      nip: "198003062005011002",
      ruang: "RKTE 2",
      hari: "Jumat",
      waktu: "14:00-16:30",
      peserta: "18"
    },
    {
      id: 759,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLE542",
      nama_mk: "PENGOLAHAN CITRA DIGITAL + LAB",
      kelas: "11",
      nama_dosen: "Prof. Dr. Fitri Arnia, S.T., M.Eng.Sc",
      nip: "197311121999032001",
      ruang: "RKTE 3",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "21"
    },
    {
      id: 760,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLEP02",
      nama_mk: "ELECTRICAL ENGINEERING DESIGN + LAB",
      kelas: "11",
      nama_dosen: "Dr. Tarmizi, ST., M.Sc",
      nip: "197202011999031002",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "9:45-10:50",
      peserta: "30"
    },
    {
      id: 761,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLEP02",
      nama_mk: "ELECTRICAL ENGINEERING DESIGN + LAB",
      kelas: "12",
      nama_dosen: "Hafidh, S.T., MT",
      nip: "197205021999031003",
      ruang: "RKTE 2",
      hari: "Senin",
      waktu: "8:00-8:50",
      peserta: "25"
    },
    {
      id: 762,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLEP02",
      nama_mk: "ELECTRICAL ENGINEERING DESIGN + LAB",
      kelas: "13",
      nama_dosen: "Dr. Tarmizi, ST., M.Sc",
      nip: "197202011999031002",
      ruang: "RKTE 1",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "29"
    },
    {
      id: 763,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLEP04",
      nama_mk: "KERJA PRAKTEK",
      kelas: "11",
      nama_dosen: "Dr. Ramzi Adriman, S.T, M. Sc",
      nip: "197901302005011001",
      ruang: "RKTE 2",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "53"
    },
    {
      id: 764,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLEPA1",
      nama_mk: "TUGAS AKHIR I",
      kelas: "11",
      nama_dosen: "Dr. Melinda, ST., M.Sc",
      nip: "197906102002122001",
      ruang: "RKTE 3",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "119"
    },
    {
      id: 765,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410501",
      nama_prodi: "Teknik Elektro",
      kode_mk: "TLEPA2",
      nama_mk: "TUGAS AKHIR II",
      kelas: "11",
      nama_dosen: "Dr. Melinda, ST., M.Sc",
      nip: "197906102002122001",
      ruang: "RKTE 3",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "129"
    },
    {
      id: 766,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS102",
      nama_mk: "KALKULUS  II",
      kelas: "11",
      nama_dosen: "Dr.-Ing. Rudi Kurniawan, ST, M. Sc",
      nip: "197910132003121002",
      ruang: "RB - 4A",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "24"
    },
    {
      id: 767,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS102",
      nama_mk: "KALKULUS  II",
      kelas: "12",
      nama_dosen: "Ratna Sary, ST, MT",
      nip: "197811082006042002",
      ruang: "RB - 5A",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "27"
    },
    {
      id: 768,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS102",
      nama_mk: "KALKULUS  II",
      kelas: "13",
      nama_dosen: "Dr. Zahrul Fuadi, S.T.,M.Sc",
      nip: "197110061997021001",
      ruang: "RB - 6A",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "27"
    },
    {
      id: 769,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS102",
      nama_mk: "KALKULUS  II",
      kelas: "14",
      nama_dosen: "Dr. M. Nizar Machmud, S.T., M.Eng",
      nip: "197203201998021001",
      ruang: "A25 - 201",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "29"
    },
    {
      id: 770,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS104",
      nama_mk: "FISIKA DASAR II + PRAKTIKUM",
      kelas: "11",
      nama_dosen: "Dr. Ir. Adi Rahwanto, M.Eng.Sc",
      nip: "196008091987031003",
      ruang: "RB - 4A",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "24"
    },
    {
      id: 771,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS104",
      nama_mk: "FISIKA DASAR II + PRAKTIKUM",
      kelas: "12",
      nama_dosen: "Malahayati, S.Si., M.Si",
      nip: "197407252000122001",
      ruang: "RB - 5A",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 772,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS104",
      nama_mk: "FISIKA DASAR II + PRAKTIKUM",
      kelas: "13",
      nama_dosen: "Fadhli, S.Si., M.Si.",
      nip: "197107031997021003",
      ruang: "RB - 6A",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "30"
    },
    {
      id: 773,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS104",
      nama_mk: "FISIKA DASAR II + PRAKTIKUM",
      kelas: "14",
      nama_dosen: "Fauzi, S.Si, M.Si",
      nip: "196609281994031014",
      ruang: "A25-201",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "24"
    },
    {
      id: 774,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS106",
      nama_mk: "METALURGI FISIK + PRAKTIKUM",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Husaini, M.T., IPU",
      nip: "196108081988111001",
      ruang: "RB - 4A",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "26"
    },
    {
      id: 775,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS106",
      nama_mk: "METALURGI FISIK + PRAKTIKUM",
      kelas: "12",
      nama_dosen: "Dr. Syifaul Huzni, ST., M.Sc",
      nip: "196910091997021001",
      ruang: "RB - 5A",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 776,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS106",
      nama_mk: "METALURGI FISIK + PRAKTIKUM",
      kelas: "13",
      nama_dosen: "Prof. Dr. Ir. Samsul Rizal, M.Eng., IPU., ASEAN.En",
      nip: "196208081988031003",
      ruang: "RB - 6A",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "23"
    },
    {
      id: 777,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS106",
      nama_mk: "METALURGI FISIK + PRAKTIKUM",
      kelas: "14",
      nama_dosen: "Dr. Syarizal Fonna, ST, M.Sc",
      nip: "197810272008121001",
      ruang: "A25-201",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "29"
    },
    {
      id: 778,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS108",
      nama_mk: "MENGGAMBAR TEKNIK MESIN DAN CAD + PRAKTIKUM",
      kelas: "11",
      nama_dosen: "Ir. Asbar R., M.T.",
      nip: "195705151984031005",
      ruang: "LAB KONSTRUKSI & PER",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "26"
    },
    {
      id: 779,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS108",
      nama_mk: "MENGGAMBAR TEKNIK MESIN DAN CAD + PRAKTIKUM",
      kelas: "12",
      nama_dosen: "Dr. Irwansyah, ST, M.Eng",
      nip: "197609182002121004",
      ruang: "RB - 5A",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "30"
    },
    {
      id: 780,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS108",
      nama_mk: "MENGGAMBAR TEKNIK MESIN DAN CAD + PRAKTIKUM",
      kelas: "13",
      nama_dosen: "Amir Zaki Mubarak, S.T, M. Sc",
      nip: "197910122006041002",
      ruang: "RB - 6A",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "30"
    },
    {
      id: 781,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS108",
      nama_mk: "MENGGAMBAR TEKNIK MESIN DAN CAD + PRAKTIKUM",
      kelas: "14",
      nama_dosen: "Ir. Zulfadhli, ST, M.T., IPM",
      nip: "196908151997021001",
      ruang: "A25-201",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "27"
    },
    {
      id: 782,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS110",
      nama_mk: "STATIKA STRUKTUR",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Husaini, M.T., IPU",
      nip: "196108081988111001",
      ruang: "RB 4A",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "40"
    },
    {
      id: 783,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS110",
      nama_mk: "STATIKA STRUKTUR",
      kelas: "12",
      nama_dosen: "Dr. M. Nizar Machmud, S.T., M.Eng",
      nip: "197203201998021001",
      ruang: "RB - 5A",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "38"
    },
    {
      id: 784,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS110",
      nama_mk: "STATIKA STRUKTUR",
      kelas: "13",
      nama_dosen: "Dr. Arhami, S.T., M.T.",
      nip: "197205271998021001",
      ruang: "RB - 6A",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "40"
    },
    {
      id: 785,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS110",
      nama_mk: "STATIKA STRUKTUR",
      kelas: "14",
      nama_dosen: "Ir. Zulfadhli, ST, M.T., IPM",
      nip: "196908151997021001",
      ruang: "A25-201",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "40"
    },
    {
      id: 786,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS110",
      nama_mk: "STATIKA STRUKTUR",
      kelas: "15",
      nama_dosen: "Amir Zaki Mubarak, S.T, M. Sc",
      nip: "197910122006041002",
      ruang: "PRODI TM",
      hari: "Kamis",
      waktu: "16:35-18:15",
      peserta: "40"
    },
    {
      id: 787,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS202",
      nama_mk: "MATEMATIKA II",
      kelas: "11",
      nama_dosen: "Ir. Jalaluddin, M.T.",
      nip: "195807311987101001",
      ruang: "LAB MOTOR BAKAR",
      hari: "Selasa",
      waktu: "10:45-12:25",
      peserta: "19"
    },
    {
      id: 788,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS202",
      nama_mk: "MATEMATIKA II",
      kelas: "12",
      nama_dosen: "Dr. Muhammad Ilham Maulana, ST, MT",
      nip: "197105011998021003",
      ruang: "RB - 5A",
      hari: "Selasa",
      waktu: "10:45-12:25",
      peserta: "30"
    },
    {
      id: 789,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS202",
      nama_mk: "MATEMATIKA II",
      kelas: "13",
      nama_dosen: "Dr. Zahrul Fuadi, S.T.,M.Sc",
      nip: "197110061997021001",
      ruang: "RB - 6A",
      hari: "Selasa",
      waktu: "10:45-12:25",
      peserta: "30"
    },
    {
      id: 790,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS202",
      nama_mk: "MATEMATIKA II",
      kelas: "14",
      nama_dosen: "Dr.-Ing. Rudi Kurniawan, ST, M. Sc",
      nip: "197910132003121002",
      ruang: "A25-201",
      hari: "Selasa",
      waktu: "10:45-12:25",
      peserta: "27"
    },
    {
      id: 791,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS204",
      nama_mk: "PERPINDAHAN PANAS DASAR",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Ahmad Syuhada, M.Sc",
      nip: "196108201987031002",
      ruang: "LAB THERMAL",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "35"
    },
    {
      id: 792,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS204",
      nama_mk: "PERPINDAHAN PANAS DASAR",
      kelas: "12",
      nama_dosen: "Ratna Sary, ST, MT",
      nip: "197811082006042002",
      ruang: "A25-201",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "37"
    },
    {
      id: 793,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS204",
      nama_mk: "PERPINDAHAN PANAS DASAR",
      kelas: "13",
      nama_dosen: "Ir. Jalaluddin, M.T.",
      nip: "195807311987101001",
      ruang: "LAB MOTOR BAKAR",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "28"
    },
    {
      id: 794,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS206",
      nama_mk: "TERMODINAMIKA TEKNIK II",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Khairil, M.T.",
      nip: "196610261990031001",
      ruang: "LAB MOTOR BAKAR",
      hari: "Rabu",
      waktu: "14:00-16:30",
      peserta: "36"
    },
    {
      id: 795,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS206",
      nama_mk: "TERMODINAMIKA TEKNIK II",
      kelas: "12",
      nama_dosen: "Ir. Jalaluddin, M.T.",
      nip: "195807311987101001",
      ruang: "RB - 5A",
      hari: "Rabu",
      waktu: "14:00-16:30",
      peserta: "28"
    },
    {
      id: 796,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS206",
      nama_mk: "TERMODINAMIKA TEKNIK II",
      kelas: "13",
      nama_dosen: "Prof. Dr. Ir. Razali, M.Si., M.T.",
      nip: "196505051991021001",
      ruang: "RB - 6A",
      hari: "Rabu",
      waktu: "14:00-16:30",
      peserta: "35"
    },
    {
      id: 797,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS208",
      nama_mk: "MEKANIKA FLUIDA I",
      kelas: "11",
      nama_dosen: "Dr. Muhammad Ilham Maulana, ST, MT",
      nip: "197105011998021003",
      ruang: "RB - 6A",
      hari: "Jumat",
      waktu: "14:00-16:30",
      peserta: "29"
    },
    {
      id: 798,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS208",
      nama_mk: "MEKANIKA FLUIDA I",
      kelas: "12",
      nama_dosen: "Prof. Dr. Ir. Ahmad Syuhada, M.Sc",
      nip: "196108201987031002",
      ruang: "LAB MOTOR BAKAR",
      hari: "Jumat",
      waktu: "14:00-16:30",
      peserta: "35"
    },
    {
      id: 799,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS208",
      nama_mk: "MEKANIKA FLUIDA I",
      kelas: "13",
      nama_dosen: "Dr. Sarwo Edhy S, S.T, M.Eng",
      nip: "198106262006041005",
      ruang: "RK EKSTENSI 2",
      hari: "Jumat",
      waktu: "14:00-16:30",
      peserta: "35"
    },
    {
      id: 800,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS210",
      nama_mk: "PROSES MANUFAKTUR II + PRAKTIKUM",
      kelas: "11",
      nama_dosen: "Dr. Ir. Husni, M.Eng.Sc",
      nip: "196505061992031002",
      ruang: "RB - 4A",
      hari: "Senin",
      waktu: "14:00-16:30",
      peserta: "30"
    },
    {
      id: 801,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS210",
      nama_mk: "PROSES MANUFAKTUR II + PRAKTIKUM",
      kelas: "12",
      nama_dosen: "Ir. Suhaeri, M.Eng",
      nip: "196007151990021001",
      ruang: "RB - 5A",
      hari: "Senin",
      waktu: "14:00-16:30",
      peserta: "21"
    },
    {
      id: 802,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS210",
      nama_mk: "PROSES MANUFAKTUR II + PRAKTIKUM",
      kelas: "13",
      nama_dosen: "Ir. Udink Aulia, M.Eng.",
      nip: "196605231993031003",
      ruang: "RB - 6A",
      hari: "Senin",
      waktu: "14:00-16:30",
      peserta: "29"
    },
    {
      id: 803,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS210",
      nama_mk: "PROSES MANUFAKTUR II + PRAKTIKUM",
      kelas: "14",
      nama_dosen: "Dr. Muhammad Rizal, S.T, M.Sc",
      nip: "197910192006041003",
      ruang: "A25-201",
      hari: "Senin",
      waktu: "14:00-16:30",
      peserta: "28"
    },
    {
      id: 804,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS212",
      nama_mk: "ELEMEN MESIN I",
      kelas: "11",
      nama_dosen: "Ir. Asbar R., M.T.",
      nip: "195705151984031005",
      ruang: "A23 - 204",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 805,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS212",
      nama_mk: "ELEMEN MESIN I",
      kelas: "12",
      nama_dosen: "Prof. Dr. Ir. Husaini, M.T., IPU",
      nip: "196108081988111001",
      ruang: "RK - MTM",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "30"
    },
    {
      id: 806,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS212",
      nama_mk: "ELEMEN MESIN I",
      kelas: "13",
      nama_dosen: "Dr. Arhami, S.T., M.T.",
      nip: "197205271998021001",
      ruang: "RKTM 1",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "26"
    },
    {
      id: 807,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS212",
      nama_mk: "ELEMEN MESIN I",
      kelas: "14",
      nama_dosen: "Dr. Irwansyah, ST, M.Eng",
      nip: "197609182002121004",
      ruang: "LAB KONSTRUKSI & PER",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 808,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS214",
      nama_mk: "PENGUKURAN TEKNIK",
      kelas: "11",
      nama_dosen: "Ir. Suhaeri, M.Eng",
      nip: "196007151990021001",
      ruang: "RB- 4A",
      hari: "Selasa",
      waktu: "14:00-16:30",
      peserta: "28"
    },
    {
      id: 809,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS214",
      nama_mk: "PENGUKURAN TEKNIK",
      kelas: "12",
      nama_dosen: "Dr. Muhammad Rizal, S.T, M.Sc",
      nip: "197910192006041003",
      ruang: "RB- 5A",
      hari: "Selasa",
      waktu: "14:00-16:30",
      peserta: "25"
    },
    {
      id: 810,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS214",
      nama_mk: "PENGUKURAN TEKNIK",
      kelas: "13",
      nama_dosen: "Dr. Arhami, S.T., M.T.",
      nip: "197205271998021001",
      ruang: "RB- 6A",
      hari: "Selasa",
      waktu: "14:00-16:30",
      peserta: "24"
    },
    {
      id: 811,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS214",
      nama_mk: "PENGUKURAN TEKNIK",
      kelas: "14",
      nama_dosen: "Dr. Irwansyah, ST, M.Eng",
      nip: "197609182002121004",
      ruang: "A25 -201",
      hari: "Selasa",
      waktu: "14:00-16:30",
      peserta: "24"
    },
    {
      id: 812,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS302",
      nama_mk: "DINAMIKA TEKNIK",
      kelas: "11",
      nama_dosen: "Ir. Asbar R., M.T.",
      nip: "195705151984031005",
      ruang: "LAB KONSTRUKSI & PER",
      hari: "Kamis",
      waktu: "14:00-16:30",
      peserta: "19"
    },
    {
      id: 813,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS302",
      nama_mk: "DINAMIKA TEKNIK",
      kelas: "12",
      nama_dosen: "Sabri, ST, MT",
      nip: "197205021998021001",
      ruang: "RB - 6A",
      hari: "Kamis",
      waktu: "14:00-16:30",
      peserta: "25"
    },
    {
      id: 814,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS302",
      nama_mk: "DINAMIKA TEKNIK",
      kelas: "13",
      nama_dosen: "Prof. Dr. Ir. Razali, M.Si., M.T.",
      nip: "196505051991021001",
      ruang: "A25-201",
      hari: "Kamis",
      waktu: "14:00-16:30",
      peserta: "28"
    },
    {
      id: 815,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS302",
      nama_mk: "DINAMIKA TEKNIK",
      kelas: "14",
      nama_dosen: "Dr.-Ing. Rudi Kurniawan, ST, M. Sc",
      nip: "197910132003121002",
      ruang: "RK EKSTENSI 2",
      hari: "Kamis",
      waktu: "14:00-16:30",
      peserta: "22"
    },
    {
      id: 816,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS304",
      nama_mk: "GETARAN MEKANIK",
      kelas: "11",
      nama_dosen: "Sabri, ST, MT",
      nip: "197205021998021001",
      ruang: "RK - EKSTENSI 2",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "8"
    },
    {
      id: 817,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS304",
      nama_mk: "GETARAN MEKANIK",
      kelas: "12",
      nama_dosen: "Dr. Zahrul Fuadi, S.T.,M.Sc",
      nip: "197110061997021001",
      ruang: "A23-204",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "23"
    },
    {
      id: 818,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS304",
      nama_mk: "GETARAN MEKANIK",
      kelas: "13",
      nama_dosen: "Dr.-Ing., Ir. Teuku Edisah Putra, S.T, M.Sc., Ph.D",
      nip: "198005072006041004",
      ruang: "RK- MTM",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 819,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS304",
      nama_mk: "GETARAN MEKANIK",
      kelas: "14",
      nama_dosen: "Dr. Muhammad Rizal, S.T, M.Sc",
      nip: "197910192006041003",
      ruang: "RKTM 1",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "24"
    },
    {
      id: 820,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS306",
      nama_mk: "MESIN KONVERSI ENERGI II",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Khairil, M.T.",
      nip: "196610261990031001",
      ruang: "RK EKSTENSI 2",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "31"
    },
    {
      id: 821,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS306",
      nama_mk: "MESIN KONVERSI ENERGI II",
      kelas: "12",
      nama_dosen: "Prof. Dr. Ir. Ahmad Syuhada, M.Sc",
      nip: "196108201987031002",
      ruang: "A23-204",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "31"
    },
    {
      id: 822,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS306",
      nama_mk: "MESIN KONVERSI ENERGI II",
      kelas: "13",
      nama_dosen: "Ir. Jalaluddin, M.T.",
      nip: "195807311987101001",
      ruang: "LAB MOTOR BAKAR",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "5"
    },
    {
      id: 823,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS308",
      nama_mk: "SISTEM KENDALI",
      kelas: "11",
      nama_dosen: "Ir. Suhaeri, M.Eng",
      nip: "196007151990021001",
      ruang: "A25-201",
      hari: "Rabu",
      waktu: "14:00-16:30",
      peserta: "11"
    },
    {
      id: 824,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS308",
      nama_mk: "SISTEM KENDALI",
      kelas: "12",
      nama_dosen: "Muhammad Tadjuddin, ST, M.Eng.Sc",
      nip: "197105231998021001",
      ruang: "RK EKSTENSI 2",
      hari: "Rabu",
      waktu: "14:00-16:30",
      peserta: "30"
    },
    {
      id: 825,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS308",
      nama_mk: "SISTEM KENDALI",
      kelas: "13",
      nama_dosen: "Dr. Muhammad Rizal, S.T, M.Sc",
      nip: "197910192006041003",
      ruang: "A23-204",
      hari: "Rabu",
      waktu: "14:00-16:30",
      peserta: "6"
    },
    {
      id: 826,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS310",
      nama_mk: "PRAKTIKUM FENOMENA DASAR MESIN",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Ahmad Syuhada, M.Sc",
      nip: "196108201987031002",
      ruang: "LAB THERMAL",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "75"
    },
    {
      id: 827,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS402",
      nama_mk: "MANAJEMEN INDUSTRI",
      kelas: "11",
      nama_dosen: "Dr. Ir. Mohd. Iqbal, M.T.",
      nip: "196706081994031003",
      ruang: "RB - 5A",
      hari: "Jumat",
      waktu: "10:45-12:20",
      peserta: "35"
    },
    {
      id: 828,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS402",
      nama_mk: "MANAJEMEN INDUSTRI",
      kelas: "12",
      nama_dosen: "Dr. Ir. M. Dirhamsyah, M.T., IPU",
      nip: "196210021988111001",
      ruang: "RB - 6A",
      hari: "Sabtu",
      waktu: "10:45-12:25",
      peserta: "37"
    },
    {
      id: 829,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMS402",
      nama_mk: "MANAJEMEN INDUSTRI",
      kelas: "13",
      nama_dosen: "Dr. Ir. Iskandar, ST.M.Eng.Sc., IPM., ASEAN Eng",
      nip: "197303041998021002",
      ruang: "RKTM 2",
      hari: "Jumat",
      waktu: "10:30-12:20",
      peserta: "36"
    },
    {
      id: 830,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP01",
      nama_mk: "TUGAS RANCANG PRODUK REKAYASA",
      kelas: "11",
      nama_dosen: "Dr. Arhami, S.T., M.T.",
      nip: "197205271998021001",
      ruang: "LAB",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "102"
    },
    {
      id: 831,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP02",
      nama_mk: "TUGAS PURWARUPA PRODUK REKAYASA",
      kelas: "11",
      nama_dosen: "Dr. Arhami, S.T., M.T.",
      nip: "197205271998021001",
      ruang: "LAB",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "1"
    },
    {
      id: 832,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP03",
      nama_mk: "KERJA PRAKTEK (KP)",
      kelas: "11",
      nama_dosen: "Amir Zaki Mubarak, S.T, M. Sc",
      nip: "197910122006041002",
      ruang: "LAB",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "71"
    },
    {
      id: 833,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP04",
      nama_mk: "PERPINDAHAN PANAS LANJUT",
      kelas: "11",
      nama_dosen: "Ir. Jalaluddin, M.T.",
      nip: "195807311987101001",
      ruang: "LAB MOTOR BAKAR",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "23"
    },
    {
      id: 834,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP04",
      nama_mk: "PERPINDAHAN PANAS LANJUT",
      kelas: "12",
      nama_dosen: "Ir. Jalaluddin, M.T.",
      nip: "195807311987101001",
      ruang: "LAB MOTOR BAKAR",
      hari: "Senin",
      waktu: "14:00-16:30",
      peserta: "22"
    },
    {
      id: 835,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP06",
      nama_mk: "KONSEP DESAIN",
      kelas: "11",
      nama_dosen: "Dr. Irwansyah, ST, M.Eng",
      nip: "197609182002121004",
      ruang: "A23-204",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "13"
    },
    {
      id: 836,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP08",
      nama_mk: "METALOGRAFI DAN KARAKTERISASI MATERIAL",
      kelas: "11",
      nama_dosen: "Dr. Ir. Sulaiman Thalib, M.T.",
      nip: "196102101990021001",
      ruang: "RK. MTM",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "8"
    },
    {
      id: 837,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP10",
      nama_mk: "PROSES PEMESINAN",
      kelas: "11",
      nama_dosen: "Ir. Udink Aulia, M.Eng.",
      nip: "196605231993031003",
      ruang: "RKTM 1",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "33"
    },
    {
      id: 838,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP12",
      nama_mk: "MOTOR BAKAR",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Khairil, M.T.",
      nip: "196610261990031001",
      ruang: "LAB MOTOR BAKAR",
      hari: "Kamis",
      waktu: "8:00-10:30",
      peserta: "14"
    },
    {
      id: 839,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP14",
      nama_mk: "TEKNIK PENDINGIN",
      kelas: "11",
      nama_dosen: "Dr. Muhammad Ilham Maulana, ST, MT",
      nip: "197105011998021003",
      ruang: "A23-204",
      hari: "Kamis",
      waktu: "14:00-16:30",
      peserta: "6"
    },
    {
      id: 840,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP16",
      nama_mk: "MEKANIKA FLUIDA LANJUT",
      kelas: "11",
      nama_dosen: "Ratna Sary, ST, MT",
      nip: "197811082006042002",
      ruang: "A23-204",
      hari: "Kamis",
      waktu: "10:45-12:25",
      peserta: "1"
    },
    {
      id: 841,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP18",
      nama_mk: "PEMBANGKIT TENAGA UAP",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Ahmad Syuhada, M.Sc",
      nip: "196108201987031002",
      ruang: "LAB THERMAL",
      hari: "Selasa",
      waktu: "14:00-16:30",
      peserta: "15"
    },
    {
      id: 842,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP20",
      nama_mk: "AERODINAMIKA",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Ahmad Syuhada, M.Sc",
      nip: "196108201987031002",
      ruang: "LAB THERMAL",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "3"
    },
    {
      id: 843,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP22",
      nama_mk: "TEKNIK PENGERING",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Ahmad Syuhada, M.Sc",
      nip: "196108201987031002",
      ruang: "LAB THERMAL",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "3"
    },
    {
      id: 844,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP24",
      nama_mk: "METODE MATRIKS DALAM ANALISIS STRUKTUR",
      kelas: "11",
      nama_dosen: "Dr. Syifaul Huzni, ST., M.Sc",
      nip: "196910091997021001",
      ruang: "RKTM 1",
      hari: "Kamis",
      waktu: "14:00-16:30",
      peserta: "7"
    },
    {
      id: 845,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP26",
      nama_mk: "KONSTRUKSI SISTEM PEMIPAAN",
      kelas: "11",
      nama_dosen: "Ir. Asbar R., M.T.",
      nip: "195705151984031005",
      ruang: "LAB KONSTRUKSI & PER",
      hari: "Rabu",
      waktu: "16:35-18:20",
      peserta: "5"
    },
    {
      id: 846,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP28",
      nama_mk: "TEKNIK KENDERAAN",
      kelas: "11",
      nama_dosen: "Sabri, ST, MT",
      nip: "197205021998021001",
      ruang: "RKTM 1",
      hari: "Rabu",
      waktu: "10:45-12:25",
      peserta: "0"
    },
    {
      id: 847,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP30",
      nama_mk: "PEMILIHAN MATERIAL & PROSES MANUFAKTUR",
      kelas: "11",
      nama_dosen: "Dr. Syifaul Huzni, ST., M.Sc",
      nip: "196910091997021001",
      ruang: "RKTM 1",
      hari: "Rabu",
      waktu: "16:35-18:20",
      peserta: "19"
    },
    {
      id: 848,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP32",
      nama_mk: "MATERIAL KERAMIK",
      kelas: "11",
      nama_dosen: "Dr. Syarizal Fonna, ST, M.Sc",
      nip: "197810272008121001",
      ruang: "RKTM 1",
      hari: "Kamis",
      waktu: "8:00-10:30",
      peserta: "2"
    },
    {
      id: 849,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP34",
      nama_mk: "TEKNIK PENGECORAN",
      kelas: "11",
      nama_dosen: "Dr.Ir. Akhyar, ST., MP., M.Eng., IPM., ASEAN Eng",
      nip: "198006152006041004",
      ruang: "RKTM 1",
      hari: "Jumat",
      waktu: "10:45-12:25",
      peserta: "2"
    },
    {
      id: 850,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP36",
      nama_mk: "PENGETESAN MESIN PERKAKAS",
      kelas: "11",
      nama_dosen: "Ir. Suhaeri, M.Eng",
      nip: "196007151990021001",
      ruang: "A23-204",
      hari: "Selasa",
      waktu: "14:00-16:30",
      peserta: "2"
    },
    {
      id: 851,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP38",
      nama_mk: "PEMROGRAMAN MESIN KONTROL NUMERIK",
      kelas: "11",
      nama_dosen: "Dr. Ir. Husni, M.Eng.Sc",
      nip: "196505061992031002",
      ruang: "RKTM 1",
      hari: "Selasa",
      waktu: "16:30-18:20",
      peserta: "30"
    },
    {
      id: 852,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP40",
      nama_mk: "PERHITUNGAN ONGKOS PRODUKSI",
      kelas: "11",
      nama_dosen: "Dr. Ir. Mohd. Iqbal, M.T.",
      nip: "196706081994031003",
      ruang: "RKTM 1",
      hari: "Jumat",
      waktu: "14:00-16:30",
      peserta: "6"
    },
    {
      id: 853,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP42",
      nama_mk: "PENGENALAN KONTROL KEBISINGAN",
      kelas: "11",
      nama_dosen: "Dr. Zahrul Fuadi, S.T.,M.Sc",
      nip: "197110061997021001",
      ruang: "RKTM 1",
      hari: "Kamis",
      waktu: "10:45-12:20",
      peserta: "0"
    },
    {
      id: 854,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP44",
      nama_mk: "MEKANIKA RETAKAN",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Husaini, M.T., IPU",
      nip: "196108081988111001",
      ruang: "RKTM 1",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "2"
    },
    {
      id: 855,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP46",
      nama_mk: "METODE ELEMEN HINGGA",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Husaini, M.T., IPU",
      nip: "196108081988111001",
      ruang: "RK. MTM",
      hari: "Selasa",
      waktu: "14:00-16:30",
      peserta: "9"
    },
    {
      id: 856,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP48",
      nama_mk: "TEKNIK PENGELASAN",
      kelas: "11",
      nama_dosen: "Dr. Ir. Sulaiman Thalib, M.T.",
      nip: "196102101990021001",
      ruang: "RK EKSTENSI 2",
      hari: "Senin",
      waktu: "16:35-18:20",
      peserta: "14"
    },
    {
      id: 857,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP50",
      nama_mk: "REKAYASA KOROSI",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Nurdin Ali, Dipl.Ing",
      nip: "195706271985031001",
      ruang: "RB- 4A",
      hari: "Senin",
      waktu: "16:35-18:20",
      peserta: "13"
    },
    {
      id: 858,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP52",
      nama_mk: "TEKNOLOGI TEPAT GUNA",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Ahmad Syuhada, M.Sc",
      nip: "196108201987031002",
      ruang: "LAB THERMAL",
      hari: "Kamis",
      waktu: "16:35-18:20",
      peserta: "35"
    },
    {
      id: 859,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP54",
      nama_mk: "TANUR DAN BAHAN BAKAR",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ir. Khairil, M.T.",
      nip: "196610261990031001",
      ruang: "LAB MOTOR BAKAR",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "8"
    },
    {
      id: 860,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSP56",
      nama_mk: "BIO MEKANIK",
      kelas: "11",
      nama_dosen: "Dr. Syifaul Huzni, ST., M.Sc",
      nip: "196910091997021001",
      ruang: "RK MTM",
      hari: "Selasa",
      waktu: "16:35-18:20",
      peserta: "15"
    },
    {
      id: 861,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSPA1",
      nama_mk: "PROPOSAL TUGAS AKHIR",
      kelas: "11",
      nama_dosen: "Ir. Udink Aulia, M.Eng.",
      nip: "196605231993031003",
      ruang: "LAB",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "125"
    },
    {
      id: 862,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410201",
      nama_prodi: "Teknik Mesin",
      kode_mk: "TMSPA2",
      nama_mk: "TUGAS AKHIR",
      kelas: "11",
      nama_dosen: "Ir. Udink Aulia, M.Eng.",
      nip: "196605231993031003",
      ruang: "LAB",
      hari: "Sabtu",
      waktu: "8:00-10:30",
      peserta: "131"
    },
    {
      id: 863,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB102",
      nama_mk: "KALKULUS II",
      kelas: "11",
      nama_dosen: "Dr. Ir. Darmadi, M.T.",
      nip: "196603231993031003",
      ruang: "prodi",
      hari: "Rabu",
      waktu: "10:45-13:15",
      peserta: "25"
    },
    {
      id: 864,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB102",
      nama_mk: "KALKULUS II",
      kelas: "12",
      nama_dosen: "Zuhra, ST., M.Sc",
      nip: "197403181999032001",
      ruang: "prodi",
      hari: "Rabu",
      waktu: "10:45-13:15",
      peserta: "24"
    },
    {
      id: 865,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB102",
      nama_mk: "KALKULUS II",
      kelas: "13",
      nama_dosen: "Izzan Nur Aslam, S.T., M.Eng",
      nip: "199008232020121015",
      ruang: "prodi",
      hari: "Rabu",
      waktu: "10:45-13:15",
      peserta: "25"
    },
    {
      id: 866,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB110",
      nama_mk: "FISIKA DASAR II",
      kelas: "11",
      nama_dosen: "Dr. Rini Safitri, S.Si., M.Si.",
      nip: "197004251995122001",
      ruang: "prodi",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "25"
    },
    {
      id: 867,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB110",
      nama_mk: "FISIKA DASAR II",
      kelas: "12",
      nama_dosen: "Nurul Aflah, ST., M.Sc",
      nip: "198308162012122002",
      ruang: "prodi",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "23"
    },
    {
      id: 868,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB110",
      nama_mk: "FISIKA DASAR II",
      kelas: "13",
      nama_dosen: "Prof. Dr. Abrar Muslim, S.T., M.Eng.",
      nip: "197205251999031002",
      ruang: "prodi",
      hari: "Senin",
      waktu: "8:00-10:30",
      peserta: "21"
    },
    {
      id: 869,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB112",
      nama_mk: "KIMIA DASAR II",
      kelas: "11",
      nama_dosen: "Ir. Pocut Nurul Alam, M.T.",
      nip: "196608221997022002",
      ruang: "prodi",
      hari: "Senin",
      waktu: "8:00-10:45",
      peserta: "23"
    },
    {
      id: 870,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB112",
      nama_mk: "KIMIA DASAR II",
      kelas: "12",
      nama_dosen: "Nurul Aflah, ST., M.Sc",
      nip: "198308162012122002",
      ruang: "prodi",
      hari: "Selasa",
      waktu: "8:00-10:45",
      peserta: "25"
    },
    {
      id: 871,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB112",
      nama_mk: "KIMIA DASAR II",
      kelas: "13",
      nama_dosen: "Izzan Nur Aslam, S.T., M.Eng",
      nip: "199008232020121015",
      ruang: "prodi",
      hari: "Senin",
      waktu: "10:45-13:15",
      peserta: "23"
    },
    {
      id: 872,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB114",
      nama_mk: "KRISTALOGRAFI DAN MINERALOGI",
      kelas: "11",
      nama_dosen: "Dr. Husni, ST, M.Sc., M. Eng",
      nip: "197410282006041002",
      ruang: "prodi",
      hari: "Rabu",
      waktu: "8:00-10:15",
      peserta: "24"
    },
    {
      id: 873,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB114",
      nama_mk: "KRISTALOGRAFI DAN MINERALOGI",
      kelas: "12",
      nama_dosen: "Fahri Adrian, B.Sc., M.Sc",
      nip: "199004292016011101",
      ruang: "prodi",
      hari: "Sabtu",
      waktu: "10:45-13:45",
      peserta: "25"
    },
    {
      id: 874,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB114",
      nama_mk: "KRISTALOGRAFI DAN MINERALOGI",
      kelas: "13",
      nama_dosen: "Izzan Nur Aslam, S.T., M.Eng",
      nip: "199008232020121015",
      ruang: "prodi",
      hari: "Kamis",
      waktu: "10:45-13:45",
      peserta: "20"
    },
    {
      id: 875,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB202",
      nama_mk: "SISTEM INFORMASI GEOGRAFIS",
      kelas: "11",
      nama_dosen: "Haqul Baramsyah, S.T., M.Eng.Sc.",
      nip: "198411282015041002",
      ruang: "prodi",
      hari: "Selasa",
      waktu: "10:45-12:25",
      peserta: "27"
    },
    {
      id: 876,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB202",
      nama_mk: "SISTEM INFORMASI GEOGRAFIS",
      kelas: "12",
      nama_dosen: "Mulkal, ST., M.Sc",
      nip: "198010162012121003",
      ruang: "prodi",
      hari: "Rabu",
      waktu: "10:45-12:25",
      peserta: "14"
    },
    {
      id: 877,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB202",
      nama_mk: "SISTEM INFORMASI GEOGRAFIS",
      kelas: "13",
      nama_dosen: "Haqul Baramsyah, S.T., M.Eng.Sc.",
      nip: "198411282015041002",
      ruang: "prodi",
      hari: "Sabtu",
      waktu: "10:45-12:25",
      peserta: "30"
    },
    {
      id: 878,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB204",
      nama_mk: "TEKTONOFISIK",
      kelas: "11",
      nama_dosen: "Muchlis, S.Si., M.Sc",
      nip: "197912182009121001",
      ruang: "prodi",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "13"
    },
    {
      id: 879,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB204",
      nama_mk: "TEKTONOFISIK",
      kelas: "12",
      nama_dosen: "Dr. Bambang Setiawan, ST, M.Eng.Sc.",
      nip: "197106032006041002",
      ruang: "prodi",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "38"
    },
    {
      id: 880,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB204",
      nama_mk: "TEKTONOFISIK",
      kelas: "13",
      nama_dosen: "Haqul Baramsyah, S.T., M.Eng.Sc.",
      nip: "198411282015041002",
      ruang: "prodi",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "23"
    },
    {
      id: 881,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB208",
      nama_mk: "METODE NUMERIK",
      kelas: "11",
      nama_dosen: "Moehammad Ediyan Raza Karmel, B.Sc(Huns), M.Si.",
      nip: "198902262019031006",
      ruang: "prodi",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "27"
    },
    {
      id: 882,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB208",
      nama_mk: "METODE NUMERIK",
      kelas: "12",
      nama_dosen: "Prof. Dr. Ir. Husni Husin, M.T.",
      nip: "196506011994122001",
      ruang: "prodi",
      hari: "Rabu",
      waktu: "14:00-16:30",
      peserta: "20"
    },
    {
      id: 883,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB208",
      nama_mk: "METODE NUMERIK",
      kelas: "13",
      nama_dosen: "Mulkal, ST., M.Sc",
      nip: "198010162012121003",
      ruang: "prodi",
      hari: "Jumat",
      waktu: "8:00-10:30",
      peserta: "23"
    },
    {
      id: 884,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB210",
      nama_mk: "MEKANIKA BATUAN",
      kelas: "11",
      nama_dosen: "Ibnu Rusydy, S.Si., M.Sc.",
      nip: "198307262014041001",
      ruang: "prodi",
      hari: "Selasa",
      waktu: "8:00-10:30",
      peserta: "15"
    },
    {
      id: 885,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB210",
      nama_mk: "MEKANIKA BATUAN",
      kelas: "12",
      nama_dosen: "Nafisah Al-Huda, S.T., MT.",
      nip: "197901102008122001",
      ruang: "prodi",
      hari: "Rabu",
      waktu: "8:00-10:30",
      peserta: "24"
    },
    {
      id: 886,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB210",
      nama_mk: "MEKANIKA BATUAN",
      kelas: "13",
      nama_dosen: "Dr. Teuku Andika Rama Putra, S.T.,M.Sc",
      nip: "197209251999031002",
      ruang: "prodi",
      hari: "Rabu",
      waktu: "8:00-10:35",
      peserta: "33"
    },
    {
      id: 887,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB218",
      nama_mk: "PENGGERAK MULA",
      kelas: "11",
      nama_dosen: "Nurul Kamal, ST., M.Sc",
      nip: "196903232014011101",
      ruang: "prodi",
      hari: "Senin",
      waktu: "16:30-18:00",
      peserta: "30"
    },
    {
      id: 888,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB218",
      nama_mk: "PENGGERAK MULA",
      kelas: "12",
      nama_dosen: "Ir. Darwin, M.T",
      nip: "195612131991021001",
      ruang: "prodi",
      hari: "Senin",
      waktu: "16:30-18:00",
      peserta: "26"
    },
    {
      id: 889,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB218",
      nama_mk: "PENGGERAK MULA",
      kelas: "13",
      nama_dosen: "Dr. Teuku Andika Rama Putra, S.T.,M.Sc",
      nip: "197209251999031002",
      ruang: "prodi",
      hari: "Senin",
      waktu: "16:30-18:00",
      peserta: "25"
    },
    {
      id: 890,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB220",
      nama_mk: "PENYALIRAN TAMBANG",
      kelas: "11",
      nama_dosen: "Febi Mutia, S.T., M.Sc.",
      nip: "198702042019032011",
      ruang: "prodi",
      hari: "Rabu",
      waktu: "14:00-15:40",
      peserta: "29"
    },
    {
      id: 891,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB220",
      nama_mk: "PENYALIRAN TAMBANG",
      kelas: "12",
      nama_dosen: "Haqul Baramsyah, S.T., M.Eng.Sc.",
      nip: "198411282015041002",
      ruang: "prodi",
      hari: "Rabu",
      waktu: "14:00-15:40",
      peserta: "31"
    },
    {
      id: 892,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB220",
      nama_mk: "PENYALIRAN TAMBANG",
      kelas: "13",
      nama_dosen: "Febi Mutia, S.T., M.Sc.",
      nip: "198702042019032011",
      ruang: "prodi",
      hari: "Rabu",
      waktu: "14:00-15:40",
      peserta: "30"
    },
    {
      id: 893,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB220",
      nama_mk: "PENYALIRAN TAMBANG",
      kelas: "14",
      nama_dosen: "Haqul Baramsyah, S.T., M.Eng.Sc.",
      nip: "198411282015041002",
      ruang: "RB 1A",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "27"
    },
    {
      id: 894,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB222",
      nama_mk: "TAMBANG BAWAH TANAH",
      kelas: "11",
      nama_dosen: "Ir. T. Zulfikar, M.T",
      nip: "195809122017011101",
      ruang: "prodi",
      hari: "Senin",
      waktu: "8:00-10:40",
      peserta: "27"
    },
    {
      id: 895,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB222",
      nama_mk: "TAMBANG BAWAH TANAH",
      kelas: "12",
      nama_dosen: "Dr. Teuku Andika Rama Putra, S.T.,M.Sc",
      nip: "197209251999031002",
      ruang: "prodi",
      hari: "Senin",
      waktu: "8:00-10:40",
      peserta: "25"
    },
    {
      id: 896,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB222",
      nama_mk: "TAMBANG BAWAH TANAH",
      kelas: "13",
      nama_dosen: "Hendra Harisman, S.T., M.Eng.Sc",
      nip: "198311102018031001",
      ruang: "prodi",
      hari: "Senin",
      waktu: "8:00-10:40",
      peserta: "20"
    },
    {
      id: 897,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB224",
      nama_mk: "GENESA BAHAN GALIAN",
      kelas: "11",
      nama_dosen: "Muchlis, S.Si., M.Sc",
      nip: "197912182009121001",
      ruang: "prodi",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "27"
    },
    {
      id: 898,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB224",
      nama_mk: "GENESA BAHAN GALIAN",
      kelas: "12",
      nama_dosen: "Hendra Harisman, S.T., M.Eng.Sc",
      nip: "198311102018031001",
      ruang: "prodi",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "32"
    },
    {
      id: 899,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB224",
      nama_mk: "GENESA BAHAN GALIAN",
      kelas: "13",
      nama_dosen: "Nurul Aflah, ST., M.Sc",
      nip: "198308162012122002",
      ruang: "prodi",
      hari: "Kamis",
      waktu: "8:00-9:40",
      peserta: "36"
    },
    {
      id: 900,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB224",
      nama_mk: "GENESA BAHAN GALIAN",
      kelas: "14",
      nama_dosen: "Haqul Baramsyah, S.T., M.Eng.Sc.",
      nip: "198411282015041002",
      ruang: "RB 1A",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "27"
    },
    {
      id: 901,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB314",
      nama_mk: "MANAJEMEN TAMBANG",
      kelas: "11",
      nama_dosen: "Nurul Kamal, ST., M.Sc",
      nip: "196903232014011101",
      ruang: "prodi",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "32"
    },
    {
      id: 902,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB314",
      nama_mk: "MANAJEMEN TAMBANG",
      kelas: "12",
      nama_dosen: "Nurul Kamal, ST., M.Sc",
      nip: "196903232014011101",
      ruang: "prodi",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "28"
    },
    {
      id: 903,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB314",
      nama_mk: "MANAJEMEN TAMBANG",
      kelas: "13",
      nama_dosen: "Ali Sastromijoyo, S.T., M.T",
      nip: "197501062021011101",
      ruang: "RB 1A",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "28"
    },
    {
      id: 904,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB316",
      nama_mk: "K3 DAN KESELAMATAN PERTAMBANGAN",
      kelas: "11",
      nama_dosen: "Nurul Kamal, ST., M.Sc",
      nip: "196903232014011101",
      ruang: "prodi",
      hari: "Jumat",
      waktu: "16:35-18:00",
      peserta: "12"
    },
    {
      id: 905,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB316",
      nama_mk: "K3 DAN KESELAMATAN PERTAMBANGAN",
      kelas: "12",
      nama_dosen: "Yoessi Oktarini, ST., M.T.",
      nip: "198910102016092101",
      ruang: "prodi",
      hari: "Jumat",
      waktu: "16:35-18:00",
      peserta: "5"
    },
    {
      id: 906,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB318",
      nama_mk: "KULIAH LAPANGAN",
      kelas: "11",
      nama_dosen: "Ir. Pocut Nurul Alam, M.T.",
      nip: "196608221997022002",
      ruang: "prodi",
      hari: "Jumat",
      waktu: "16:35-18:00",
      peserta: "45"
    },
    {
      id: 907,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB510",
      nama_mk: "TEKNOLOGI BATUBARA",
      kelas: "11",
      nama_dosen: "Dr. Husni, ST, M.Sc., M. Eng",
      nip: "197410282006041002",
      ruang: "prodi",
      hari: "Selasa",
      waktu: "10:45-12:45",
      peserta: "28"
    },
    {
      id: 908,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB512",
      nama_mk: "COAL BED METHANE (CBM)",
      kelas: "11",
      nama_dosen: "Hendra Harisman, S.T., M.Eng.Sc",
      nip: "198311102018031001",
      ruang: "prodi",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "-1"
    },
    {
      id: 909,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB514",
      nama_mk: "PERENCANAAN PABRIK PENGOLAHAN MINERAL",
      kelas: "11",
      nama_dosen: "Dr. Husni, ST, M.Sc., M. Eng",
      nip: "197410282006041002",
      ruang: "prodi",
      hari: "Senin",
      waktu: "10:45-12:25",
      peserta: "19"
    },
    {
      id: 910,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB516",
      nama_mk: "KOROSI DALAM INDUSTRI PERTAMBANGAN",
      kelas: "11",
      nama_dosen: "Ir. Pocut Nurul Alam, M.T.",
      nip: "196608221997022002",
      ruang: "prodi",
      hari: "Senin",
      waktu: "14:00-15:40",
      peserta: "1"
    },
    {
      id: 911,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB518",
      nama_mk: "EVALUASI FORMASI",
      kelas: "11",
      nama_dosen: "Muchlis, S.Si., M.Sc",
      nip: "197912182009121001",
      ruang: "prodi",
      hari: "Senin",
      waktu: "16:30-18:00",
      peserta: "-1"
    },
    {
      id: 912,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB522",
      nama_mk: "GEOTHERMAL",
      kelas: "11",
      nama_dosen: "Nurul Aflah, ST., M.Sc",
      nip: "198308162012122002",
      ruang: "prodi",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "29"
    },
    {
      id: 913,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB524",
      nama_mk: "TEKNIK PRODUKSI MIGAS",
      kelas: "11",
      nama_dosen: "Nurul Aflah, ST., M.Sc",
      nip: "198308162012122002",
      ruang: "prodi",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "39"
    },
    {
      id: 914,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB526",
      nama_mk: "ANALISIS DAMPAK LINGKUNGAN",
      kelas: "11",
      nama_dosen: "Dr. Teuku Andika Rama Putra, S.T.,M.Sc",
      nip: "197209251999031002",
      ruang: "prodi",
      hari: "Selasa",
      waktu: "14:00-15:40",
      peserta: "24"
    },
    {
      id: 915,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB528",
      nama_mk: "REKLAMASI DAN PASCA TAMBANG",
      kelas: "11",
      nama_dosen: "Yoessi Oktarini, ST., M.T.",
      nip: "198910102016092101",
      ruang: "prodi",
      hari: "Selasa",
      waktu: "16:30-18:00",
      peserta: "25"
    },
    {
      id: 916,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB528",
      nama_mk: "REKLAMASI DAN PASCA TAMBANG",
      kelas: "12",
      nama_dosen: "Febi Mutia, S.T., M.Sc.",
      nip: "198702042019032011",
      ruang: "RB 1A",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "22"
    },
    {
      id: 917,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB530",
      nama_mk: "KEBIJAKAN PERTAMBANGAN",
      kelas: "11",
      nama_dosen: "Febi Mutia, S.T., M.Sc.",
      nip: "198702042019032011",
      ruang: "prodi",
      hari: "Selasa",
      waktu: "8:00-9:40",
      peserta: "10"
    },
    {
      id: 918,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB532",
      nama_mk: "STRATEGI BISNIS PERTAMBANGAN",
      kelas: "11",
      nama_dosen: "Dr. Teuku Andika Rama Putra, S.T.,M.Sc",
      nip: "197209251999031002",
      ruang: "prodi",
      hari: "Rabu",
      waktu: "10:40-12:25",
      peserta: "0"
    },
    {
      id: 919,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB534",
      nama_mk: "MANAJEMEN PROYEK",
      kelas: "11",
      nama_dosen: "Hendra Harisman, S.T., M.Eng.Sc",
      nip: "198311102018031001",
      ruang: "prodi",
      hari: "Rabu",
      waktu: "14:00-15:40",
      peserta: "3"
    },
    {
      id: 920,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB536",
      nama_mk: "TEKNIK KOMUNIKASI",
      kelas: "11",
      nama_dosen: "Ir. Pocut Nurul Alam, M.T.",
      nip: "196608221997022002",
      ruang: "prodi",
      hari: "Rabu",
      waktu: "16:30-18:00",
      peserta: "0"
    },
    {
      id: 921,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB538",
      nama_mk: "PIROMETALURGI",
      kelas: "11",
      nama_dosen: "Izzan Nur Aslam, S.T., M.Eng",
      nip: "199008232020121015",
      ruang: "prodi",
      hari: "Kamis",
      waktu: "10:40-12:25",
      peserta: "0"
    },
    {
      id: 922,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB540",
      nama_mk: "HIDROGEOLOGI TAMBANG",
      kelas: "11",
      nama_dosen: "Febi Mutia, S.T., M.Sc.",
      nip: "198702042019032011",
      ruang: "prodi",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "28"
    },
    {
      id: 923,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB542",
      nama_mk: "TEKNIK PEMBORAN EKSPLORASI",
      kelas: "11",
      nama_dosen: "Haqul Baramsyah, S.T., M.Eng.Sc.",
      nip: "198411282015041002",
      ruang: "prodi",
      hari: "Kamis",
      waktu: "14:00-15:40",
      peserta: "0"
    },
    {
      id: 924,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB544",
      nama_mk: "SISTEM KEBUMIAN",
      kelas: "11",
      nama_dosen: "Moehammad Ediyan Raza Karmel, B.Sc(Huns), M.Si.",
      nip: "198902262019031006",
      ruang: "prodi",
      hari: "Kamis",
      waktu: "16:30-18:00",
      peserta: "19"
    },
    {
      id: 925,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB546",
      nama_mk: "FOTOGRAMETRI",
      kelas: "11",
      nama_dosen: "Haqul Baramsyah, S.T., M.Eng.Sc.",
      nip: "198411282015041002",
      ruang: "prodi",
      hari: "Jumat",
      waktu: "8:00-9:45",
      peserta: "10"
    },
    {
      id: 926,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB548",
      nama_mk: "MANAJEMEN KONFLIK",
      kelas: "11",
      nama_dosen: "Ir. T. Zulfikar, M.T",
      nip: "195809122017011101",
      ruang: "prodi",
      hari: "Jumat",
      waktu: "14:00-15:40",
      peserta: "-2"
    },
    {
      id: 927,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPB550",
      nama_mk: "HIDROMETALURGI",
      kelas: "11",
      nama_dosen: "Dr. Husni, ST, M.Sc., M. Eng",
      nip: "197410282006041002",
      ruang: "prodi",
      hari: "Jumat",
      waktu: "16:30-18:00",
      peserta: "-1"
    },
    {
      id: 928,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPBP02",
      nama_mk: "KERJA PRAKTEK",
      kelas: "11",
      nama_dosen: "Yoessi Oktarini, S.T., M.T.",
      nip: "198910102019032024",
      ruang: "prodi",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "10"
    },
    {
      id: 929,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPBP04",
      nama_mk: "SEMINAR",
      kelas: "11",
      nama_dosen: "Izzan Nur Aslam, S.T., M.Eng",
      nip: "199008232020121015",
      ruang: "prodi",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "82"
    },
    {
      id: 930,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0410801",
      nama_prodi: "Teknik Pertambangan",
      kode_mk: "TPBPA2",
      nama_mk: "TUGAS AKHIR",
      kelas: "11",
      nama_dosen: "Nurul Kamal, ST., M.Sc",
      nip: "196903232014011101",
      ruang: "prodi",
      hari: "Senin",
      waktu: "8:00-9:40",
      peserta: "79"
    },
    {
      id: 931,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW108",
      nama_mk: "INFRASTRUKTUR WILAYAH DAN KOTA",
      kelas: "11",
      nama_dosen: "Dr. Irin Caisarina, ST, M. Sc.",
      nip: "197605182005012002",
      ruang: "A34-201",
      hari: "Selasa",
      waktu: "8:00-11:30",
      peserta: "28"
    },
    {
      id: 932,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW108",
      nama_mk: "INFRASTRUKTUR WILAYAH DAN KOTA",
      kelas: "12",
      nama_dosen: "Zainuddin, S.T.,M.Sc",
      nip: "197306042008011013",
      ruang: "A34-202",
      hari: "Selasa",
      waktu: "8:00-11:30",
      peserta: "31"
    },
    {
      id: 933,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW110",
      nama_mk: "PERENCANAAN TAPAK",
      kelas: "11",
      nama_dosen: "Ir. Halis Agussaini, M.T.",
      nip: "196508111993031001",
      ruang: "A34-201",
      hari: "Selasa",
      waktu: "14:00-17:09",
      peserta: "33"
    },
    {
      id: 934,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW110",
      nama_mk: "PERENCANAAN TAPAK",
      kelas: "12",
      nama_dosen: "Zainuddin, S.T.,M.Sc",
      nip: "197306042008011013",
      ruang: "A34-202",
      hari: "Selasa",
      waktu: "14:00-17:09",
      peserta: "34"
    },
    {
      id: 935,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW110",
      nama_mk: "PERENCANAAN TAPAK",
      kelas: "13",
      nama_dosen: "Fahmi Aulia, S.T., M.T.",
      nip: "199202172019031014",
      ruang: "Prodi PWK",
      hari: "Sabtu",
      waktu: "10:45-12:19",
      peserta: "30"
    },
    {
      id: 936,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW110",
      nama_mk: "PERENCANAAN TAPAK",
      kelas: "14",
      nama_dosen: "Zainuddin, S.T.,M.Sc",
      nip: "197306042008011013",
      ruang: "Prodi PWK",
      hari: "Sabtu",
      waktu: "14:00-16:20",
      peserta: "56"
    },
    {
      id: 937,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW110",
      nama_mk: "PERENCANAAN TAPAK",
      kelas: "15",
      nama_dosen: "Ir. Halis Agussaini, M.T.",
      nip: "196508111993031001",
      ruang: "Lab. Kota",
      hari: "Sabtu",
      waktu: "10:45-13:15",
      peserta: "29"
    },
    {
      id: 938,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW110",
      nama_mk: "PERENCANAAN TAPAK",
      kelas: "16",
      nama_dosen: "Fahmi Aulia, S.T., M.T.",
      nip: "199202172019031014",
      ruang: "A34-202",
      hari: "Senin",
      waktu: "16:45-17:49",
      peserta: "21"
    },
    {
      id: 939,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW112",
      nama_mk: "STUDIO PROSES PERENCANAAN",
      kelas: "11",
      nama_dosen: "Myna Agustina Yusuf, S.T., MURP",
      nip: "198908082019032026",
      ruang: "Studio PWK",
      hari: "Senin",
      waktu: "8:00-12:00",
      peserta: "58"
    },
    {
      id: 940,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW114",
      nama_mk: "SISTEM SOSIAL DAN KEPENDUDUKAN",
      kelas: "11",
      nama_dosen: "Zahrul Fuady, S.T., M.T.",
      nip: "196903192000031001",
      ruang: "A34-201",
      hari: "Rabu",
      waktu: "8:00-10:29",
      peserta: "31"
    },
    {
      id: 941,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW114",
      nama_mk: "SISTEM SOSIAL DAN KEPENDUDUKAN",
      kelas: "12",
      nama_dosen: "Ir. Halis Agussaini, M.T.",
      nip: "196508111993031001",
      ruang: "A34-202",
      hari: "Rabu",
      waktu: "8:00-10:29",
      peserta: "29"
    },
    {
      id: 942,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW204",
      nama_mk: "PERENCANAAN KOTA",
      kelas: "11",
      nama_dosen: "Dr. Ir. Mirza Irwansyah, MBA. MLA.",
      nip: "196205261987101001",
      ruang: "A34-201",
      hari: "Jumat",
      waktu: "8:00-10:29",
      peserta: "30"
    },
    {
      id: 943,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW204",
      nama_mk: "PERENCANAAN KOTA",
      kelas: "12",
      nama_dosen: "Prof. Dr. Ashfa, S.T., M.T.",
      nip: "197302152000031001",
      ruang: "A34-202",
      hari: "Jumat",
      waktu: "8:00-10:29",
      peserta: "29"
    },
    {
      id: 944,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW210",
      nama_mk: "METODE ANALISIS PERENCANAAN II",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ashfa, S.T., M.T.",
      nip: "197302152000031001",
      ruang: "A34-201",
      hari: "Selasa",
      waktu: "10:40-12:19",
      peserta: "30"
    },
    {
      id: 945,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW210",
      nama_mk: "METODE ANALISIS PERENCANAAN II",
      kelas: "12",
      nama_dosen: "Sylvia Agustina, ST., MUP",
      nip: "197308171999032002",
      ruang: "A34-202",
      hari: "Selasa",
      waktu: "10:40-12:19",
      peserta: "29"
    },
    {
      id: 946,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW216",
      nama_mk: "PERUMAHAN DAN PERMUKIMAN",
      kelas: "11",
      nama_dosen: "Zahrul Fuady, S.T., M.T.",
      nip: "196903192000031001",
      ruang: "A34-201",
      hari: "Rabu",
      waktu: "10:40-13:09",
      peserta: "31"
    },
    {
      id: 947,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW216",
      nama_mk: "PERUMAHAN DAN PERMUKIMAN",
      kelas: "12",
      nama_dosen: "Dr.Ir. Elysa Wulandari, M.T.",
      nip: "196410191990022001",
      ruang: "A34-202",
      hari: "Rabu",
      waktu: "10:40-13:09",
      peserta: "30"
    },
    {
      id: 948,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW218",
      nama_mk: "ANALISIS SUMBER DAYA ALAM DAN GEOLOGI LINGKUNGAN",
      kelas: "11",
      nama_dosen: "Dr. Bambang Setiawan, ST, M.Eng.Sc.",
      nip: "197106032006041002",
      ruang: "A34-201",
      hari: "Senin",
      waktu: "14:00-17:09",
      peserta: "30"
    },
    {
      id: 949,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW218",
      nama_mk: "ANALISIS SUMBER DAYA ALAM DAN GEOLOGI LINGKUNGAN",
      kelas: "12",
      nama_dosen: "Dr. Halida Yunita, S.T., M.T.",
      nip: "197806132002122002",
      ruang: "A34-202",
      hari: "Senin",
      waktu: "14:00-17:09",
      peserta: "31"
    },
    {
      id: 950,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW220",
      nama_mk: "PENGEMBANGAN KOMUNITAS",
      kelas: "11",
      nama_dosen: "Putra Rizkiya, S.T., M.Sc.",
      nip: "198601212019031009",
      ruang: "A34-201",
      hari: "Kamis",
      waktu: "16:20-17:59",
      peserta: "29"
    },
    {
      id: 951,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW220",
      nama_mk: "PENGEMBANGAN KOMUNITAS",
      kelas: "12",
      nama_dosen: "Sylvia Agustina, ST., MUP",
      nip: "197308171999032002",
      ruang: "A34-202",
      hari: "Kamis",
      waktu: "16:20-17:59",
      peserta: "13"
    },
    {
      id: 952,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW220",
      nama_mk: "PENGEMBANGAN KOMUNITAS",
      kelas: "13",
      nama_dosen: "Farisa Sabila, S.T., M.Sc",
      nip: "199202232019032023",
      ruang: "Lab Kota",
      hari: "Kamis",
      waktu: "16:20-17:59",
      peserta: "31"
    },
    {
      id: 953,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW222",
      nama_mk: "EKONOMI WILAYAH DAN KOTA",
      kelas: "11",
      nama_dosen: "Dr. Muliadi, S.T., M.T.",
      nip: "197408282006041010",
      ruang: "A34-201",
      hari: "Senin",
      waktu: "16:20-17:59",
      peserta: "26"
    },
    {
      id: 954,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW222",
      nama_mk: "EKONOMI WILAYAH DAN KOTA",
      kelas: "12",
      nama_dosen: "Putra Rizkiya, S.T., M.Sc.",
      nip: "198601212019031009",
      ruang: "A34-202",
      hari: "Senin",
      waktu: "16:20-17:59",
      peserta: "30"
    },
    {
      id: 955,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW224",
      nama_mk: "STUDIO PERENCANAAN DESA",
      kelas: "11",
      nama_dosen: "Dr.Ir. Elysa Wulandari, M.T.",
      nip: "196410191990022001",
      ruang: "Studio PWK",
      hari: "Kamis",
      waktu: "8:00-12:00",
      peserta: "0"
    },
    {
      id: 956,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW225",
      nama_mk: "STUDIO PERENCANAAN KAWASAN",
      kelas: "11",
      nama_dosen: "Farisa Sabila, S.T., M.Sc",
      nip: "199202232019032023",
      ruang: "Studio PWK",
      hari: "Selasa",
      waktu: "8:00-12:00",
      peserta: "60"
    },
    {
      id: 957,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW304",
      nama_mk: "PERENCANAAN KAWASAN PESISIR",
      kelas: "11",
      nama_dosen: "Dr.Ir. Elysa Wulandari, M.T.",
      nip: "196410191990022001",
      ruang: "A34-201",
      hari: "Kamis",
      waktu: "8:00-10:29",
      peserta: "22"
    },
    {
      id: 958,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW304",
      nama_mk: "PERENCANAAN KAWASAN PESISIR",
      kelas: "12",
      nama_dosen: "Zahrul Fuady, S.T., M.T.",
      nip: "196903192000031001",
      ruang: "A34-202",
      hari: "Kamis",
      waktu: "8:00-10:29",
      peserta: "30"
    },
    {
      id: 959,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW320",
      nama_mk: "MANAJEMEN PROYEK DAN PEMBIAYAAN",
      kelas: "11",
      nama_dosen: "Ir. Halis Agussaini, M.T.",
      nip: "196508111993031001",
      ruang: "A34-201",
      hari: "Senin",
      waktu: "8:00-10:29",
      peserta: "22"
    },
    {
      id: 960,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW320",
      nama_mk: "MANAJEMEN PROYEK DAN PEMBIAYAAN",
      kelas: "12",
      nama_dosen: "Zainuddin, S.T.,M.Sc",
      nip: "197306042008011013",
      ruang: "A34-201",
      hari: "Senin",
      waktu: "8:00-10:29",
      peserta: "30"
    },
    {
      id: 961,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW322",
      nama_mk: "METODE PENELITIAN",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ashfa, S.T., M.T.",
      nip: "197302152000031001",
      ruang: "A34-201",
      hari: "Jumat",
      waktu: "14:00-15:39",
      peserta: "22"
    },
    {
      id: 962,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW322",
      nama_mk: "METODE PENELITIAN",
      kelas: "12",
      nama_dosen: "Ir. Halis Agussaini, M.T.",
      nip: "196508111993031001",
      ruang: "A34-202",
      hari: "Jumat",
      waktu: "14:00-15:39",
      peserta: "30"
    },
    {
      id: 963,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPW325",
      nama_mk: "STUDIO PERENCANAAN KOTA",
      kelas: "11",
      nama_dosen: "Fahmi Aulia, S.T., M.T.",
      nip: "199202172019031014",
      ruang: "Studio PWK",
      hari: "Jumat",
      waktu: "8:00-12:00",
      peserta: "54"
    },
    {
      id: 964,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPWP01",
      nama_mk: "KERJA PRAKTEK",
      kelas: "11",
      nama_dosen: "Zahrul Fuady, S.T., M.T.",
      nip: "196903192000031001",
      ruang: "Lab. Perecanaan dan",
      hari: "Senin",
      waktu: "10:40-12:19",
      peserta: "0"
    },
    {
      id: 965,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPWP02",
      nama_mk: "PENGEMBANGAN LAHAN",
      kelas: "11",
      nama_dosen: "Dr. Irin Caisarina, ST, M. Sc.",
      nip: "197605182005012002",
      ruang: "A34-201",
      hari: "Selasa",
      waktu: "16:20-17:59",
      peserta: "8"
    },
    {
      id: 966,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPWP03",
      nama_mk: "SEMINAR",
      kelas: "11",
      nama_dosen: "Zainuddin, S.T.,M.Sc",
      nip: "197306042008011013",
      ruang: "A34-201",
      hari: "Senin",
      waktu: "10:40-12:19",
      peserta: "11"
    },
    {
      id: 967,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPWP04",
      nama_mk: "PERMODELAN WILAYAH DAN KOTA",
      kelas: "11",
      nama_dosen: "Prof. Dr. Ashfa, S.T., M.T.",
      nip: "197302152000031001",
      ruang: "A34-201",
      hari: "Rabu",
      waktu: "14:00-15:39",
      peserta: "1"
    },
    {
      id: 968,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPWP06",
      nama_mk: "PENGELOLAAN WILAYAH PESISIR DAN PULAU KECIL",
      kelas: "11",
      nama_dosen: "Fahmi Aulia, S.T., M.T.",
      nip: "199202172019031014",
      ruang: "A34-202",
      hari: "Rabu",
      waktu: "16:20-17:59",
      peserta: "21"
    },
    {
      id: 969,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPWP08",
      nama_mk: "PRESERVASI KAWASAN",
      kelas: "11",
      nama_dosen: "Dr. Cut Dewi, ST., MT,M.Sc",
      nip: "197807152002122002",
      ruang: "A34-202",
      hari: "Jumat",
      waktu: "16:20-17:59",
      peserta: "21"
    },
    {
      id: 970,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPWP10",
      nama_mk: "PERENCANAAN PARTISIPATIF",
      kelas: "11",
      nama_dosen: "Ir. Halis Agussaini, M.T.",
      nip: "196508111993031001",
      ruang: "Lab. Perecanaan dan",
      hari: "Selasa",
      waktu: "14:00-15:39",
      peserta: "11"
    },
    {
      id: 971,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPWP12",
      nama_mk: "PERENCANAAN PARIWISATA",
      kelas: "11",
      nama_dosen: "Zainuddin, S.T.,M.Sc",
      nip: "197306042008011013",
      ruang: " A34-201",
      hari: "Kamis",
      waktu: "10:40-12:19",
      peserta: "2"
    },
    {
      id: 972,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPWP14",
      nama_mk: "PERENCANAAN PASCA BENCANA",
      kelas: "11",
      nama_dosen: "Sylvia Agustina, ST., MUP",
      nip: "197308171999032002",
      ruang: "Lab. Perecanaan dan",
      hari: "Selasa",
      waktu: "8:00-9:39",
      peserta: "3"
    },
    {
      id: 973,
      kd_semester: "20213",
      nama_semester: "Genap 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPWP16",
      nama_mk: "PENGEMBANGAN KOTA RENDAH KARBON",
      kelas: "11",
      nama_dosen: "Putra Rizkiya, S.T., M.Sc.",
      nip: "198601212019031009",
      ruang: "A34-202",
      hari: "Kamis",
      waktu: "14:00-15:39",
      peserta: "22"
    },
    {
      id: 974,
      kd_semester: "20211",
      nama_semester: "Ganjil 2021/2022",
      kode_prodi: "0411001",
      nama_prodi: "Perencanaan Wilayah dan Kota",
      kode_mk: "TPWPA1",
      nama_mk: "TUGAS AKHIR  TA",
      kelas: "11",
      nama_dosen: "Putra Rizkiya, S.T., M.Sc.",
      nip: "198601212019031009",
      ruang: "Studio PWK",
      hari: "Minggu",
      waktu: "8:00-12:19",
      peserta: "62"
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/matakuliah/list/all-data').reply(200, data.listMatakuliah)

// POST: Add new user
mock.onPost('/matakuliah/cpmk/add-user').reply(config => {
  // Get event from post data
  const itemMk = JSON.parse(config.data)
  const highestValue = data.listMatakuliah.reduce((a, b) => (a.id > b.id ? a : b)).id

  itemMk.id = highestValue + 1

  data.listMatakuliah.push(itemMk)

  return [201, { user }]
})

// GET Updated DATA
mock.onGet('/api/matakuliah/list/data').reply(config => {
  const {
    q = '',
    page = 1,
    kd_semester = null,
    perPage = 10,
    sort = 'asc',
    kode_prodi = null,
    sortColumn = 'nama_dosen'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.listMatakuliah.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    itemMk =>
      (itemMk.nip.toLowerCase().includes(queryLowered) ||
        itemMk.nama_dosen.toLowerCase().includes(queryLowered) ||
        itemMk.nama_mk.toLowerCase().includes(queryLowered)) &&
      itemMk.kd_semester === (kd_semester || itemMk.kd_semester) &&
      itemMk.kode_prodi === (kode_prodi || itemMk.kode_prodi) 
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      listMatakuliah: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET USER
mock.onGet('/api/matakuliah/user').reply(config => {
  const { id } = config
  const itemMk = data.listMatakuliah.find(i => i.id === id)
  return [200, { itemMk }]
})

// DELETE: Deletes User
mock.onDelete('/matakuliah/cpmk/delete').reply(config => {
  // Get user id from URL
  let mkId = config.id

  // Convert Id to number
  mkId = Number(mkId)

  const mkIndex = data.listMatakuliah.findIndex(t => t.id === mkId)
  data.listMatakuliah.splice(mkIndex, 1)

  return [200]
})
